import queryString from "query-string";
import useSWR from "swr";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;

// @TODO: Update API documentation with new functions from this file

export const useBrowseSitepages = (values) => {
  return useSWR(
    [
      hostname + "/sitepages/browse",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetSitePage = (page_id, values) => {
  return useSWR(
    [
      hostname + "/sitepage/view/" + page_id,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetSitePageAlbums = (page_id, values) => {
  return useSWR(
    [
      hostname + "/sitepage/photos/index/" + page_id,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetSitePageAlbumPhotos = (page_id, album_id, values) => {
  return useSWR(
    [
      hostname + `/sitepage/photos/viewalbum/${page_id}/${album_id}`,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetSitePageReviews = (page_id, values) => {
  return useSWR(
    [
      hostname + `/sitepage/reviews/browse/${page_id}`,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetSitePageManaged = (values) => {
  return useSWR(
    [
      hostname + "/sitepages/manage",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetEditSitePageForm = (page_id, values) => {
  return useSWR(
    [
      hostname + "/sitepage/edit/" + page_id,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const postEditSitePage = (page_id, values) => {
  return fetcher(
    [
      hostname + `/sitepage/edit/${page_id}`,
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
  );
};

// Get Sitepage Statistics
export const useGetSitePageStatistics = (page_id, values) => {
  return useSWR(
    [
      hostname + `/sitepages/statistics`,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

//Get Sitepage Manage Admins
export const useGetSitePageManageAdmins = (page_id, values) => {
  return useSWR(
    [
      hostname + `/sitepage/manageadmins/${page_id}`,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const postCreatePage = (values) => {
  return fetcher(
    [
      hostname + "/sitepages/create",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
  );
};

export const postDeletePage = (id) => {
  return fetcher(
    [
      hostname + `/sitepage/delete/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false,
    true,
  );
};

export const postTellAFriend = (id, values) => {
  return fetcher(
    [
      hostname + `/sitepage/tellafriend/${id}`,
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
  );
};

export const postDeletePageAlbumPhoto = (photo_id, album_id, page_id) => {
  return fetcher(
    [
      hostname + `/sitepage/photos/deletephoto/${page_id}/${album_id}/${photo_id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false, true
  )
}
export const postFollowPage = (id) => {
  return fetcher(
    [
      hostname + `/sitepage/follow/${id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false, true
  );
}
