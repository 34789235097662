import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { postProfileEditPhoto } from "../../../utils/api/functions/memberSettings";
import { useToast } from "rc-toastr";

const EditProfilePhoto = () => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const { toast } = useToast();

  const handleProfilePhotoChange = (e) => {
    setProfilePhoto(e.target.files[0]);
    setProfilePhotoPreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = async () => {
    if (!profilePhoto) return;
    setSubmitting(true);
    const form_data = new FormData();
    form_data.append("photo", profilePhoto);

    const res = await postProfileEditPhoto(form_data);
    

    if (res.status_code === 204) {
      toast.success("Profile photo updated successfully");
    } else {
      toast.error("Error updating profile photo");
    }
    setSubmitting(false);
  };

  return (
    <div className="profile-photo-edit-form-container">
      <div className="profile-photo-edit-preview">
        <img src={profilePhotoPreview} />
      </div>
      <Form>
        <Form.Group>
          <Form.Label>Upload Profile Photo</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={handleProfilePhotoChange}
          />
          <Button onClick={handleSubmit} disabled={isSubmitting}>
            Save
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default EditProfilePhoto;
