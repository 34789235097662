import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faX
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FeedProvider } from "../../utils/context/feedContext";
import { BasicComments, CreateComment } from "../posts/Comment";

const VideoOwner = ({ video_data }) => {
  return (
    <div className="video-owner">
      <Link to={`/profile/${video_data.owner_id}`}>
        <img className="video-owner-image" src={video_data.owner_image} />
      </Link>
      <div className="video-owner-name">
        <Link to={`/profile/${video_data.owner_id}`}>
          {video_data.owner_title}
        </Link>
        <div className="title">{video_data.title}</div>
      </div>
    </div>
  );
};

const PhotoOwner = ({ owner_data }) => {
  return (
    <div className="video-owner">
      <Link to={`/profile/${owner_data.owner_id}`}>
        <img className="video-owner-image" src={owner_data.owner_image} />
      </Link>
      <div className="video-owner-name">
        <Link to={`/profile/${owner_data.owner_id}`}>
          {owner_data.owner_title}
        </Link>
        <div className="title"></div>
      </div>
    </div>
  );
};

const AttachmentOwner = ({ owner_data }) => {
  return(
    <div className="video-owner">
      <Link to={`/profile/${owner_data.subject_id}`}>
        <img className="video-owner-image" src={owner_data.image} />
      </Link>
      <div className="video-owner-name">
        <Link to={`/profile/${owner_data.subject_id}`}>
          {owner_data.displayname}
        </Link>
        <div className="title"></div>
      </div>
    </div>
  )
}

const ContentDisplay = ({ type, data }) => {
  switch (type) {
    case "video":
      return (
        <video controls autoPlay>
          {data.video_url ? (
            <source src={data.video_url} type="video/mp4" />
          ) : null}
        </video>
      );
    case "album_photo":
      return <img src={data.image || data.image_main.src} />;
      
    case "youtube":
      return (
        <iframe
          src={"https://" + data.video_url}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      );
  }

  return null;
};

const VideoDisplayNav = ({ navHandles }) => {
  return (
    <div className="display-wrapper-nav">
      <FontAwesomeIcon icon={faCircleArrowLeft} onClick={navHandles.handlePrev} />
      <FontAwesomeIcon icon={faCircleArrowRight} onClick={navHandles.handleNext} />
    </div>
  );
};

const VideoDisplay = ({
  show,
  onHide,
  video_data,
  type = "video",
  subject_type,
  subject_id,
  navButtons = false,
  navHandles,
  totalNumber = false,
  itemIndex = 0,
  owner_data = {},
  isLoading = false,
  attachment = false
}) => {

  if(!video_data) return null; 

  let content_type = type;
  if (video_data && video_data.type === 1) {
    content_type = "youtube";
  }


  if(attachment) {
    video_data.video_url = video_data.attachment_video_url; 
  }

  if (isLoading) {
    return (
      <Modal
        centered
        show={show}
        onHide={onHide}
        fullscreen={true}
        dialogClassName="video-display-dialog"
      >
        <Modal.Body>
          <div className="video-display-component" style={{ color: "white" }}>
            Loading...
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      fullscreen={true}
      dialogClassName="video-display-dialog"
    >
      <Modal.Body>
        <div className="video-display-component">
          <div className="video-display-component-video">
            <div className="video-display-wrapper">
              {totalNumber ? (
                <div className="display-item-count">
                  {itemIndex + 1} / {totalNumber}
                </div>
              ) : null}
              <ContentDisplay type={content_type} data={video_data} />
              {navButtons ? <VideoDisplayNav navHandles={navHandles} /> : null}
            </div>

            <div className="video-info">
              <div className="button-bar">
                <FontAwesomeIcon icon={faX} onClick={onHide} />
              </div>
              {
                attachment === true ? (
                  <AttachmentOwner owner_data={owner_data} />
                )
                :
                (
                  <>
                  {type === "video" ? (
                    <VideoOwner video_data={video_data} owner_data={owner_data} />
                  ) : (
                    <PhotoOwner owner_data={owner_data} />
                  )}
                  </>
                )
              }
              

              <div className="video-stats">
                <div className="video-description">
                  {video_data.description}
                </div>
                <div className="likes-comments-views">
                  {video_data.like_count} likes • {video_data.comment_count}{" "}
                  comments • {video_data.view_count} views
                </div>
                {/* <div className="ratings">
                                    <Rating rating={video_data.rating} rating_count={video_data.rating_count}/>
                                    {video_data.rating_count} ratings
                                </div> */}
              </div>

              <div className="video-actions">
                <FeedProvider>
                  <CreateComment
                    hideProfilePicture
                    action_id={subject_id}
                    subject_type={type}
                    nested={false}
                  />
                  <BasicComments
                    subject_type={subject_type}
                    subject_id={subject_id}
                  />
                </FeedProvider>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

// Component to load video from url designated url parameter


export default VideoDisplay;