import React from "react";
import { useEffect, useState } from "react";
import { useGetSitePage } from "../../../utils/api/functions/sitepages";
import { useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";

const PagePhoto = () => {
  const { id } = useParams();
  const { data } = useGetSitePage(id, {});
  const [image, setImage] = useState("");

  useEffect(() => {
    if (data && data.body) {
      setImage(data.body.image);
    }
  }, [data]);

  const onChange = (e) => {
    const file = e.target.files[0];
    const new_url = URL.createObjectURL(file);
    setImage(new_url);
  };

  return (
    <div className="page-edit-photo-container">
      Page Photo
      <div className="page-edit-photo-preview">
        {image ? <img alt="page-edit-photo-preview" src={image} /> : null}
      </div>
      <Form>
        <Form.Group className="mb-3">
          <Form.Control type="file" name="image" onChange={onChange} />
        </Form.Group>
        <Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default PagePhoto;
