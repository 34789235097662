import { Formik } from "formik";
import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { postEditGroup, useGetEditGroup } from "../../../utils/api/functions/groups";

const GroupEditSchema = Yup.object().shape({
  title: Yup.string().required("Group name is required"),
  description: Yup.string().required("Group description is required"),
  //photo: Yup.string().required("Group photo is required"),
  category_id: Yup.number().required("Group category is required"),
  search: Yup.string().required("Search setting is required"),
  auth_invite: Yup.string().required("Invite setting is required"),
  approval: Yup.string().required("Approval setting is required"),
  auth_view: Yup.string().required("View setting is required"),
  auth_comment: Yup.string().required("Comment setting is required"),
  auth_photo: Yup.string().required("Photo setting is required"),
  auth_event: Yup.string().required("Event setting is required"),
});

const getValues = (values, name) => {
  if (values && values[name]) {
    return values[name];
  }
  return "";
};

const GroupEditError = ({ name, errors, touched }) => {
  if (errors[name] && touched[name]) {
    return <div className="group-edit-form-error">{errors[name]}</div>;
  } else {
    return null;
  }
};

const GroupEdit = () => {
  const { id } = useParams();
  const { data } = useGetEditGroup(id, {});
  const [initialValues, setValues] = useState();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (data?.body?.formValues) {
      setValues(() => data.body.formValues);
    }
  }, [data]);

  return (
    <div className="group-edit-container">
      <div className="group-edit-form-container">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={GroupEditSchema}
          onSubmit={async (values, { setSubmitting }) => {
            // console.log("Group edit values: "+(new Date()), values); 
            // return;
            setSubmitting(true);
            toast.info("Saving...");

            const form_data = new FormData();
            for (let value in values) {
              form_data.append(value, values[value]);
            }


            await postEditGroup(id, form_data)
              .then((res) => {
                if (res.status_code === 204) {
                  toast.success("Group updated successfully");
                } else {
                  toast.error("Error updating group");
                }
              })
              .catch(() => {
                toast.error("Error updating group");
              });
            setSubmitting(false);
            navigate(`/groups/group/${id}`);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="title" className="mb-3">
                <Form.Label>Group Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter group name"
                  value={getValues(values, "title")}
                  onChange={handleChange}
                  isValid={touched.title && !errors.title}
                  isInvalid={touched.title && errors.title}
                />
                <GroupEditError
                  errors={errors}
                  touched={touched}
                  name={"title"}
                />
              </Form.Group>
              <Form.Group controlId="description" className="mb-3">
                <Form.Label>Group Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={getValues(values, "description")}
                  onChange={handleChange}
                  isValid={touched.description && !errors.description}
                  isInvalid={touched.description && errors.description}
                />
                <GroupEditError
                  errors={errors}
                  touched={touched}
                  name={"description"}
                />
              </Form.Group>
              {/* <Form.Group controlId="photo" className="mb-3">
                                    <Form.Label>Group Image</Form.Label>
                                    <Form.Control type="file" 
                                        // value={values?.photo}
                                        // onChange={handleChange}

                                    />
                                    <GroupEditError errors={errors} touched={touched} name={"photo"}/>
                                    <GroupEditPhotoPreview src={values?.photo}/>
                                </Form.Group> */}
              <Form.Group controlId="category_id" className="mb-3">
                <Form.Label>Group Category</Form.Label>
                <Form.Control
                  as="select"
                  value={parseInt(getValues(values, "category_id")) || ""}
                  onChange={handleChange}
                  isValid={touched.category_id && !errors.category_id}
                  isInvalid={touched.category_id && errors.category_id}
                >
                  <option>Choose...</option>
                  <option value={1}>Animals</option>
                  <option value={2}>Business & Finance</option>
                  <option value={3}>Computers and Internet</option>
                  <option value={4}>Cultures & Community</option>
                  <option value={5}>Dating & Relationships</option>
                  <option value={6}>Entertainment & Arts</option>
                  <option value={7}>Family & Home</option>
                  <option value={8}>Games</option>
                  <option value={9}>Government & Politics</option>
                  <option value={10}>Health & Wellness</option>
                  <option value={11}>Hobbies & Crafts</option>
                  <option value={12}>Music</option>
                  <option value={13}>Recreation & Sports</option>
                  <option value={14}>Regional</option>
                  <option value={15}>Religion & Beliefs</option>
                  <option value={16}>Schools & Education</option>
                  <option value={17}>Science</option>
                </Form.Control>
                <GroupEditError
                  errors={errors}
                  touched={touched}
                  name={"category_id"}
                />
              </Form.Group>
              <Form.Group controlId="search" className="mb-3">
                <Form.Check
                  type="switch"
                  id="show_in_search"
                  label="Show in Search Results"
                  checked={getValues(values, "search")}
                  onChange={(e) => {
                    setFieldValue("search", e.target.checked);
                  }}
                  isValid={touched.search && !errors.search}
                  isInvalid={touched.search && errors.search}
                />
                <GroupEditError
                  errors={errors}
                  touched={touched}
                  name={"search"}
                />
              </Form.Group>
              <Form.Group controlId="auth_invite" className="mb-3">
                <Form.Check
                  type="switch"
                  id="allow_members_to_invite"
                  label="Allow members to invite others"
                  checked={getValues(values, "auth_invite")}
                  onChange={(e) => {
                    setFieldValue("auth_invite", e.target.checked);
                  }}
                  isValid={touched.auth_invite && !errors.auth_invite}
                  isInvalid={touched.auth_invite && errors.auth_invite}
                />
                <GroupEditError
                  errors={errors}
                  touched={touched}
                  name={"auth_invite"}
                />
              </Form.Group>
              <Form.Group controlId="approval" className="mb-3">
                <Form.Check
                  type="switch"
                  id="approve_members"
                  label="Manually Approve Members"
                  checked={getValues(values, "approval")}
                  onChange={(e) => {
                    setFieldValue("approval", e.target.checked);
                  }}
                  isValid={touched.approval && !errors.approval}
                  isInvalid={touched.approval && errors.approval}
                />
                <GroupEditError
                  errors={errors}
                  touched={touched}
                  name={"approval"}
                />
              </Form.Group>

              {/* Privacy Settings */}
              <Form.Group controlId="auth_view" className="mb-3">
                <Form.Label>View Privacy</Form.Label>
                <Form.Select
                  value={getValues(values, "auth_view")}
                  onChange={handleChange}
                  isValid={touched.auth_view && !errors.auth_view}
                  isInvalid={touched.auth_view && errors.auth_view}
                >
                  <option value={"everyone"}>Everyone</option>
                  <option value={"member"}>All Group Members</option>
                  <option value={"registered"}>Registered Members</option>
                </Form.Select>
                <GroupEditError
                  errors={errors}
                  touched={touched}
                  name={"auth_view"}
                />
              </Form.Group>
              <Form.Group controlId="auth_comment" className="mb-3">
                <Form.Label>Comment Privacy</Form.Label>
                <Form.Select
                  value={getValues(values, "auth_comment")}
                  onChange={handleChange}
                  isValid={touched.auth_comment && !errors.auth_comment}
                  isInvalid={touched.auth_comment && errors.auth_comment}
                >
                  <option value={"member"}>All Group Members</option>
                  <option value={"officer"}>Officers and Owner only</option>
                  <option value={"registered"}>Registered Members</option>
                </Form.Select>
                <GroupEditError
                  errors={errors}
                  touched={touched}
                  name={"auth_comment"}
                />
              </Form.Group>

              <Form.Group controlId="auth_photo" className="mb-3">
                <Form.Label>Photo Uploads</Form.Label>
                <Form.Select
                  value={getValues(values, "auth_photo")}
                  onChange={handleChange}
                  isValid={touched.auth_photo && !errors.auth_photo}
                  isInvalid={touched.auth_photo && errors.auth_photo}
                >
                  <option value={"member"}>All Group Members</option>
                  <option value={"officer"}>Officers and Owner Only</option>
                  <option value={"registered"}>Registered Members</option>
                </Form.Select>
                <GroupEditError
                  errors={errors}
                  touched={touched}
                  name={"auth_photo"}
                />
              </Form.Group>
              <Form.Group controlId="auth_event" className="mb-3">
                <Form.Label>Event Creation</Form.Label>
                <Form.Select
                  value={getValues(values, "auth_event")}
                  onChange={handleChange}
                  isValid={touched.auth_event && !errors.auth_event}
                  isInvalid={touched.auth_event && errors.auth_event}
                >
                  <option value={"member"}>All Group Members</option>
                  <option value={"officer"}>Officers and Owner Only</option>
                  <option value={"registered"}>Registered Members</option>
                </Form.Select>
                <GroupEditError
                  errors={errors}
                  touched={touched}
                  name={"auth_event"}
                />
              </Form.Group>
              <Form.Group controlId="photo" className="mb-3">
                <Form.Label>Group Photo</Form.Label>
                <Form.Control type="file" name="photo"
                  onChange={(e) => {
                    setFieldValue("photo", e.target.files[0])
                  }}
                />
                {
                  values && values.photo ? (
                    <img 
                      src={URL.createObjectURL(values.photo)}
                      style={{
                        width: "5rem",
                        height: "5rem", 
                        marginTop: "1rem",
                        border: "1px solid black"
                      }}
                    />
                  ) : null
                }
                
              </Form.Group>
              <Form.Group style={{display: 'flex', justifyContent: "space-between"}}>
                <Button variant="danger" onClick={()=>{
                  navigate(-1)
                }}>
                  Back
                </Button>
                <Button type="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </Form.Group>
              
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default GroupEdit;
