import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { useToast } from 'rc-toastr';
import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { postCreateVideo, useBrowseVideos } from '../../utils/api/functions/videos';

const VideoUploadModal = ({show, onHide}) => {
    const {id} = useParams();
    const {mutate} = useBrowseVideos({
        user_id: id
    });
    
    const [file, setFile] = useState(null);
    
    const {toast} = useToast();

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }


    return(
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                Upload Video
            </Modal.Header>
            
                <Formik
                    validationSchema={
                        Yup.object({
                            title: Yup.string().required("Title is required"),
                            description: Yup.string().required("Description is required")
                        })
                    }
                    initialValues={{}}
                    onSubmit={async(values, {setSubmitting}) => {
                        const formData = new FormData();
                        formData.append("title", values.title);
                        formData.append("description", values.description);
                         
                        formData.append("auth_view", "everyone");
                        formData.append("auth_comment", "everyone");
                        formData.append("type", 3)
                        formData.append("category_id", 1);
                        formData.append("filedata", file);
                        
                        const res = await postCreateVideo(formData);
                        if(res.status_code === 200) {
                            toast.success("Video uploaded successfully");
                            mutate();
                            onHide();
                        }
                        else {
                            toast.error("Error uploading video");
                            onHide();
                        }
                    }}
                >
                    {({values, handleSubmit, handleChange, touched, errors, isSubmitting}) => (
                        <>
                        <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter title"
                                    name="title"
                                    value={values.title}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter description"
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Video</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="filedata"
                                    onChange={(e)=>{
                                        handleChange(e);
                                        handleFileChange(e);
                                    }}
                                />
                            </Form.Group>
                        </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={onHide}>Cancel</Button>
                            <Button onClick={handleSubmit}>Upload</Button>
                        </Modal.Footer>
                        </>
                    )}

                </Formik>
            
        </Modal>
    )
}

const VideoUploadButton = () => {
    const [show, setShow] = useState(false);
    const { id } = useParams(); 
    const isSelf = id === window.localStorage.getItem("user_id"); 

    const handleShow = () => {
        setShow(true);
    }
    const handleHide = () => {
        setShow(false);
    }

    if(!isSelf) return null; 
    
    return(
        <div>
            <Button
                className="video-upload-button"
                style={{"margin-top": "0.5rem", marginLeft: "0.5rem", marginBottom: "0.5rem"}}
                onClick={handleShow}
            >
                <FontAwesomeIcon icon={faVideo} style={{marginRight: "7px"}} />
                Upload Video
            </Button>
            <VideoUploadModal show={show} onHide={handleHide}/>
        </div>
    )
}


export default VideoUploadButton;