import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faBell,
  faRectangleAd,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const QuickLinks = () => {
  return (
    <div className="home-sidebar-item sidebar-quick-links">
      <Link to="/notifications">
        <FontAwesomeIcon icon={faBell} style={{ marginRight: "10px" }} />
        View Recent Updates
      </Link>
      <Link to="/profile/edit">
        <FontAwesomeIcon icon={faPenToSquare} style={{ marginRight: "10px" }} />
        Edit My Profile
      </Link>
      <Link to="/advertisements/manage">
        <FontAwesomeIcon icon={faRectangleAd} style={{ marginRight: "10px" }} />
        Manage Advertisements
      </Link>
      <Link to="/invite">
        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: "10px" }} />
        Invite Your Friends
      </Link>
    </div>
  );
};

export default QuickLinks;
