import { Button } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router";

const NotFound = () => {

  const navigate = useNavigate(); 

  return (
    <div
      style={{
        height: "100%", 
        width: "100%",
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
        flexDirection: "column"
      }}
    >
      <h1>404, Page Not Found</h1>
      <p>The page you were looking for could not be found!</p>
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "2rem",
          justifyContent: "center"
        }}
      >
        <Button
          onClick={()=> {navigate(-1)}}
        >Go back</Button>
        <Button
          onClick={()=> {navigate("/home")}}
        >
          Go to Home
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
