import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Bars } from "react-loading-icons";
import { Link, useNavigate } from "react-router-dom";
import { useBrowseGroups } from "../../utils/api/functions/groups";

const SearchGroups = ({ handleSearch }) => {
  const [text, setText] = useState("");
  const [category, setCategory] = useState(""); 
  const [order, setOrder] = useState("");

  const handleChange = (e) => {
    setText(e.target.value);
  };
  const handleCategorySelect = (e) => {
    setCategory(e.target.value); 
  }
  const handleOrderSelect = (e) => {
    setOrder(e.target.value); 
  }

  const handleSubmit = () => {
    handleSearch({
      text: text, 
      category_id: category,
      order: order
    });
  };

  return (
    <div className="groups-sidebar-search">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Form.Group>
          <Form.Label>Search Groups: </Form.Label>
          <Form.Control type="text" onChange={handleChange}></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Category: </Form.Label>
          <Form.Select onChange={handleCategorySelect} value={category}>
            <option value="">All Categories</option>
            <option value={1}>Animals</option>
            <option value={2}>Business & Finance</option>
            <option value={3}>Computers and Internet</option>
            <option value={4}>Cultures & Community</option>
            <option value={5}>Dating & Relationships</option>
            <option value={6}>Entertainment & Arts</option>
            <option value={7}>Family & Home</option>
            <option value={8}>Games</option>
            <option value={9}>Government & Politics</option>
            <option value={10}>Health & Wellness</option>
            <option value={11}>Hobbies & Crafts</option>
            <option value={12}>Music</option>
            <option value={13}>Recreation & Sports</option>
            <option value={14}>Regional</option>
            <option value={15}>Religion & Beliefs</option>
            <option value={16}>Schools & Education</option>
            <option value={17}>Science</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>List By:</Form.Label>
          <Form.Select
            onChange={handleOrderSelect}
            value={order}
          >
            <option value={"creation_date"}>Recently Created</option>
            <option value={"member_count"}>Most Popular</option>
          </Form.Select>
        </Form.Group>
        <Form.Group style={{ marginTop: "0.5rem" }}>
          <Button variant="primary" onClick={handleSubmit}>
            Search
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

const GroupsBrowse = () => {
  const [searchData, setSearchData] = useState({
    text: "", 
    category_id: ""
  });

  const { data, mutate, isLoading } = useBrowseGroups(searchData);

  const navigate = useNavigate();

  const [groups, setGroups] = useState([]);

  const handleSearch = (query) => {
    setGroups((old) => [])
    setSearchData(query);
  };

  //UseEffect to update on newly fetched data from BrowseGroups
  useEffect(() => {
    if (data && data.body && data.body.response) {
      setGroups((old) => [...data.body.response]);
    } else if (data && data.body && data.body.totalItemCount === 0) {
      setGroups((old) => []);
    }
  }, [data]);

  return (
    <div className="groups-browse-container">
      <div className="groups-home-main-content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Bars stroke="#00BCD4" fill="#00BCD4" />
          </div>
        ) : null}
        <div className="groups-browse-content">
          {groups.length === 0 && !isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              No Groups Found
            </div>
          ) : null}
          {groups.map((val, index) => {
            return (
              <div
                className="groups-browse-content-item"
                key={`groups-browse-item-${index}`}
              >
                <div className="item-img-container">
                  <div className="item-groups-members">
                    <span><FontAwesomeIcon icon={faUser}></FontAwesomeIcon> {val.member_count}</span>
                  </div>
                  <img
                    src={val.image_normal}
                    onClick={() => {
                      navigate(`/groups/group/${val.group_id}`);
                    }}
                  />
                </div>

                <div className="item-desc-container">
                  <p className="item-name">
                    <Link to={`/groups/group/${val.group_id}`}>
                      {val.title}
                    </Link>
                  </p>
                  <p className="item-owner">
                    Led by{" "}
                    <Link to={`/profile/${val.user_id}`}>
                      <a className="item-owner-name">{val.owner_title}</a>
                    </Link>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="groups-home-sidebar">
        <SearchGroups handleSearch={handleSearch} />
      </div>
    </div>
  );
};

export default GroupsBrowse;
