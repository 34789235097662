import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  postEditSitePage,
  useGetEditSitePageForm
} from "../../../utils/api/functions/sitepages";

import { sitepage_select_values } from "../../../settings/form/selectValues";

import { useToast } from "rc-toastr";

const PageEditInfoScheme = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  page_url: Yup.string(),
  location: Yup.string().required("Location is required"),
  package_id: Yup.number().required("Package is required"),
  category_id: Yup.number().required("Category is required"),
  subcategory_id: Yup.number(),
  subsubcategory_id: Yup.number(),
  tags: Yup.array().of(Yup.string()),
  //Photo?
  price: Yup.number(),
  auth_view: Yup.string().required(),
  auth_comment: Yup.string().required(),
  splcreate: Yup.string(), // Privacy setting for poll creation
  sspcreate: Yup.string(), // Privacy setting for sub page creation
  secreate: Yup.string(), // Privacy setting for event creation
  sdicreate: Yup.string(), // Privacy setting for discussion creation
  spcreate: Yup.string(), // Privacy setting for photo creation
  sdcreate: Yup.string(), // Privacy setting for document creation
  search: Yup.number(), // Setting to show page in search results
  draft: Yup.number(), // Setting to show page in draft
});

const PageEditInfoForm = () => {
  const { id } = useParams();
  const [initial, setInitial] = useState();
  const { data } = useGetEditSitePageForm(id, {});
  const { toast } = useToast();

  useEffect(() => {
    if (data && data.body) {
      setInitial(() => data.body.formValues);
    }
  }, [data]);

  const getValues = (values, name) => {
    if (values && values[name]) {
      return values[name];
    }
    return "";
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initial}
      validationSchema={PageEditInfoScheme}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        toast.info("Saving...");
        const form_data = new FormData();

        for (let value in values) {
          form_data.append(value, values[value]);
        }

        form_data.delete("gateway_id");
        form_data.delete("gateway_profile_id");
        form_data.delete("networks_privacy");

        await postEditSitePage(id, form_data)
          .then((res) => {
            if (res.status_code === 204) {
              toast.success("Page updated successfully");
            } else {
              toast.error("Error updating page");
            }
          })
          .error(() => {
            toast.error("Error updating page");
          });

        setSubmitting(false);
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              placeholder="Enter title"
              value={getValues(values, "title")}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Page URL</Form.Label>
            <Form.Control
              type="text"
              name="page_url"
              placeholder="Enter page url"
              value={getValues(values, "page_url")}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              name="location"
              placeholder="Enter location"
              value={getValues(values, "location")}
              onChange={handleChange}
            />
          </Form.Group>

          {/* @TODO: Add options for package_id */}

          <Form.Group as={Row} className="info-row">
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Package</Form.Label>
              <Form.Select
                name="package_id"
                value={getValues(values, "package_id")}
                onChange={handleChange}
              >
                {Object.keys(sitepage_select_values.sitepage_package_type).map(
                  (item) => {
                    return (
                      <option value={item}>
                        {sitepage_select_values.sitepage_package_type[item]}
                      </option>
                    );
                  },
                )}
              </Form.Select>
            </Form.Group>

            {/* @TODO: Add options for category_id */}
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Category</Form.Label>
              <Form.Select
                name="category_id"
                value={getValues(values, "category_id")}
                onChange={handleChange}
              >
                {Object.keys(sitepage_select_values.sitepage_category_type).map(
                  (item) => {
                    return (
                      <option value={item}>
                        {sitepage_select_values.sitepage_category_type[item]}
                      </option>
                    );
                  },
                )}
              </Form.Select>
            </Form.Group>
          </Form.Group>

          {/* <Form.Group as={Row}>
                        <Form.Group className="mb-3" as={Col}>
                            <Form.Label>Subcategory</Form.Label>
                            <Form.Select
                                name="subcategory_id"
                                value={getValues(values, "subcategory_id")}
                            >
                                <option value="1">Subcategory 1</option>
                                <option value="2">Subcategory 2</option>
                                <option value="3">Subcategory 3</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" as={Col}>
                            <Form.Label>Tags</Form.Label>
                            <Form.Control type="text" name="tags" placeholder="Enter tags" 
                                value={getValues(values, "tags")}
                            />
                        </Form.Group>
                    </Form.Group> */}
          {/* @TODO: Add options for subcategory_id */}

          {/* <Form.Group className="mb-3">
                        <Form.Label>Price</Form.Label>
                        <Form.Control type="number" name="price" placeholder="Enter price" />
                    </Form.Group> */}

          {/* @TODO: Add options */}
          <Form.Group as={Row} className="info-row">
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>View Privacy</Form.Label>
              <Form.Select
                name="auth_view"
                value={getValues(values, "auth_view")}
                onChange={handleChange}
              >
                <option value={"everyone"}>Everyone</option>
                <option value={"member"}>Page Members Only</option>
                <option value={"owner_member"}>Friends Only</option>
                <option value={"owner_member_member"}>
                  Friends of Friends
                </option>
                <option value={"owner_network"}>Friends and Networks</option>
                <option value={"registered"}>All Registered Members</option>
              </Form.Select>
            </Form.Group>

            {/* @TODO: Add options */}
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Comment Privacy</Form.Label>
              <Form.Select
                name="auth_comment"
                value={getValues(values, "auth_comment")}
                onChange={handleChange}
              >
                <option value={"owner_member"}>Friends Only</option>
                <option value={"owner_member_member"}>
                  Friends of Friends
                </option>
                <option value={"owner_network"}>Friends and Networks</option>
                <option value={"registered"}>All Registered Members</option>
              </Form.Select>
            </Form.Group>
          </Form.Group>

          <Form.Group as={Row} className="info-row">
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Poll Creation Privacy</Form.Label>
              <Form.Select
                name="splcreate"
                value={getValues(values, "splcreate")}
                onChange={handleChange}
              >
                <option value={"owner_member"}>Friends Only</option>
                <option value={"owner_member_member"}>
                  Friends of Friends
                </option>
                <option value={"owner_network"}>Friends and Networks</option>
                <option value={"registered"}>All Registered Members</option>
                <option value={"like_member"}>Who Liked This Page</option>
                <option value={"member"}>Page Members Only</option>
                <option value={"owner"}>Page Admins</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" as={Col}>
              <Form.Label> Sub Page Creation Privacy </Form.Label>
              <Form.Select
                name="sspcreate"
                value={getValues(values, "sspcreate")}
                onChange={handleChange}
              >
                <option value={"owner_member"}>Friends Only</option>
                <option value={"owner_member_member"}>
                  Friends of Friends
                </option>
                <option value={"owner_network"}>Friends and Networks</option>
                <option value={"registered"}>All Registered Members</option>
                <option value={"like_member"}>Who Liked This Page</option>
                <option value={"member"}>Page Members Only</option>
                <option value={"owner"}>Page Admins</option>
              </Form.Select>
            </Form.Group>
          </Form.Group>

          <Form.Group as={Row} className="info-row">
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Event Creation Privacy</Form.Label>
              <Form.Select
                name="secreate"
                value={getValues(values, "secreate")}
                onChange={handleChange}
              >
                <option value={"owner_member"}>Friends Only</option>
                <option value={"owner_member_member"}>
                  Friends of Friends
                </option>
                <option value={"owner_network"}>Friends and Networks</option>
                <option value={"registered"}>All Registered Members</option>
                <option value={"like_member"}>Who Liked This Page</option>
                <option value={"member"}>Page Members Only</option>
                <option value={"owner"}>Page Admins</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Discussion Creation Privacy</Form.Label>
              <Form.Select
                name="sdicreate"
                value={getValues(values, "sdicreate")}
                onChange={handleChange}
              >
                <option value={"owner_member"}>Friends Only</option>
                <option value={"owner_member_member"}>
                  Friends of Friends
                </option>
                <option value={"owner_network"}>Friends and Networks</option>
                <option value={"registered"}>All Registered Members</option>
                <option value={"like_member"}>Who Liked This Page</option>
                <option value={"member"}>Page Members Only</option>
                <option value={"owner"}>Page Admins</option>
              </Form.Select>
            </Form.Group>
          </Form.Group>

          <Form.Group as={Row} className="info-row">
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Photo Creation Privacy</Form.Label>
              <Form.Select
                name="spcreate"
                value={getValues(values, "spcreate")}
                onChange={handleChange}
              >
                <option value={"owner_member"}>Friends Only</option>
                <option value={"owner_member_member"}>
                  Friends of Friends
                </option>
                <option value={"owner_network"}>Friends and Networks</option>
                <option value={"registered"}>All Registered Members</option>
                <option value={"like_member"}>Who Liked This Page</option>
                <option value={"member"}>Page Members Only</option>
                <option value={"owner"}>Page Admins</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Video Creation Privacy</Form.Label>
              <Form.Select
                name="svcreate"
                value={getValues(values, "svcreate")}
                onChange={handleChange}
              >
                <option value={"owner_member"}>Friends Only</option>
                <option value={"owner_member_member"}>
                  Friends of Friends
                </option>
                <option value={"owner_network"}>Friends and Networks</option>
                <option value={"registered"}>All Registered Members</option>
                <option value={"like_member"}>Who Liked This Page</option>
                <option value={"member"}>Page Members Only</option>
                <option value={"owner"}>Page Admins</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Document Creation Privacy</Form.Label>
              <Form.Select
                name="sdcreate"
                value={getValues(values, "sdcreate")}
                onChange={handleChange}
              >
                <option value={"owner_member"}>Friends Only</option>
                <option value={"owner_member_member"}>
                  Friends of Friends
                </option>
                <option value={"owner_network"}>Friends and Networks</option>
                <option value={"registered"}>All Registered Members</option>
                <option value={"like_member"}>Who Liked This Page</option>
                <option value={"member"}>Page Members Only</option>
                <option value={"owner"}>Page Admins</option>
              </Form.Select>
            </Form.Group>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Group as={Col}>
              <Form.Check
                type="switch"
                label="Show in Search"
                name="search"
                checked={getValues(values, "search")}
                onChange={(e) => {
                  setFieldValue("search", e.target.checked);
                }}
              />
              <Form.Check
                type="switch"
                label="Draft Mode"
                name="draft"
                checked={getValues(values, "draft")}
                onChange={(e) => {
                  setFieldValue("draft", e.target.checked);
                }}
              />
            </Form.Group>
          </Form.Group>
          <Form.Group controlId="photo" className="mb-3">
            <Form.Label>Page Photo</Form.Label>
            <Form.Control type="file" name="photo"
              onChange={(e) => {
                setFieldValue("photo", e.target.files[0])
              }}
            />
            {
              values && values.photo ? (
                <img 
                  src={URL.createObjectURL(values.photo)}
                  style={{
                    width: "5rem",
                    height: "5rem", 
                    marginTop: "1rem",
                    border: "1px solid black"
                  }}
                />
              ) : null
            }
                
          </Form.Group>

          <Form.Group>
            <Button variant="primary" onClick={handleSubmit}>
              Save
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

const PageEditInfo = () => {
  return (
    <div className="page-edit-info-container">
      Page Edit Info
      <PageEditInfoForm />
    </div>
  );
};

export default PageEditInfo;
