import React, { useState, useEffect } from "react";
import { GridList } from "../../../components/lists/VerticalGrid";
import { useGetGroupMembers } from "../../../utils/api/functions/groups";

const GroupMembers = ({ group_id }) => {
  const { data, mutate } = useGetGroupMembers({
    group_id: group_id,
  });

  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (data && data.body && data.body.members) {
      setMembers((old) => [...data.body.members]);
    }
  }, [data]);
  
  return (
    <div className="groups-members-view group-view-content">
      <div className="groups-members-count">
        This group has {members.length}{" "}
        {members.length !== 1 ? "members" : "member"}
      </div>

      <GridList
        items={members}
        autosizerClass="groups-members-grid"
        colCount={1}
      />
    </div>
  );
};

export default GroupMembers;
