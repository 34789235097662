import useSWR from "swr";
import { fetcher } from "../handler";
import queryString from "query-string";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
const hostname = api_config.hostname;

export const useGetAds = (values) => {
  return useSWR(
    [
      hostname + "/communityads/index/index?limit=10&type=2&placementCount=1",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetOwnersAds = (values) => {
  return useSWR(
    [
      hostname + "/communityads/index/getcampaigns",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetPackages = (values) => {
  return useSWR(
    [
      hostname + "/communityads/index/getpackages",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};
