import { useToast } from 'rc-toastr';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { postBlockMember, postUnblockMember } from '../../utils/api/functions/members';

const UserBlockModal = ({show, handleClose, isBlocked, user_id}) => {

    const {toast} = useToast(); 

    const handleBlock = async() => {
        const res = await postBlockMember(user_id)
        
        if(res.status_code === 204){
            toast.success("User blocked!"); 
            setTimeout(() => {
                window.location.reload(); 
            }, 1500)
        }
        else {
            toast.error("Error blocking user");
        }
    }
    const handleUnblock = async() => {
        const res = await postUnblockMember(user_id)

        if(res.status_code === 204){
            toast.success("User unblocked!");
            setTimeout(() => {
                window.location.reload(); 
            }, 1500)
        }
        else {
            toast.error("Error unblocking user"); 
        }
    }

    return(
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"

        >
            <Modal.Header closeButton>{isBlocked ? "Unblock User" : "Block User"}</Modal.Header>
            <Modal.Body>
                Are you sure you want to {isBlocked ? "unblock" : "block"} this user?
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                {
                    isBlocked ? 
                    <Button onClick={handleUnblock} variant="danger">
                        Unblock
                    </Button>
                    :
                    <Button onClick={handleBlock} variant="danger">
                        Block
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default UserBlockModal; 