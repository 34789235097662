import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const EventSearch = ({handleSearch}) => {
  const [text, setText] = useState("");
  const [category, setCategory] = useState(""); 
  const [order, setOrder] = useState("DESC"); 
  const [listby, setListby] = useState("starttime");

  const handleTextChange = (e) => {
    setText(e.target.value); 
  }
  const handleCategoryChange = (e) => {
    setCategory(e.target.value); 
  }
  const handleListbyChange = (e) => {
    setListby(e.target.value); 
  }
  const handleOrderChange = (e) => {
    setOrder(e.target.value); 
  }

  const handleSubmit = () => {
    handleSearch({
      search_text: text, 
      category_id: category,
      order: listby + " " + order
    })
  }

  return (
    <div className="events-content-sidebar-item">
      <Form
        onSubmit={(e)=>{
          e.preventDefault();
        }}
      >
        <Form.Group className="mb-2" controlId="search">
          <Form.Label>Search Events:</Form.Label>
          <Form.Control 
            onChange={handleTextChange}
            value={text}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="category">
          <Form.Label>Category:</Form.Label>
          <Form.Select
            onChange={handleCategoryChange}
            value={category}
          >
            <option value="">All Categories</option>
            <option value="1">Arts</option>
            <option value="2">Business</option>
            <option value="3">Conferences</option>
            <option value="4">Festivals</option>
            <option value="5">Food</option>
            <option value="6">Fundraisers</option>
            <option value="7">Galleries</option>
            <option value="8">Health</option>
            <option value="9">Just For Fun</option>
            <option value="10">Kids</option>
            <option value="11">Learning</option>
            <option value="12">Literary</option>
            <option value="13">Movies</option>
            <option value="14">Musuems</option>
            <option value="15">Neighborhood</option>
            <option value="16">Networking</option>
            <option value="17">Nightlife</option>
            <option value="18">On Campus</option>
            <option value="19">Organization</option>
            <option value="20">Outdoors</option>
            <option value="21">Pets</option>
            <option value="22">Politics</option>
            <option value="23">Sales</option>
            <option value="24">Science</option>
            <option value="25">Spirituality</option>
            <option value="26">Sports</option>
            <option value="27">Technology</option>
            <option value="28">Theatre</option>
            <option value="29">Other</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2" controlId="list_by">
          <Form.Label>List By</Form.Label>
          <Form.Select
            onChange={handleListbyChange}
            value={listby}
          >
            <option value="starttime">Start Time</option>
            <option value="creation_date">Creation Date</option>
            <option value="member_count">Most Popular</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2" controlId="list_by">
          <Form.Label>Order</Form.Label>
          <Form.Select
            onChange={handleOrderChange}
            value={order}
          >
            <option value="DESC">Descending</option>
            <option value="ASC">Ascending</option>
          </Form.Select>
        </Form.Group> 
        <Form.Group>
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Search
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default EventSearch;
