import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import { useGetSitePageStatistics } from "../../../../utils/api/functions/sitepages";
import { colorArrayGen } from "../../../../utils/color";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const chart_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Page Insights",
    },
  },
};

const InsightsChart = () => {
  const { id } = useParams();

  const { data } = useGetSitePageStatistics(id, {
    page_id: id,
    type: "all",
    mode: "normal",
    chunk: "dd",
    period: "mm",
    start: 0,
    end: 0,
    offset: 0,
  });

  const [chartData, setChartData] = useState({});

  useEffect(() => {
    
    if (data?.status_code === 200 && data.body) {
      const labels = data.body.data.all
        .map((item, index) => {
          if (index === 0) return;
          return item[0];
        })
        .filter((item) => item !== undefined);

      const color_array = colorArrayGen(labels.length); // -1 for date field
      
      const datasets = data.body.data.all[0]
        .map((item, index) => {
          if (item === "Date") return;
          return {
            label: item,
            data: data.body.data.all
              .map((item, index) => {
                if (index === 0) return;
                return item[index];
              })
              .filter((item) => item !== undefined),
            borderColor: `rgb(${color_array[index - 1].r}, ${
              color_array[index - 1].g
            }, ${color_array[index - 1].b})`,
            backgroundColor: `rgba(${color_array[index - 1].r}, ${
              color_array[index - 1].g
            }, ${color_array[index - 1].b}, 0.5)`,
          };
        })
        .filter((item) => item !== undefined);

      setChartData(() => ({
        labels: labels ? labels : [],
        datasets: datasets,
      }));
    }
  }, [data]);
  if (Object.keys(chartData).length === 0) return null;
  
  return <Line options={chart_options} data={chartData} />;
};

const PageAdminInsights = () => {
  return (
    <div>
      Page Admin Insights
      <InsightsChart />
    </div>
  );
};

export default PageAdminInsights;
