import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Header from "../components/header/Header";
import { useGetMessages } from "../utils/api/functions/messages";
import { MessagesProvider } from "../utils/context/messages/messagesContext";

const MessagingWidget = () => {
  const { data, mutate } = useGetMessages();

  return <div></div>;
};

function Layout({ children }) {

  const {id} = useParams(); 
  const navigate = useNavigate(); 
  useEffect(() => {
    // ID param integer test 
    if(id && !/\d+/.test(id)){
      navigate("/404"); 
    }
  }, [id, navigate]);

  return (
    <div className={"default-layout-container"}>
      <MessagesProvider>
        <Header></Header>
        <div className="content-box">{children}</div>
      </MessagesProvider>
    </div>
  );
}

export default Layout;
