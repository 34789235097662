import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { MessageBox } from "../../components/widgets/MessagesWidget";
import { postNewMessage } from "../../utils/api/functions/messages";
import { postGlobalSearch } from "../../utils/api/functions/misc";
import { useMessages } from "../../utils/context/messages/messagesContext";


const MessagesChat = ({ message_data }) => {
  if (message_data)
    return (
      <MessageBox
        message_data={message_data}
        show={true}
        containerClass={"messages-chat"}
      />
    );
  else {
    return null;
  }
};

const truncate = (input) =>
  input?.length > 60 ? `${input.substring(0, 55)}...` : input;

const MessageItem = ({ message_item, onClick }) => {
  return (
    <div className="messages-list-item" onClick={onClick}>
      <div className="messages-list-item-img">
        <img src={message_item.sender.image} />
      </div>
      <div className="messages-list-item-preview">
        <div className="display-sender-name">
          {message_item?.sender?.displayname
            .replace("<i>", "")
            .replace("</i>", "")}
        </div>
        <div className="display-message-preview">
          {truncate(message_item.message.body)}
        </div>
      </div>
    </div>
  );
};
const MessagesList = ({ messages, handleSelect }) => {
  return (

    <div className="messages-list">
      {messages.map((item) => (
        <MessageItem message_item={item} onClick={() => handleSelect(item)} />
      ))}
    </div>

  );
};


const ComposeMessageSearch = ({handleComposeNew}) => {
  const [value, setValue] = useState(""); 
  const [results, setResults] = useState([]); 
  const [showResults, setShowResults] = useState(false); 
  const searchPreview = useRef(null); 

  const handleChange = (e) => {
    setValue(e.target.value);
  }

  useEffect(() => {
    const getResults = async () => {
      const form = new FormData(); 
      form.append("query", value); 
      form.append('type', "user")
      const data = await postGlobalSearch(form);

      if(data && data.body && data.body.result) {
        setResults(() => [...data.body.result].slice(0, 5)); 
      }

      if(value.length > 0) {
        setShowResults(true); 
      }
      else {
        setShowResults(false); 
      }

    }

    getResults(); 
  }, [value])

  // Handle click outside of search results 
  useEffect(() => {
    const handleClickOutside = (e) => {
      if(searchPreview.current && !searchPreview.current.contains(e.target)) {
        setShowResults(false); 
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [])

  return (
    <div className="compose-message-search">
      <Form.Control 
        type="search"
        placeholder="Search"
        value={value}
        onChange={handleChange}
      />
      {
        showResults ? (
        <div className="compose-message-search-preview" ref={searchPreview}>
          {
            results.map((result, index) => {
              return (
                <div className="compose-message-search-result" key={index} onClick={()=>{handleComposeNew(result.user_id)}}>
                  <div className="compose-message-search-result-img">
                    <img src={result.image} />
                  </div>
                  <div className="compose-message-search-result-name">
                    {result.displayname}
                  </div>
                </div>
              )
            })
          }
        </div>
        ) : null
      }
      
    </div>
  )
}

const MessagesActions = ({handleNewMessage}) => {
  const [composing, setComposing] = useState(false);

  const handleComposeNew = (user_id) => {
    handleNewMessage(user_id)
    setComposing(false); 
  }

  return (
    <div className="messages-actions">
      <div className="messages-actions-icons">
        <h3 className="messages-actions-header">Messages</h3>
        <div className="messages-actions-icons-fa">
          <FontAwesomeIcon icon={faPenToSquare} onClick={()=>{setComposing(!composing)}} />
        </div>
      </div>
      <div className="messages-actions-search">
        {
          composing ? (<ComposeMessageSearch handleComposeNew={handleComposeNew}/>) : null
        }
      </div>
    </div>
  )
}

const Messages = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const {openMessage, messages} = useMessages(); 

  const handleSelect = (message_data) => {
    setSelectedChat(() => message_data);
    openMessage(message_data, false);  
  };

  const handleNewMessage = async(user_id) => {
    const formData = new FormData(); 
    formData.append("toValues", user_id); 
    formData.append("title", " "); 
    formData.append("body", " "); 
    formData.append("react_app", true); 
    const result = await postNewMessage(formData);
    
    if(result && result.status_code === 200) {
      handleSelect(result.body.messages)
    }
  } 

  return (
    <div className="messages-container">
      <MessagesActions 
        handleNewMessage={handleNewMessage}
      />
      <div className="messages-container-content">

        <MessagesList messages={messages} handleSelect={handleSelect} />

        <MessagesChat message_data={selectedChat} />
      </div>
    </div>
  );
};

export default Messages;
