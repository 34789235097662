import queryString from "query-string";
import useSWR from "swr";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;

export const useGetNotifications = (values) => {
  return useSWR(
    [
      hostname + "/notifications?"+queryString.stringify(values),
      {
        method: "GET",
        //body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetFriendsRequests = (values) => {
  return useSWR(
    [
      hostname + "/notifications/friend-request",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetNewNotificationCount = (values) => {
  return useSWR(
    [
      hostname + "/notifications/new-updates",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const postMarkAllNotificationsRead = (values) => {
  return fetcher(
    [
      hostname + "/notifications/markallread",
      {
        method: "POST",
        body: queryString.stringify(values),
        headers: {
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false,
    true,
  );
};

export const postMarkNotificationRead = (values) => {
  return fetcher([hostname + "/notifications/markread", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      Accept: "application/json",
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
    },
  }], false, true);
};

export const postMarkNotificationUnread = (values) => {
  return fetcher([hostname + "/notifications/markunread", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      Accept: "application/json",
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
    },
  }], false, true);
};

export const postDeleteNotification = (values) => {
  return fetcher([hostname + `/notifications/delete`, {
    method: "POST",
    body: queryString.stringify(values), 
    headers: {
      Accept: "application/json",
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
    },
  }], false, true);
}