import React from "react";

const Advertise = () => {
  return (
    <div className="advertise-container">
      <h1>Advertise</h1>
    </div>
  );
};

export default Advertise;
