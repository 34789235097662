import { Link } from "react-router-dom";
import useAuth from "../../utils/authentication";
//Import dropdown component
import routes from "../../settings/routes";
import DropdownMenu from "../menus/Dropdown";

export const portalLinks = [
  { link: "/pages/page/136", title: "Home Schooling" },
];

export const pagesLinks = [
  { link: "/pages/home", title: "Pages Home" },
  { link: "/pages/browse", title: "Browse Pages" },
  { link: "/pages/my-pages", title: "My Pages" },
  { link: "/pages/pinboard", title: "Pinboard" },
  { link: "/pages/claim", title: "Claim a Page" },
  { link: "/pages/home-schooling", title: "Home Schooling" },
];

export const groupsLinks = [];

function Nav() {
  const { authed, login, logout, userId } = useAuth();

  return (
    <div className="default-header-nav">
      {/* <Link to="/home">My News Feed</Link>
            <Link to="/profile">My Profile</Link>
            <Link to="/profile/friends">My Friends</Link>
            <DropdownMenu title="Portals" mainLink="/portals" links={portalLinks} menuVariant="light" className="default-header-nav-dropdown"/>
            <DropdownMenu title="Pages" mainLink="/pages" links={pagesLinks} menuVariant="light" className="default-header-nav-dropdown" />
            <Link to="/groups">Groups</Link>
            <Link to="/faq">FAQs</Link>
            <Link to="/home">More</Link> */}

      {routes.map((item, i) => {
        if (item.nav !== true) return;

        if (item.navOptions.subMenu) {
          let submenu_links = item.navOptions.subMenu;
          if (
            item.navOptions.link &&
            item.navOptions.link.includes("profile")
          ) {
            item.navOptions.link = item.navOptions.link.replace(
              ":id",
              localStorage.getItem("user_id"),
            );

            submenu_links = submenu_links.map((val) => {
              val.link = val.link.replace(
                ":id",
                localStorage.getItem("user_id"),
              );
              return val;
            });
          }

          return (
            <DropdownMenu
              className="default-header-nav-dropdown"
              title={item.navOptions.name}
              mainLink={item.navOptions.link}
              links={item.navOptions.subMenu}
              icon={item.navOptions.icon}
            />
          );
        } else {
          return (
            <Link
              to={
                item.navOptions.link === "/profile" &&
                localStorage.getItem("user_id")
                  ? `${item.navOptions.link}/${localStorage.getItem("user_id")}`
                  : item.navOptions.link
              }
              replace
            >
              {item.navOptions.name}
            </Link>
          );
        }
      })}
    </div>
  );
}

export default Nav;
