// Settings for different form value mappings

// Core Value Maps

// Sitepage specific value maps
const sitepage_privacy_type = {
  everyone: "Everyone",
  registered: "Registered",
  owner_network: "Friends and Networks",
  owner_member_member: "Friends of Friends",
  owner_member: "Friends Only",
  member: "Page Members Only",
  owner: "Page Admins",
};

const sitepage_package_type = {
  1: "Basic",
};

const sitepage_category_type = {
  88: "Animals",
  55: "Arts & Crafts",
  1: "Automobile",
  39: "Electronics",
  127: "Environment",
  117: "Family",
  17: "Fashion",
  57: "Fitness & Exercise",
  52: "Food",
  76: "Gaming",
  140: "Health & Wellness",
  136: "Home Schooling",
  142: "Internet Marketing",
  6: "Jobs",
  71: "Literature",
  139: "Media & Entertainment",
  12: "Movies & TV",
  56: "Music",
  83: "Outdoor Activities",
  46: "Places",
  143: "Politics",
  23: "Real Estate",
  132: "Religious Organization",
  29: "Sports",
  121: "Technology",
};

const sitepage_draft_type = {
  1: "Draft",
  0: "Published",
};

export const sitepage_select_values = {
  sitepage_privacy_type: sitepage_privacy_type,
  sitepage_package_type: sitepage_package_type,
  sitepage_category_type: sitepage_category_type,
  sitepage_draft_type: sitepage_draft_type,
};

const XapitSelectValues = {
  sitepage: {
    sitepage_privacy_type: sitepage_privacy_type,
    sitepage_package_type: sitepage_package_type,
    sitepage_category_type: sitepage_category_type,
    sitepage_draft_type: sitepage_draft_type,
  },
};
