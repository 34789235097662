import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { postDeleteEvent, useGetOwnersEvents } from "../../utils/api/functions/advancedEvents";
import EventDeleteConfirmationModal from "./EventDeleteConfirmation";
import EventsList from "./EventList";
import EventSearch from "./EventSearch";

const CreateEventButton = () => {
  const navigate = useNavigate();

  return (
    <div className="events-content-sidebar-item">
      <Button variant="primary" onClick={() => navigate("/events/create")}>
        Create Event
      </Button>
    </div>
  );
};

const MyEvents = () => {
  const [events, setEvents] = useState([]);
  const [searchData, setSearchData] = useState({
    order: "starttime DESC"
  })

  const { data, mutate, isLoading } = useGetOwnersEvents(searchData);
  const navigate = useNavigate(); 

  useEffect(() => {
    if (data?.body?.response) {
      setEvents((old) => [...data.body.response]);
    }
  }, [data]);
  
  const handleSearch = (search_values) => {
    setEvents(() => []);
    setSearchData({...searchData, ...search_values}); 
  }

  const {toast} = useToast(); 
  
  const Actions = (event) => {
    const [showDelete, setShowDelete] = useState(false); 
    const handleShowModal = () => {
      console.log("SHOW MODAL")
      setShowDelete(true); 
    }
    const handleHideModal = () => {
      setShowDelete(false);
    }

    const handleDeleteEvent = async() => {
      const res = await postDeleteEvent(event.event.event_id);
      if(res?.status_code === 204){
        toast.success("Event deleted!");
        mutate(); 
      }
      else {
        toast.error("Error deleting event."); 
      }
    }; 
    
    return (
      <div className="default-event-list-item-actions">
        <Button variant="primary" onClick={()=>{navigate(`/events/event/${event.event.event_id}/edit`)}}>
          <FontAwesomeIcon icon={faPen} /> Edit
        </Button>
        <Button variant="danger" onClick={handleShowModal}>
          <FontAwesomeIcon icon={faTrash} /> Delete
        </Button>
        <EventDeleteConfirmationModal 
          show={showDelete}
          handleHide={handleHideModal}
          handleDelete={handleDeleteEvent}
        /> 
      </div>
    );
  };

  return (
    <div className="events-my-container events-content">
      <EventsList
        events={events}
        action_buttons={Actions}
        isLoading={isLoading}
      />
      <div className="events-content-sidebar">
        <EventSearch handleSearch={handleSearch}/>
        <CreateEventButton />
      </div>
      
    </div>
  );
};

export default MyEvents;
