import {
  faArrowLeft,
  faArrowRight,
  faFlag,
  faPen,
  faShareNodes,
  faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { postJoinEvent, postLeaveEvent, useGetEvent } from "../../../utils/api/functions/advancedEvents";

import EventViewHeader from "./EventHeader";
// import PostsFeed from "../../../components/posts/PostsFeed";
import { useToast } from "rc-toastr";
import PostsFeed from "../../../components/posts/Feedv2";
import { ReportModal } from "../../../components/widgets/ReportModal";
import ShareUserContent from "../../../components/widgets/ShareUserContent";
import { postDeleteEvent } from "../../../utils/api/functions/advancedEvents";
import { getReadableDate } from "../../../utils/parsing";
import EventDeleteConfirmationModal from "../EventDeleteConfirmation";
import EventGuests from "./EventGuests";
import EventPhotos from "./EventPhotos";


const eventNavItems = (event_id, event_data) => [
  {
    title: "UPDATES",
    element: [
      <PostsFeed
        feed_params={{
          subject_type: "event",
          subject_id: event_id,
        }}
      />,
    ],
    active: true,
  },
  {
    title: "GUESTS",
    element: [<EventGuests event_id={event_id} />],
    active: false,
  },
  {
    title: "PHOTOS",
    element: [<EventPhotos event_id={event_id} />],
    active: false,
  },
];

const EventInfoSidebar = ({ description, starttime, endtime }) => {
  return (
    <div className="event-sidebar-container">
      <div className="event-sidebar-item">
        <div className="event-sidebar-header">Event Details</div>
        <div className="event-sidebar-description">
          {description}
        </div>
        <div>
          <span
            style={{
              fontWeight: 'bold'
            }}
          >Start:</span> { starttime ? (getReadableDate(new Date(starttime))) : null}
        </div>
        <div>
          <span
            style={{
              fontWeight: "bold"
            }}
          >End:</span> { endtime ? (getReadableDate(new Date(endtime))) : null}
        </div>
      </div>
    </div>
  );
};

const LeaveOrJoin = ({isMember}) => {
  const {toast} = useToast();
  const {id} = useParams();
  console.log("isMember", isMember)
  const [member_status, setMemberStatus] = useState(isMember); 

  useEffect(() => {
    setMemberStatus(isMember); 
  }, [isMember])

  const handleJoin = async() => {
    const res = await postJoinEvent(id); 
    if(res?.status_code === 204){
      toast.success("Joined event!"); 
      setMemberStatus(true); 
    }
    else {
      toast.error("Erorr joining event"); 
    }
  }

  const handleLeave = async() => {
    const res = await postLeaveEvent(id); 
    if(res?.status_code === 204){
      toast.success("Left event!"); 
      setMemberStatus(false); 
    }
    else {
      toast.error("Error leaving event"); 
    }
  }

  if(member_status){
    return (
    <div onClick={handleLeave}>
      <FontAwesomeIcon icon={faArrowLeft} /> Leave Event
    </div>
    )
  }
  return (
    <div onClick={handleJoin}>
      <FontAwesomeIcon icon={faArrowRight} /> Join Event
    </div>
  )
}

const ShareEvent = ({event_data}) => {
  const {id} = useParams(); 

  const [showShare, setShowShare] = useState(false);
  const handleShowShareModal = () => {
    setShowShare(true); 
  }
  const handleHideShareModal = () => {
    setShowShare(false); 
  }

  return(
    <div onClick={handleShowShareModal}>
      <FontAwesomeIcon icon={faShareNodes} /> Share This Event
      <ShareUserContent 
        show={showShare}
        onHide={handleHideShareModal}
        data={event_data}
        type={"event"}
        object_id={id}
      />
    </div>
  )
}

const ReportEvent = () => {
  const {id} = useParams(); 
  const [showReport, setReport] = useState(false); 

  const handleShowReport = () => {
    setReport(true); 
  }
  const handleHideReport = () => {
    console.log("HIDE REPORT")
    setReport(()=>false); 
  }

  return (
    <>
      <div onClick={handleShowReport}>
        <FontAwesomeIcon icon={faFlag} /> Report  
      </div>
      <ReportModal 
      show={showReport}
      onHide={()=>{setReport(false)}}
      type="event"
      id={id}
      />
  </>
  )
}


const EventActionsSidebar = ({isOwner, isMember, event_data}) => {
  const [showDelete, setShowDelete] = useState(false); 
  const {id} = useParams(); 
  const {toast} = useToast(); 
  const navigate = useNavigate(); 


  // DELETE ACTIONS 
  const handleShowDeleteModal = () => {
    setShowDelete(true); 
  }
  const handleHideDeleteModal = () => {
    setShowDelete(false); 
  }

  const handleDeleteEvent = async() => {
    const res = await postDeleteEvent(id);
    if(res?.status_code === 204){
      toast.success("Event deleted!");
      navigate("/events/my-events"); 
    }
    else {
      toast.error("Error deleting event."); 
    }
  }

  return (
    <div className="event-sidebar-container">
      <div className="event-sidebar-item event-sidebar-actions">
        {
          isOwner && 
          <div>
            <Link to={"edit"}>
              <FontAwesomeIcon icon={faPen} /> Edit Event Details
            </Link>
          </div>
        }
        {
          !isOwner && <LeaveOrJoin isMember={isMember}/>
        }
        {
          !isOwner && <ReportEvent />
        }
        
        <ShareEvent event_data={event_data}/>
        {
          isOwner && 
          <div onClick={handleShowDeleteModal}>
            <FontAwesomeIcon icon={faTrashCan} /> Delete Event
          </div>
        }
      </div>

      <EventDeleteConfirmationModal 
        show={showDelete}
        handleHide={handleHideDeleteModal}
        handleDelete={handleDeleteEvent}
      />
    </div>
  );
};

const EventView = () => {
  const { id } = useParams();
  const { data, mutate, isLoading } = useGetEvent(id, {});
  const [eventData, setEvent] = useState({});

  const _eventNavItems = eventNavItems(id, eventData);
  const [activeNav, setActive] = useState(0);
  const [content, setContent] = useState(_eventNavItems[0].element);

  useEffect(() => {
    if (data?.body?.response) {
      const new_data = { ...data.body.response };
      console.log("Event: ", new_data)
      setEvent((old) => new_data);
    }
  }, [data]);

  const handleNavSwitch = (index) => {
    setActive(index);
    setContent(_eventNavItems[index].element);
  };

  return (
    <div className="event-view-container">
      <EventViewHeader _event={eventData} />
      <div className="event-view-content">
        <div className="event-view-content-nav">
          {_eventNavItems.map((val, index) => {
            return (
              <h6
                className={
                  activeNav === index
                    ? "event-nav-item event-active-nav"
                    : "event-nav-item"
                }
                onClick={() => {
                  handleNavSwitch(index);
                }}
              >
                {val.title}
              </h6>
            );
          })}
        </div>
        <div className="event-view-content-wrapper">
          <EventInfoSidebar description={eventData.description} starttime={eventData.starttime} endtime={eventData.endtime}/>
          <div className="event-view-content-main">{content}</div>
          {
            Object.keys(eventData).length > 0 ? (<EventActionsSidebar isOwner={eventData.isowner} isMember={eventData.isMember} event_data={eventData}/>) : null
          }
          
        </div>
      </div>
    </div>
  );
};

export default EventView;
