import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;

export const createPostUploadVideo = (values, onProgress) => {
  return fetcher(
    [
      hostname + "/advancedvideos/create",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
    false, 
    (progress)=>{
      onProgress(progress)
    }
  );
};
