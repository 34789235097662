import useSWR from "swr";
import { fetcher } from "../handler";
import queryString from "query-string";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
const hostname = api_config.hostname;

export const useBrowseGroups = (values) => {
  return useSWR(
    [
      hostname + "/groups",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );
};

export const useGetManagedGroups = (values) => {
  return useSWR(
    [
      hostname + "/groups/manage",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetOwnersGroups = (values) => {
  return useSWR(
    [
      hostname + "/groups/manage",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const postCreateGroup = (values) => {
  return fetcher(
    [
      hostname + "/groups/create",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
  );
};

export const useGetGroup = (group_id, values) => {
  return useSWR(
    [
      hostname + `/groups/view/${group_id}`,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const postEditGroup = (group_id, values) => {
  return fetcher(
    [
      hostname + "/groups/edit/" + group_id,
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
  );
};
export const useGetEditGroup = (group_id, values) => {
  return useSWR(
    [
      hostname + `/groups/edit/${group_id}`,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const postDeleteGroup = (id, values) => {
  return fetcher(
    [
      hostname + "/groups/delete/" + id,
      {
        method: "DELETE",
        //body: values,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false,
    true,
  );
};

export const useGetGroupMembers = (values) => {
  return useSWR(
    [
      hostname + "/groups/member/list",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGroupInviteMember = (values) => {
  return fetcher(hostname + "/groups/member/invite", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGroupAcceptMemberRequest = (values) => {
  return fetcher(hostname + "/groups/member/accept", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGroupIgnoreMemberRequest = (values) => {
  return fetcher(hostname + "/groups/member/ignore", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const postLeaveGroup = (id, values) => {
  return fetcher(
    [
      hostname + "/groups/member/leave/" + id,
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
  );
};

export const postJoinGroup = (id, values) => {
  return fetcher(
    [
      hostname + "/groups/member/join/" + id,
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
  );
};

export const useRequestGroupMembership = (values) => {
  return fetcher(hostname + "/groups/member/request", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGroupApproveRequest = (values) => {
  return fetcher(hostname + "/groups/member/approve", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useCancelMemberRequest = (values) => {
  return fetcher(hostname + "/groups/member/request-cancel", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useRemoveMember = (values) => {
  return fetcher(hostname + "/groups/member/remove", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useEditGroupStaff = (values) => {
  return fetcher(hostname + "/groups/member/edit", {
    method: "PUT",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const usePromoteGroupMember = (values) => {
  return fetcher(hostname + "/groups/member/promote", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useDemoteGroupMember = (values) => {
  return fetcher(hostname + "/groups/memeber/demote", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGetGroupPhotos = (group_id, values) => {
  return useSWR(
    [
      hostname + "/groups/photo/list/" + group_id,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useUploadGroupPhotos = (values) => {
  return fetcher(hostname + "/groups/photo/list", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGetGroupPhoto = (values) => {
  return useSWR(
    [
      hostname + "/groups/photo/view",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useEditGroupPhoto = (values) => {
  return fetcher(hostname + "/groups/photo/edit", {
    method: "PUT",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useDeleteGroupPhoto = (values) => {
  return fetcher(hostname + "/groups/photo/delete", {
    method: "DELETE",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGetGroupDiscussions = (values) => {
  return useSWR(
    [
      hostname + "/groups/topic/index",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useCreateGroupDiscussion = (values) => {
  return fetcher(hostname + "/groups/topic/create", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGetGroupDiscussion = (values) => {
  return useSWR(
    [
      hostname + "/groups/topic/view",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useCreateGroupDiscussionReply = (values) => {
  return fetcher(hostname + "/groups/topic/post", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useMakeGroupDiscussionStickyUnsticky = (values) => {
  return fetcher(hostname + "/groups/topic/sticky", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useOpenCloseGroupDiscussion = (values) => {
  return fetcher(hostname + "/groups/topic/close", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useRenameDiscussion = (values) => {
  return fetcher(hostname + "/groups/topic/rename", {
    method: "PUT",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useWatchStopWatchingDiscussion = (values) => {
  return fetcher(hostname + "/groups/topic/watch", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useDeleteDiscussion = (values) => {
  return fetcher(hostname + "/groups/topic/delete", {
    method: "DELETE",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGetGroupAlbums = (group_id, album_id, values) => {
  return useSWR(
    [
      hostname + "/groups/photos/viewalbum/" + group_id + "/" + album_id,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useBrowseGroupVideos = (group_id, values) => {
  return useSWR(
    [
      hostname + "/groups/videos/browse/" + group_id,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};
