import {
  faCalendar,
  faLightbulb,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Bars } from "react-loading-icons";
import { useParams } from "react-router-dom";
import { useGetUserInfo } from "../../utils/api/functions/members";

const UserIcon = <FontAwesomeIcon icon={faUser} />;
const LightBulbIcon = <FontAwesomeIcon icon={faLightbulb} />;
const CalendarIcon = <FontAwesomeIcon icon={faCalendar} />;

const PrivateDetails = ({data}) => {



  return(
    <div className="profile-info-display-private">
      <div className="profile-private-tooltip">
        {LightBulbIcon} This field is hidden and only visible to you and
        admins:
      </div>
      <div className="profile-info-section-header">Private Details</div>
      {
        data && data.profile_fields ? (
          <>
          <span>
            <strong>City:</strong>
            &nbsp; {data.profile_fields.filter((field) => field.type === "city")[0]?.value || "N/A"}
          </span>
          <span>
            <strong>State (or Province/Territory):</strong>
            &nbsp; {data.profile_fields.filter((field) => field.type === "state")[0]?.value || "N/A"}
          </span>
          <span>
            <strong>Country:</strong>
            &nbsp; {data.profile_fields.filter((field) => field.type === "country")[0]?.value || "N/A"}
          </span>
          </>
        ) : null
      }
      
    </div>
  )
}

const ProfileInfo = () => {
  const { id } = useParams();
  const { data, isLoading, mutate } = useGetUserInfo({
    user_id: id,
  });
  const [info, setInfo] = useState({});

  const isOwner = id == localStorage.getItem("user_id");

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setInfo((old) => ({
        ...old,
        ...data.body.response,
      }));
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="loading-icon-wrapper">
        <Bars stroke="#00BCD4" fill="#00BCD4" />
      </div>
    );
  }

  return (
    <div className="profile-info">
      {Object.keys(info).length > 0 ? (
        <div className="profile-info-display">
          <div className="profile-info-section-header">Public Details</div>
          <span>
            <strong>{UserIcon} First Name:</strong>
            &nbsp;
            {info.displayname.split(" ")[0]}
          </span>
          <span>
            <strong>{UserIcon} Last Name:</strong>
            &nbsp;
            {info.displayname.split(" ")[1]}
          </span>
          {isOwner ? <PrivateDetails data={info} /> : null}
          
        </div>
      ) : null}
    </div>
  );
};

export default ProfileInfo;
