import React, { useEffect, useState } from "react";
import Layout from "./Default";
import routes from "../settings/routes";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";

const PageDashboardNav = () => {
  const [nav, setNav] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const page_dashboard_nav_routes = routes.filter((val) => {
      if (val.name === "PageDashboard") return true;
      return false;
    });
    if (!page_dashboard_nav_routes) return;

    const links = [];
    for (
      let i = 0;
      i < page_dashboard_nav_routes[0].navOptions.subMenu.length;
      i++
    ) {
      let link = page_dashboard_nav_routes[0].navOptions.subMenu[i];

      if (link.link.includes(":id")) {

        link.link = link.link.replace(":id", id);
      }
      links.push(link);
    }


    setNav(() => [...links]);
  }, []);

  return (
    <div className="page-dashboard-nav">
      <div className="page-dashboard-nav-category">Content</div>
      <div className="page-dashboard-nav-links">
        {nav.map((val, index) => {
          return val.category === "content" ? (
            <NavLink
              to={val.link}
              key={val.category + String(index)}
              className="page-dashboard-nav-item"
              activeClassName="page-dashboard-nav-item active"
              end={true}
            >
              {val.name}
            </NavLink>
          ) : null;
        })}
      </div>
      <div className="page-dashboard-nav-category">Admin</div>
      <div className="page-dashboard-nav-links">
        {nav.map((val, index) => {
          return val.category === "admin" ? (
            <NavLink
              to={val.link}
              key={index}
              className="page-dashboard-nav-item"
              activeClassName="page-dashboard-nav-item active"
              end={true}
            >
              {val.name}
            </NavLink>
          ) : null;
        })}
      </div>
      <div className="page-dashboard-nav-category">Promotion</div>
      <div className="page-dashboard-nav-links">
        {nav.map((val, index) => {
          return val.category === "promotion" ? (
            <NavLink
              to={val.link}
              key={index}
              className="page-dashboard-nav-item"
              activeClassName="page-dashboard-nav-item active"
              end={true}
            >
              {val.name}
            </NavLink>
          ) : null;
        })}
      </div>
    </div>
  );
};

const PageDashboardLayout = ({ children }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleViewPage = () => {
    navigate(`/pages/page/${id}`);
  };

  return (
    <Layout>
      <div className="page-dashboard-container">
        <PageDashboardNav />
        <div className="page-dashboard-content-container">
          <div className="page-dashboard-content-container-header">
            <div className="page-dashboard-content-container-header-title">
              Dashboard: {id}
            </div>
            <div className="page-dashboard">
              <Button onClick={handleViewPage}> View Page </Button>
            </div>
          </div>
          {children}
        </div>
      </div>
    </Layout>
  );
};

export default PageDashboardLayout;
