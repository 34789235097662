import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { postGlobalSearch } from "../../utils/api/functions/misc";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserGroup,
  faFileLines,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import InfiniteLoader from "react-window-infinite-loader";

const ResultItem = ({ data, index, style }) => {
  const [link, setLink] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let item_data = data[index];
    switch (item_data.type) {
      case "User":
      case "User":
        setLink(`/profile/${item_data.user_id}`);
        break;
      case "Group":
        setLink(`/groups/group/${item_data.group_id}`);
        break;
      case "Sitepage":
        setLink(`/pages/page/${item_data.page_id}`);
        break;
      default:
        break;
    }
  }, [data]);

  return (
    <div
      className="search-view-list-item"
      style={style}
      onClick={() => {
        navigate(link);
      }}
    >
      <div className="search-view-list-item-image">
        <img src={data[index].image} />
      </div>
      <div className="search-view-list-item-content">
        <p>{data[index].title || data[index].displayname}</p>
        <p className="search-view-list-item-content-type">{data[index].type}</p>
      </div>
    </div>
  );
};

// const ResultsList = ({results}) => {

//     useEffect(() => {
//
//     }, [results])

//     return(

//     )
// }

const SearchFilters = () => {
  return <div className="search-view-filters"></div>;
};

const SearchField = ({ handle }) => {
  return (
    <Form>
      <Form.Group controlId="search">
        <Form.Control
          type="text"
          onChange={handle}
          placeholder="Enter Search Here..."
        />
      </Form.Group>
    </Form>
  );
};

const CategoryItem = ({ icon, title, active, ...props }) => {
  return (
    <div
      className={`search-category-item ${active ? "active" : ""}`}
      {...props}
    >
      <FontAwesomeIcon icon={icon} />
      <p>{title}</p>
    </div>
  );
};

const SearchCategories = ({ toggleHandle, activeCategory }) => {
  return (
    <div className="search-view-categories">
      <CategoryItem
        icon={faUser}
        title="Users"
        onClick={() => {
          toggleHandle("Users");
        }}
        active={activeCategory === "Users"}
      />
      <CategoryItem
        icon={faUserGroup}
        title="Groups"
        onClick={() => {
          toggleHandle("Groups");
        }}
        active={activeCategory === "Groups"}
      />
      <CategoryItem
        icon={faFileLines}
        title="Pages"
        onClick={() => {
          toggleHandle("Pages");
        }}
        active={activeCategory === "Pages"}
      />
      <CategoryItem
        icon={faCalendar}
        title="Events"
        onClick={() => {
          toggleHandle("Events");
        }}
        active={activeCategory === "Events"}
      />
    </div>
  );
};

const Search = () => {
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [page, setPage] = useState(1);
  const [loadedCount, setLoadedCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [category, setCategory] = useState("");
  const [loadingPage, setLoadingPage] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("query")) {
      setQuery(searchParams.get("query"));
    }
  }, [searchParams]);

  useEffect(() => {
    const getResults = async () => {
      const form = new FormData();
      form.append("query", query);
      setPage(0);
      form.append("page", "1");
      switch (category) {
        case "Users":
          form.append("type", "user");
          break;
        case "Groups":
          form.append("type", "group");
          break;
        case "Pages":
          form.append("type", "sitepage_page");
          break;
        case "Events":
          form.append("type", "event");
          break;
      }

      const data = await postGlobalSearch(form);

      if (data && data.body && data.body.result) {
        setTotal(data.body.totalItemCount);
        setSearchData([...data.body.result]);
      }
    };

    if (query.length > 0) {
      getResults();
    }
  }, [query, category]);

  // useEffect(() => {

  // }, [category])
  const LOADING = 1;
  const LOADED = 2;
  let itemStatusMap = {};
  const isItemLoaded = (index) => !!itemStatusMap[index];

  let currently_loading = false;

  const loadData = (start, stop) => {
    setLoadingPage(true);
    for (let i = start; i <= stop; i++) {
      itemStatusMap[i] = LOADING;
    }
    return new Promise(async (resolve, reject) => {
      const form = new FormData();
      form.append("query", query);
      switch (category) {
        case "Users":
          form.append("type", "user");
          break;
        case "Groups":
          form.append("type", "group");
          break;
        case "Pages":
          form.append("type", "sitepage_page");
          break;
        case "Events":
          form.append("type", "event");
          break;
      }
      let new_page = page + 1;
      form.append("page", new_page);
      setPage(new_page);

      const data = await postGlobalSearch(form);
      if (data && data.body && data.body.result) {
        setTotal(data.body.totalItemCount);
        setSearchData([...searchData, ...data.body.result]);
        for (let i = start; i <= stop; i++) {
          itemStatusMap[i] = LOADED;
        }
        setLoadingPage(false);
        resolve();
      }
    });
  };
  const loadMoreResults = loadingPage ? () => {} : loadData;

  const handleSearch = (e) => {
    navigate({
      pathname: "/search",
      search: `?query=${e.target.value}`,
    });
  };

  const toggleCategory = (selection) => {
    if (category === selection) {
      setCategory("");
    } else {
      setCategory(selection);
    }
  };

  return (
    <div className="search-view-container">
      <div className="search-view-wrapper">
        <div className="search-view-header">
          <h4>Search</h4>
          <SearchField handle={handleSearch} />
        </div>
        <div className="search-view-content">
          <SearchCategories
            toggleHandle={toggleCategory}
            activeCategory={category}
          />
          <div className="search-view-list-wrapper">
            <div className="search-view-list">
              <AutoSizer>
                {({ height, width }) => (
                  <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={total}
                    loadMoreItems={loadMoreResults}
                  >
                    {({ onItemsRendered, ref }) => (
                      <List
                        height={height}
                        itemCount={total}
                        itemSize={75}
                        itemData={searchData}
                        onItemsRendered={onItemsRendered}
                        width={width}
                        ref={ref}
                      >
                        {({ index, style }) => {
                          const item = searchData[index];
                          if (!item) {
                            return <div style={style}>Loading...</div>;
                          }
                          return (
                            <ResultItem
                              data={searchData}
                              style={style}
                              index={index}
                            />
                          );
                        }}
                      </List>
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer>
            </div>
          </div>
        </div>
        <div className="search-view-footer"></div>
      </div>
    </div>
  );
};

export default Search;
