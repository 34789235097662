import { Formik } from "formik";
import { useToast } from "rc-toastr";
import React from "react";
import { Button, Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { postCreateEvent } from "../../utils/api/functions/advancedEvents";


const CreateEventSchema = Yup.object().shape({
  title: Yup.string().required("Event title is required"),
  description: Yup.string().required("Event description is required"),
  starttime: Yup.string().required("Event start time is required"),
  endtime: Yup.string().required("Event end time is required"),
  host: Yup.string(),
  location: Yup.string(),
  category_id: Yup.number().required("Event category is required"),
  search: Yup.boolean(),
  approval: Yup.boolean(),
  auth_invite: Yup.string(),
  auth_view: Yup.string().required("Event view permission is required"),
  auth_comment: Yup.string().required("Event comment permission is required"),
  auth_photo: Yup.string().required("Event photo permission is required"),
});
const initialValues = {
  title: "",
  description: "",
  starttime: "",
  endtime: "",
  host: "",
  location: "",
  category_id: "",
  search: false,
  approval: false,
  auth_invite: "",
  auth_view: "",
  auth_comment: "",
  auth_photo: "",
};

const formatDate = (date) => {
  const unparsed_date = new Date(date); 
  
  const parsed_date =
    ("00" + (unparsed_date.getMonth() + 1)).slice(-2) +
    "/" +
    ("00" + unparsed_date.getDate()).slice(-2) +
    "/" +
    unparsed_date.getFullYear() +
    " " +
    ("00" + unparsed_date.getHours()).slice(-2) +
    ":" +
    ("00" + unparsed_date.getMinutes()).slice(-2) +
    ":" +
    ("00" + unparsed_date.getSeconds()).slice(-2) + " " + new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1];
  return parsed_date
}

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const CreateEventPage = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  return (
    <div className="events-create-container">
      <Formik
        enableReinitialize={true}
        validationSchema={CreateEventSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const form_data = new FormData();
          for (let value in values) {
            form_data.append(value, values[value]);
          }

          //Convert starttime and endtime from Fri Dec 22 2023 14:00:00 GMT-0500 (Eastern Standard Time) to yyyy-mm-dd hh:mm:ss

          form_data.set("starttime", formatDate(new Date(values.starttime)));
          
          form_data.set("endtime", formatDate(new Date(values.endtime))); 

          const res = await postCreateEvent(form_data);
          
          if (res.status_code === 200) {
            toast.success("Event created successfully");
            await sleep(2000);
            navigate(`/events/event/${res.body.response.event_id}`);
          } else {
            toast.error("Event creation failed");
          }

          setSubmitting(false);
        }}
        initialValues={initialValues}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          touched,
          errors,
          setFieldValue
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Group controlId="title">
              <Form.Label>Event Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={values.title}
                onChange={handleChange}
                isValid={touched.title && !errors.title}
                isInvalid={touched.title && errors.title}
              />
              <Form.Control.Feedback type="invalid">
                {errors.title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Event Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={values.description}
                onChange={handleChange}
                isValid={touched.description && !errors.description}
                isInvalid={touched.description && errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Event Start Time</Form.Label>

              <ReactDatePicker
                // defaultValue={values.starttime}
                selected={
                  (values.starttime && new Date(values.starttime)) || null
                }
                onChange={(value) => {
                  setFieldValue("starttime", new Date(value));
                }}
                style={{ color: "black" }}
                showTimeSelect
                dateFormat="Pp"
                customInput={
                  <Form.Control
                    name="starttime"
                    isValid={touched.starttime && !errors.starttime}
                    isInvalid={touched.starttime && errors.starttime}
                    value={values.starttime}
                    onChange={() => {}}
                  />
                }
                minDate={new Date().toISOString().split(".")[0].slice(0, -3)}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />


              <Form.Control.Feedback type="invalid">
                {errors.starttime}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Event End Time</Form.Label>
              <ReactDatePicker
                // defaultValue={values.starttime}
                selected={
                  (values.endtime && new Date(values.endtime)) || null
                }
                onChange={(value) => {
                  setFieldValue("endtime", new Date(value));
                }}
                style={{ color: "black" }}
                showTimeSelect
                dateFormat="Pp"
                customInput={
                  <Form.Control
                    name="endtime"
                    isValid={touched.endtime && !errors.endtime}
                    isInvalid={touched.endtime && errors.endtime}
                    value={values.endtime}
                    onChange={() => {}}
                  />
                }
                minDate={new Date().toISOString().split(".")[0].slice(0, -3)}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              {/* <Form.Control 
                type="datetime-local"
                min={values.starttime ? new Date(values.starttime).toISOString().split(".")[0] : new Date().toISOString().split(".")[0]}
                onChange={handleChange}
                value={values.endtime}
                name="endtime"
                isValid={touched.endtime && !errors.endtime}
                isInvalid={touched.endtime && errors.endtime}
              /> */}
              <Form.Control.Feedback type="invalid">
                {errors.endtime}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Event Host</Form.Label>
              <Form.Control
                type="text"
                name="host"
                value={values.host}
                onChange={handleChange}
                isValid={touched.host && !errors.host}
                isInvalid={touched.host && errors.host}
              />
              <Form.Control.Feedback type="invalid">
                {errors.host}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Event Location</Form.Label>
              <Form.Control
                type="text"
                name="location"
                value={values.location}
                onChange={handleChange}
                isValid={touched.location && !errors.location}
                isInvalid={touched.location && errors.location}
              />
              <Form.Control.Feedback type="invalid">
                {errors.location}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Event Category</Form.Label>
              <Form.Control
                as="select"
                name="category_id"
                value={values.category_id}
                onChange={handleChange}
                isValid={touched.category_id && !errors.category_id}
                isInvalid={touched.category_id && errors.category_id}
              >
                <option>Choose a category</option>
                <option value="1">Arts</option>
                <option value="2">Business</option>
                <option value="3">Conferences</option>
                <option value="4">Festivals</option>
                <option value="5">Food</option>
                <option value="6">Fundraisers</option>
                <option value="7">Galleries</option>
                <option value="8">Health</option>
                <option value="9">Just For Fun</option>
                <option value="10">Kids</option>
                <option value="11">Learning</option>
                <option value="12">Literary</option>
                <option value="13">Movies</option>
                <option value="14">Musuems</option>
                <option value="15">Neighborhood</option>
                <option value="16">Networking</option>
                <option value="17">Nightlife</option>
                <option value="18">On Campus</option>
                <option value="19">Organization</option>
                <option value="20">Outdoors</option>
                <option value="21">Pets</option>
                <option value="22">Politics</option>
                <option value="23">Sales</option>
                <option value="24">Science</option>
                <option value="25">Spirituality</option>
                <option value="26">Sports</option>
                <option value="27">Technology</option>
                <option value="28">Theatre</option>
                <option value="29">Other</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.category_id}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Event Search</Form.Label>
              <Form.Check
                type="switch"
                name="search"
                value={values.search}
                onChange={(e) => {
                  setFieldValue("search", e.target.checked ? 1 : 0);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Event Approval (manually approve members?)
              </Form.Label>
              <Form.Check
                type="switch"
                name="approval"
                value={values.approval}
                onChange={(e) => {
                  setFieldValue("approval", e.target.checked ? 1 : 0);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Event Invite Permission</Form.Label>
              <Form.Control
                as="select"
                name="auth_invite"
                value={values.auth_invite}
                onChange={handleChange}
              >
                <option value="">Select a permission</option>
                <option value="everyone">Everyone</option>
                <option value="registered">All Registered Members</option>
                <option value="members">Event Guests Only</option>
                <option value="owner">Only Me</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.auth_invite}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Event View Permission</Form.Label>
              <Form.Control
                as="select"
                name="auth_view"
                value={values.auth_view}
                onChange={handleChange}
              >
                <option value="">Select a permission</option>
                <option value="everyone">Everyone</option>
                <option value="registered">All Registered Members</option>
                <option value="members">Event Guests Only</option>
                <option value="owner">Only Me</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Event Photo Permission</Form.Label>
              <Form.Control
                as="select"
                name="auth_photo"
                value={values.auth_photo}
                onChange={handleChange}
              >
                <option value="">Select a permission</option>
                <option value="everyone">Everyone</option>
                <option value="registered">All Registered Members</option>
                <option value="members">Event Guests Only</option>
                <option value="owner">Only Me</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Event Comment Permission</Form.Label>
              <Form.Control
                as="select"
                name="auth_comment"
                value={values.auth_comment}
                onChange={handleChange}
              >
                <option value="">Select a permission</option>
                <option value="everyone">Everyone</option>
                <option value="registered">All Registered Members</option>
                <option value="members">Event Guests Only</option>
                <option value="owner">Only Me</option>
              </Form.Control>
            </Form.Group>

            <Form.Group style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between"}}>
              <Button variant="danger" onClick={()=>{
                navigate(-1)
              }}>
                Back
              </Button>
              <Button onClick={handleSubmit}>Submit</Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateEventPage;
