import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useGetEvents } from "../../utils/api/functions/advancedEvents";
import EventsList from "./EventList";
import EventSearch from "./EventSearch";

const CreateEventButton = () => {
  const navigate = useNavigate();

  return (
    <div className="events-content-sidebar-item">
      <Button variant="primary" onClick={() => navigate("/events/create")}>
        Create Event
      </Button>
    </div>
  );
};

const OngoingEvents = () => {
  const [events, setEvents] = useState([]);
  const [searchData, setSearchData] = useState({
    filter: "now"
  })

  const { data, mutate, isLoading } = useGetEvents(searchData);

  const handleSearch = (search_values) => {
    console.log({...searchData, ...search_values});
    setEvents((old) => []);    
    setSearchData({...searchData, ...search_values}); 
  }


  useEffect(() => {
    if (data?.body?.response) {
      setEvents((old) => [...data.body.response]);
    }
  }, [data]);

  return (
    <div className="events-past-container events-content">
      <EventsList events={events} isLoading={isLoading} />
      <div className="events-content-sidebar">
        <EventSearch handleSearch={handleSearch}/>
        <CreateEventButton />
      </div>
    </div>
  );
};
export default OngoingEvents; 