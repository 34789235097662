import React, { useEffect, useState } from "react";
import Layout from "./Default";
import routes from "../settings/routes";
import { NavLink } from "react-router-dom";

const PagesNav = () => {
  const [nav, setNav] = useState([]);

  useEffect(() => {

    const pages_nav_routes = routes.filter((val) => {
      if (val.name === "Pages") {
        return true;
      }
      return false;
    });
    if (!pages_nav_routes) return;
    setNav(() => pages_nav_routes[0].navOptions.subMenu);
  }, []);

  return (
    <div className="pages-nav">
      <div className="pages-nav-header">Pages</div>
      {nav.map((val, index) => {
        return (
          <NavLink
            to={val.link}
            key={index}
            className="pages-nav-item"
            activeClassName="pages-nav-item active"
            end={true}
          >
            {val.name}
          </NavLink>
        );
      })}
    </div>
  );
};

const PagesHomeLayout = ({ children }) => {
  return (
    <Layout>
      <div className="pages-container">
        <PagesNav />
        <div className="pages-content-container">{children}</div>
      </div>
    </Layout>
  );
};

export default PagesHomeLayout;
