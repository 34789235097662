import queryString from "query-string";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;

export const useGetAdvancedActivityFeeds = (values) => {
  let stringified_params = queryString.stringify(values);

  stringified_params = stringified_params ? "?" + stringified_params : "";

  console.log("STRINGIFIED: ", stringified_params);
  console.log("Values: ", values);
  

  return useSWR(
    [
      hostname + "/advancedactivity/feeds",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

//Plain Fetch Pagination for Advanced Activity Feeds
export const paginateAdvancedActivityFeeds = (values) => {
  return fetcher([
    hostname + "/advancedactivity/feeds",
    {
      method: "GET",
      body: queryString.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        oauth_consumer_key: oauthConfig.oauth_consumer_key,
        oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
      },
    },
  ]);
};

//SWR Infinite Pagination for Advanced Activity Feeds
export const usePaginateAdvancedActivityFeeds = (values) => {
  return useSWRInfinite(
    (index, previousPageData) => {
      let maxid;

      if (previousPageData) {
        
        maxid = previousPageData.body.maxid;
      }

      if (maxid) values.maxid = maxid;
      else {
        values.maxid = 0;
      }

      return [
        hostname + "/advancedactivity/feeds?"+queryString.stringify(values),
        {
          method: "GET",
          //body: queryString.stringify(values),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            oauth_consumer_key: oauthConfig.oauth_consumer_key,
            oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
          },
        },
      ];
    },
    fetcher,
    {
      revalidateFirstPage: false,
    },
  );
};

export const createPost = (values, onProgress) => {
  return fetcher(
    [
      hostname + "/advancedactivity/feeds/post",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
    false, (progress) => {
      onProgress(progress)
    }
  );
};

export const postCreateComment = (values) => {
  return fetcher(
    [
      hostname + "/advancedactivity/comment",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false,
    true,
  );
};

export const useSaveUnsaveFeed = (values) => {
  return fetcher(hostname + "/advancedactivity/update-save-feed", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

//Delete a Post or Comment
export const postDeleteFeed = (values) => {
  return fetcher(
    [
      hostname + "/advancedactivity/delete?action_id=" + values.action_id,
      {
        method: "DELETE",
        body: queryString.stringify(values),
        headers: {
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false,
    true,
  );
};

export const useEnableDisableComments = (values) => {
  return fetcher(hostname + "/advancedactivity/update-commentable", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useEnableDisableShareable = (values) => {
  return fetcher(hostname + "/advancedactivity/update-shareable", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

//Like a post or comment
export const likeFeedOrComment = (values) => {
  return fetcher([
    hostname + "/advancedactivity/like",
    {
      method: "POST",
      body: values,
      headers: {
        Accept: "application/json",
      },
    },
  ]);
};

export const likeComment = (values) => {
  return fetcher(
    [
      hostname +
        `/advancedactivity/like?action_id=${values.action_id}&comment_id=${values.comment_id}`,
      {
        method: "POST",
        body: queryString.stringify(values),
        headers: {
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false,
    true,
  );
};

//Unlike a post or comment
export const unlikeFeedOrComment = (values) => {
  return fetcher([
    hostname + "/advancedactivity/unlike",
    {
      method: "POST",
      body: values,
      headers: {
        Accept: "application/json",
      },
    },
  ]);
};

export const useGetLikesComments = (values) => {
  return useSWR(
    [
      hostname +
        "/advancedactivity/feeds/likes-comments?" +
        queryString.stringify(values),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: false,
    },
  );
};

//SWR Infinite Pagination for comments 
export const usePaginateComments = (values) => {
  return useSWRInfinite(
    (index, previousPageData) => {
      values.page = index++ // increment index by 1, pagination does not start with 0 
      return [
        hostname + "/advancedactivity/feeds/likes-comments?" + queryString.stringify(values), 
        {
          method: "GET", 
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            oauth_consumer_key: oauthConfig.oauth_consumer_key,
            oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
          }
        }
      ], 
      fetcher,
      {
        revalidateFirstPage: false
      }
    }
  )
}

export const getLinkAttachmentInfo = (values, abortControl = null) => {
  return fetcher(
    [
      hostname + "/advancedactivity/feeds/attach-link",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
        ...(abortControl && { signal: abortControl }),
      },
    ],
    true,
  );
};

export const postHideFeedItem = (values) => {
  return fetcher([
    hostname + "/advancedactivity/feeds/hide-item", 
    {
      method: "POST", 
      body: queryString.stringify(values), 
      headers: {
        Accept: "application/json", 
        oauth_consumer_key: oauthConfig.oauth_consumer_key,
        oauth_consumer_secret: oauthConfig.oauth_consumer_secret
      }
    }
  ], false, true)
}
