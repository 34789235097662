import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import routes from "../settings/routes";
import DefaultLayout from "./Default";

const ProfileEditNav = () => {
  const [nav, setNav] = useState([]);

  useEffect(() => {
    const profile_edit_nav_routes = routes.filter((val) => {
      if (val.name === "ProfileEdit") {
        return true;
      }
      return false;
    });

    if (!profile_edit_nav_routes) return;

    setNav(() => profile_edit_nav_routes[0].navOptions.subMenu);
  }, []);

  return (
    <div className="profile-edit-nav">
      <div className="profile-edit-nav-header">Edit My Profile</div>
      {nav.map((val, index) => {
        return (
          <NavLink
            to={val.link}
            key={index}
            className="profile-edit-nav-item"
            activeClassName="profile-edit-nav-item active"
            end={true}
          >
            {val.name}
          </NavLink>
        );
      })}
    </div>
  );
};

const ProfileEditLayout = ({ children }) => {
  return (
    <DefaultLayout>
      <div className="profile-edit-container">
        <ProfileEditNav />
        <div className="profile-edit-content">{children}</div>
      </div>
    </DefaultLayout>
  );
};

export default ProfileEditLayout;
