import React from 'react';
import { Button, Modal, Row } from "react-bootstrap";


const ConfirmationModal = ({show, onHide, onConfirm, confirmation_message}) => {

    return(
        <Modal
            centered
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>Confirmation</Modal.Header>
            <Modal.Body>{confirmation_message}</Modal.Body>
            <Modal.Footer as={Row} style={{
                marginLeft: "0.5rem", 
                marginRight: "0.5rem",
                justifyContent: "space-between"
            }}>

                <Button variant="danger" onClick={onHide} style={{width: "30%"}}>Cancel</Button>
                <Button variant="primary" onClick={onConfirm} style={{width: "30%"}}>Confirm</Button>

            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal; 