import React, { useState, useEffect } from "react";
import { Form, ButtonGroup, Button } from "react-bootstrap";

const ProfileSettingsSubscription = () => {
  return (
    <div className="subscriptions-container">
      <div className="subscriptions-current-information">
        <div className="subscriptions-current-information-header">
          Current Membership
        </div>
        <p>
          <strong>Current Plan: </strong>
          Basic Membership
        </p>
        <p>
          <strong>Price: </strong>
          Free
        </p>
      </div>
      <div className="subscription-options">
        <div className="subscription-options-header">Available Plans</div>
        <div className="subscription-options-list">
          <Form.Check
            type="radio"
            name="premium"
            label="Premium Membership ($5.00 / month)"
            checked={true}
          />
        </div>
      </div>
      <div className="subscription-actions">
        <Button>Change Plan</Button>
      </div>
    </div>
  );
};

export default ProfileSettingsSubscription;
