import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import {
  GridList,
  getChunkArrayItemIfExist,
} from "../../../components/lists/VerticalGrid";
import { postAcceptMemberRequest, postIgnoreMemberRequest, useGetEventMembers } from "../../../utils/api/functions/advancedEvents";

const GUTTER_SIZE = 10;

const GuestItem = ({ style, data, columnIndex, rowIndex, waiting }) => {
  let item_data = getChunkArrayItemIfExist(data, rowIndex, columnIndex);
  const {id} = useParams(); 
  const {toast} = useToast(); 
  const {data: all_guests_data, mutate: mutate_all_guests} = useGetEventMembers(
    {
      event_id: id, 
      waiting: 0
    }
  ); 
  const {data: waiting_guests_data, mutate: mutate_waiting_guests} = useGetEventMembers(
    {
      event_id: id, 
      waiting: 1
    }
  ); 

  const user_id = item_data.user_id; 



  const handle_approve_guest = async() => {
    const result = await postAcceptMemberRequest({
      user_id: user_id, 
      event_id: id
    }); 
    if(result.status_code === 204) {
      toast.success("Guest approved!"); 
      mutate_waiting_guests(); 
      mutate_all_guests(); 
    }
    else {
      toast.error("Error approving guest")
    }
  }
  const handle_reject_guest = async() => {
    const result = await postIgnoreMemberRequest({
      user_id: user_id, 
      event_id: id
    })
    if(result.status_code === 204) {
      toast.success("Guest rejected!"); 
      mutate_waiting_guests(); 
      mutate_all_guests(); 
    }
    else {
      toast.error("Error rejecting guest")
    }
  }

  if (item_data) {
    return (
      <div
        style={{
          ...style,
          left: style.left + GUTTER_SIZE,
          top: style.top + GUTTER_SIZE,
          width: style.width - GUTTER_SIZE,
          height: style.height - GUTTER_SIZE,
        }}
        className="event-guests-grid-item"
      >
        <Link to={`/profile/${item_data.user_id}`} reloadDocument>
          <img src={item_data.image} />
        </Link>
        <div className="grid-item-text">
          <Link
            className="grid-item-title"
            to={`/profile/${item_data.user_id}`}
          >
            {item_data.displayname}
          </Link>
          {
            (waiting) ? (
            <div>
              <Button
                variant="success"
                size="sm"
                onClick={handle_approve_guest}
              >Approve</Button>
              <Button
                variant="danger"
                style={{
                  marginLeft: "1rem"
                }}
                size="sm"
                onClick={handle_reject_guest}
              >
                Reject
              </Button>
            </div>
            ) : (<span className="grid-item-sub-text">Attending</span>) 
          }
          
        </div>
      </div>
    );
  }
  return null;
};


const EventGuests = ({ event_id }) => {
  const [showWaiting, setShowWaiting] = useState(0); 
  const { data, mutate } = useGetEventMembers({
    event_id: event_id,
    waiting: showWaiting
  });

  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (data && data.body && data.body.members) {
      setMembers((old) => data.body.members);
    }
    else {
      setMembers(() => []);
    }

  }, [data]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex", 
          justifyContent: "space-evenly"
        }}
        className="event-guests-list-toggles"
      >
        <div
          onClick={()=>{
            setShowWaiting(0); 
          }}
          className={showWaiting === 0 ? "active" : ""}
        >
          All
        </div>
        <div
          onClick={()=>{
            setShowWaiting(1); 
          }}
          className={showWaiting === 1 ? "active" : ""}
        >
          Waiting
        </div>
      </div>
      <GridList items={members} CellEl={({...props}) => (<GuestItem {...props} waiting={showWaiting}/>)} colCount={1} />
    </div>

  );
};

export default EventGuests;
