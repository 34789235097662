import React, { useEffect, useState } from "react";
import {
  getChunkArrayItemIfExist, GridList
} from "../../../components/lists/VerticalGrid";
import { useGetEventPhotos } from "../../../utils/api/functions/advancedEvents";

const EventPhotoItem = ({ style, data, columnIndex, rowIndex }) => {
  let item_data = getChunkArrayItemIfExist(data, rowIndex, columnIndex);
  if (item_data) {
    return (
      <div
        style={{
          ...style,
          left: style.left + 10,
          top: style.top + 10,
          width: style.width - 10,
          height: style.height - 10,
        }}
        className="event-photos-grid-item"
      >
        <img src={item_data.image} />
        <div>By {item_data.user_title}</div>
      </div>
    );
  }
  return null;
};

const EventPhotos = ({ event_id }) => {
  const { data, mutate } = useGetEventPhotos({
    event_id: event_id,
  });
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (data && data.body && data.body.images) {
      setPhotos((old) => data.body.images);
    }
  }, [data]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      {
        photos.length > 0 ? (
        <GridList
          items={photos}
          colCount={3}
          CellEl={EventPhotoItem}
          rowHeight={150}
        />
        ) : (<p style={{textAlign: "center"}}>No photos here yet</p>)
      }
      
    </div>
  );
};

export default EventPhotos;
