import useSWR from "swr";
import { fetcher } from "../handler";
import queryString from "query-string";
import oauthConfig from "../../../oauth.config";
import config from "../../../settings/api.config";

const hostname = config.hostname;
//  @TODO: Add oauth_token & oauth_secret parameters

//Returns friends for a user
export const useGetFriends = (values) => {
  let stringified = queryString.stringify(values);
  return useSWR(
    [
      hostname + `/user/get-friend-list?${stringified}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
        },
      },
    ],
    fetcher,
  );
};

export const useCreateFriendsList = (values) => {
  return fetcher(hostname + "/user/list-create", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
    },
  });
};

export const useAddFriendToList = (values) => {
  return fetcher(hostname + "/user/list-add", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useRemoveFriendFromList = (values) => {
  return fetcher(hostname + "/user/list-remove", {
    method: "DELETE",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_Secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useDeleteFriendsList = (values) => {
  return fetcher(hostname + "/user/list-delete", {
    method: "DELETE",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useSuggestFriends = (values) => {
  return useSWR(
    [
      hostname + "/user/suggest",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const postAddFriend = (values) => {
  return fetcher([
    hostname + "/user/add",
    {
      method: "POST",
      body: values,
      headers: {
        Accept: "application/json",
        oauth_consumer_key: oauthConfig.oauth_consumer_key,
        oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
      },
    },
  ]);
};

export const postCancelFriendRequest = (values) => {
  return fetcher([
    hostname + "/user/cancel",
    {
      method: "POST",
      body: values,
      headers: {
        Accept: "application/json",
        oauth_consumer_key: oauthConfig.oauth_consumer_key,
        oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
      },
    },
  ]);
};

export const postAcceptFriendRequest = (values) => {
  return fetcher([
    hostname + "/user/confirm",
    {
      method: "POST",
      body: values,
      headers: {
        Accept: "application/json",
        oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        oauth_consumer_key: oauthConfig.oauth_consumer_key,
      },
    },
  ]);
};

export const postRejectFriendRequest = (values) => {
  return fetcher([
    hostname + "/user/reject",
    {
      method: "POST",
      body: values,
      headers: {
        Accept: "application/json",
        oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        oauth_consumer_key: oauthConfig.oauth_consumer_key,
      },
    },
  ]);
};

export const postRemoveFriend = (values) => {
  return fetcher([
    hostname + "/user/remove",
    {
      method: "POST",
      body: values,
      headers: {
        Accept: "application/json",
        oauth_consumer_key: oauthConfig.oauth_consumer_key,
        oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
      },
    },
  ]);
};

export const useGetFriendRequests = (values) => {
  return useSWR(
    [
      hostname + "/notifications/friend-request",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};
