import { useToast } from "rc-toastr";
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { postTellAFriend } from "../../../../utils/api/functions/sitepages";
import { useParams } from "react-router";

const PageInviteSchema = Yup.object().shape({
  sender_name: Yup.string().required("Sender name is required"),
  sender_email: Yup.string()
    .email("Invalid email")
    .required("Sender email is required"),
  //receiver_emails min length 1 not empty

  receiver_emails: Yup.array()
    .of(Yup.string().email("Invalid email").nullable())
    .required("At least one receiving email is required"),
  message: Yup.string().required("Message is required"),
});

const getValues = (values, name) => {
  if (values && values[name]) {
    return values[name];
  }
  return "";
};

const EmailInput = ({
  removeable = true,
  index,
  setFieldValue,
  handleRemove,
  handleChange,
  value,
  name,
  touched,
  errors,
  onBlur,
}) => {
  return (
    <Form.Group controlId={name}>
      <div
        className="page-marketing-form-email-input"
        style={{ width: "100%", display: "flex" }}
      >
        <Form.Control
          type="email"
          placeholder="Email Address"
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          style={{ width: "13rem", marginRight: "0.5rem" }}
          name={name}
          isValid={touched && !errors}
          isInvalid={touched && errors}
        />
        {removeable ? (
          <Button
            variant="danger"
            onClick={() => {
              setFieldValue(`receiver_emails.${index}`, null);
              handleRemove();
            }}
          >
            X
          </Button>
        ) : null}
      </div>
      <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
    </Form.Group>
  );
};

const PagePromotionMarketing = () => {
  const { id } = useParams();
  const [emailInputs, setInputs] = useState([
    {
      value: "",
    },
  ]);

  const { toast } = useToast();

  const handleAddEmail = () => {
    setInputs((old) => [...old, { value: "" }]);
  };
  const handleRemoveEmail = (index) => {
    let newInputs = [...emailInputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  return (
    <div>
      Page Promotion Marketing
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        validationSchema={PageInviteSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          
          setSubmitting(true);
          toast.info("Sending invites");
          const form_data = new FormData();

          form_data.append("sender_name", values.sender_name);
          form_data.append("sender_email", values.sender_email);
          form_data.append("message", values.message);

          let email_list = [];
          for (let i = 0; i < values.receiver_emails.length; i++) {
            if (!values.receiver_emails[i]) continue;
            email_list.push(values.receiver_emails[i]);
          }

          form_data.append("receiver_emails", email_list.join(","));
          let response = await postTellAFriend(id, form_data);
          if (response?.status_code === 204) {
            toast.success("Invites sent!");
          } else {
            toast.error("Error sending invites");
          }
          resetForm();
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="sender_name">
              <Form.Label>Sender Name</Form.Label>
              <Form.Control
                name="sender_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={getValues(values, "sender_name")}
                isValid={touched.sender_name && !errors.sender_name}
                isInvalid={touched.sender_name && errors.sender_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.sender_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="sender_email">
              <Form.Label>Sender Email</Form.Label>
              <Form.Control
                name="sender_email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={getValues(values, "sender_email")}
                isValid={touched.sender_email && !errors.sender_email}
                isInvalid={touched.sender_email && errors.sender_email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.sender_email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Receiving Emails</Form.Label>
              {emailInputs.map((input, index) => (
                <EmailInput
                  key={index}
                  index={index}
                  handleRemove={() => {
                    handleRemoveEmail(index);
                  }}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  removeable={emailInputs.length > 1}
                  value={
                    values.receiver_emails && values.receiver_emails[index]
                      ? values.receiver_emails[index]
                      : ""
                  }
                  name={`receiver_emails.${index}`}
                  touched={
                    touched.receiver_emails && touched.receiver_emails[index]
                      ? touched.receiver_emails[index]
                      : false
                  }
                  errors={
                    errors.receiver_emails && errors.receiver_emails[index]
                  }
                  setFieldValue={setFieldValue}
                />
              ))}
            </Form.Group>
            <Button
              size="sm"
              onClick={handleAddEmail}
              style={{
                marginTop: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              +
            </Button>
            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={handleChange}
                onBlur={handleBlur}
                value={getValues(values, "message")}
                isValid={touched.message && !errors.message}
                isInvalid={touched.message && errors.message}
                name="message"
              />
              <Form.Control.Feedback type="invalid">
                {errors.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group style={{ marginTop: "1rem" }}>
              <Button type="submit">Send</Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PagePromotionMarketing;
