import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import feelingsConfig from "./activitiesConfig";

const FeelingsActivitiesMenu = (props) => {
  const [selectedMenu, setMenu] = useState();

  const handleMenuSelect = (id) => {
    setMenu(id);
  };
  const handleReturn = () => {
    setMenu(null);
  };

  return (
    <>
      <div className="feelings-activities-menu-action">
        {selectedMenu ? (
          <FontAwesomeIcon icon={faArrowLeft} onClick={handleReturn} />
        ) : null}
      </div>
      <div
        className="col feelings-activities-menu"
        style={props.show ? { display: "none" } : {}}
      >
        {selectedMenu
          ? feelingsConfig.child[selectedMenu.id].map((item) => {
              return (
                <div
                  className="row"
                  onClick={() => {
                    props.add({
                      parent: selectedMenu,
                      child: item,
                    });
                    props.handleClose();
                  }}
                >
                  <img src={`https://xapit.com${item.url}`} />
                  <h5>{item.title}</h5>
                </div>
              );
            })
          : feelingsConfig.parent.map((item) => {
              return (
                <div
                  className="row"
                  onClick={() => {
                    handleMenuSelect(item);
                  }}
                >
                  <img src={`https://xapit.com${item.url}`}></img>
                  <h5>{item.title}</h5>
                </div>
              );
            })}
      </div>
    </>
  );
};

export default FeelingsActivitiesMenu;
