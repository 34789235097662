import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useBrowseSitepages } from "../../utils/api/functions/sitepages";
import { LoadingListWithImage } from "../loading-placeholders/LoadingSidebarCards";

const PageItem = ({ data }) => {
  return (
    <Link to={`/pages/page/${data.page_id}`}>
      <div className="sidebar-popular-pages-item">
        <div className="sidebar-popular-pages-item-img">
            <img src={data.image_icon} />
        </div>
        <div className="sidebar-popular-pages-item-content">
          <p className="sidebar-popular-pages-item-title">{data.title}</p>
          <p>{data.member_count} members</p>
        </div>
      </div>
    </Link>
  );
};

const PopularPages = ({ limit = 5 }) => {
  const [pages, setPages] = useState([]);
  const { data, isLoading, mutate } = useBrowseSitepages({
    limit: limit,
  });

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setPages((old) => data.body.response.slice(0, 5));
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="home-sidebar-item sidebar-popular-pages">
        <div className="sidebar-popular-pages-header">Trending Pages</div>

        <LoadingListWithImage row_num={limit} />
      </div>
    );
  }

  return (
    <div className="home-sidebar-item sidebar-popular-pages">
      <div className="sidebar-popular-pages-header">Trending Pages</div>
      {pages.map((page, index) => {
        return <PageItem data={page} key={index} />;
      })}
    </div>
  );
};

export default PopularPages;
