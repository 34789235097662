import queryString from "query-string";
import useSWR from "swr";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;
// @TODO: Add posts requests

export const postBlockMember = (user_id) => {
  return fetcher([hostname + `/block/add?user_id=${user_id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  }], false, true);
};

export const postUnblockMember = (user_id) => {
  return fetcher([hostname + `/block/remove?user_id=${user_id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  }], false, true);
};

export const useMakeProfilePhotoFromOther = (values) => {
  return fetcher(hostname + "/members/edit/external-photo", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGetUserInfo = (values) => {
  return useSWR(
    [
      hostname +
        "/user/profile/" +
        values.user_id +
        "?gutter_menu=1&profile_tabs=0",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};
