import React from "react";
import { Button } from "react-bootstrap";
import TailSpin from "react-loading-icons/dist/esm/components/tail-spin";

export const LoadingButton = ({ loading, text, variant, ...props }) => {
  return (
    <Button variant={variant} {...props} disabled={loading}>
      {loading ? (
        <div className="loading-button">
          <TailSpin color="white" />
        </div>
      ) : (
        text
      )}
    </Button>
  );
};
