import { Formik } from "formik";
import { useToast } from "rc-toastr";
import React from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { postChangePassword } from "../../../utils/api/functions/memberSettings";
import { yupPasswordValidation } from "../../../utils/validate";

const ChangePasswordSchema = Yup.object({
  oldPassword: Yup.string().required("Old Password is required"),
  password: yupPasswordValidation,
  passwordConfirm: Yup.string()
    .required("Confirm New Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const initialValues = {
  oldPassword: "",
  password: "",
  passwordConfirm: "",
};

const ProfileSettingsChangePassword = () => {
  const { toast } = useToast();

  return (
    <div className="profile-settings-form-container">
      <Formik
        validationSchema={ChangePasswordSchema}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(() => true);
          const _formData = new FormData();
          _formData.append("oldPassword", values.oldPassword);
          _formData.append("password", values.password);
          _formData.append("passwordConfirm", values.passwordConfirm);

          const result = await postChangePassword(_formData);

          if (result.status_code === 204) {
            toast.success("Password changed successfully");
          } else {
            toast.error(
              result.message ? result.message : "Something went wrong",
            );
          }

          actions.setSubmitting(() => false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Group className="mb-3" controlId="oldPassword">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Old Password"
                autoComplete="new-password"
                value={values?.oldPassword}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="New Password"
                autoComplete="new-password"
                value={values?.password}
                onChange={handleChange}
              />
              <Form.Text className="text-muted">
                Password must be at least 6 characters long.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="passwordConfirm">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm New Password"
                autoComplete="new-password"
                value={values?.passwordConfirm}
                onChange={handleChange}
              />
              <Form.Text className="text-muted">
                Enter your password again for confirmation.
              </Form.Text>
            </Form.Group>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isSubmitting ? true : false}
            >
              Change Password
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileSettingsChangePassword;
