import queryString from "query-string";
import useSWR from "swr";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;

export const useBrowseVideos = (values) => {
  const query_string = queryString.stringify(values);
  return useSWR(
    [
      hostname + "/videos" + (query_string ? "?" + query_string : ""),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetOwnersVideos = (values) => {
  return useSWR(
    [
      hostname + "/videos/manage",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const postCreateVideo = (values) => {
  return fetcher([hostname + "/videos/create", {
    method: "POST",
    body: values,
    headers: {
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  }], true, false);
};

export const useGetVideo = (values) => {
  return useSWR(
    [
      hostname + "/videos/view",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useEditVideo = (values) => {
  return fetcher(hostname + "/videos/edit", {
    method: "PUT",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useRateVideo = (values) => {
  return fetcher(hostname + "/videos/rate", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const deleteVideo = (values) => {
  return fetcher([hostname + "/videos/delete?video_id="+values.video_id, {
    method: "DELETE",
    body: queryString.stringify(values), 
    headers: {
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  }], false, true);
};
