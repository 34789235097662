import { faCircleMinus, faGauge, faPlus, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Bars from "react-loading-icons/dist/esm/components/bars";
import { Link } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import {
  postDeletePage,
  useGetSitePageManaged
} from "../../utils/api/functions/sitepages";

const DeleteConfirmation = ({ title, show, onHide, page_id }) => {
  const { toast } = useToast();
  const { data, mutate } = useGetSitePageManaged({});
  const handleDelete = async () => {
    onHide();
    const response = await postDeletePage(page_id);
    if (response.status_code && response.status_code === 204) {
      toast.success("Page deleted successfully!");
    } else {
      toast.error("Error deleting page, please try again.");
    }
    mutate();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>Delete Page: {title}</Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this page?</p>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={onHide} variant="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} variant="danger">
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const GUTTER_SIZE = 10;
const MyPageItem = ({ data, index, style }) => {
  const [show, setShow] = useState(false); // Show delete confirmation modal

  const item_data = data[index];

  const handleDelete = () => {
    setShow(true);
  };
  const handleHideDelete = () => {
    setShow(false);
  };

  if (item_data) {
    return (
      <div className="my-pages-list-item" style={style}>
        <div className="my-pages-item-img-container">
          <Link to={`/pages/page/${item_data.page_id}`}>
            <img src={item_data.image} />
          </Link>
        </div>
        <div className="my-pages-item-content-container">
          <div className="my-pages-item-content-actions">
            <Link to={`/pages/page/${item_data.page_id}/dashboard`}>
              <FontAwesomeIcon icon={faGauge} />
              Dashboard
            </Link>
            <span onClick={handleDelete}>
              <FontAwesomeIcon icon={faCircleMinus} />
              Delete Page
            </span>
          </div>
          <div className="my-pages-item-content-name">
            <Link to={`/pages/page/${item_data.page_id}`}>
              {item_data.title}
            </Link>
          </div>
          <div className="my-pages-item-content-stats">
            <p>
              {`${item_data.creation_date
                ? new Date(item_data.creation_date).toLocaleDateString("default", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })
                : ""
              }, 
              ${item_data.like_count} likes, 
              ${item_data.view_count} views`}
            </p>
            {/* <p className="item-content-stats-expiration">
              Expires:{" "}
              {item_data.expiration_date ? item_data.expiration_date : "Never"}
            </p> */}
          </div>
          <div className="my-pages-item-content-desc">{item_data.body}</div>
        </div>
        <DeleteConfirmation
          page_id={item_data.page_id}
          title={item_data.title}
          show={show}
          onHide={handleHideDelete}
        />
      </div>
    );
  }
  return null;
};

const MyPages = () => {
  const [pages, setPages] = useState([]);
  const { data, mutate, isLoading } = useGetSitePageManaged({});

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setPages((old) => [...data.body.response]);
    }
  }, [data]);

  return (
    <div className="my-pages-container">
      <div className="my-pages-list-wrapper">
        {isLoading ? (
          <div className="my-pages-list-loading-icon-wrapper">
            <Bars stroke="#00BCD4" fill="#00BCD4" />
          </div>
        ) : null}
        {pages.length === 0 && !isLoading ? (
          <div
            className="my-pages-list-loading-icon-wrapper"
            style={{
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <div>Nothing to see here yet...</div>
          </div>
        ) : null}
        <AutoSizer defaultHeight={500}>
          {({ height, width }) => {
            return (
              <List
                className="my-pages-list"
                height={height}
                itemCount={pages.length}
                itemSize={200}
                itemData={pages}
                width={width}
              >
                {MyPageItem}
              </List>
            );
          }}
        </AutoSizer>
      </div>
      <div className="my-pages-sidebar">
        <div className="my-pages-other-container">
          <Link to="/pages/liked-pages"><FontAwesomeIcon icon={faThumbsUp} /> Pages I've Liked</Link>
        </div>
        <div className="my-pages-create-container">
          <Link to="/pages/create"><FontAwesomeIcon icon={faPlus} /> Create New Page</Link>
        </div>
      </div>
    </div>
  );
};

export default MyPages;
