import React, { useEffect, useState } from "react";
import { Bars } from "react-loading-icons";
import { Link } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { useBrowseSitepages } from "../../utils/api/functions/sitepages";

const GUTTER_SIZE = 10;

const _featured_select = {
  Recent: "creation_date",
  "Most Joined": "member_count",
  "Most Popular": "like_count",
};

const FeaturedPagesNav = ({ handleSetFilter, filter }) => {
  const handleClick = (val) => {
    handleSetFilter(_featured_select[val]);
  };

  return (
    <div className="featured-pages-nav">
      {Object.keys(_featured_select).map((val) => {
        return (
          <div
            className={`featured-pages-nav-item ${
              filter === _featured_select[val] ? "active" : ""
            }`}
            onClick={() => {
              handleClick(val);
            }}
          >
            {val}
          </div>
        );
      })}
    </div>
  );
};

const FeaturedPages = ({}) => {
  const [filter, setFilter] = useState("creation_date");
  const [pages, setPages] = useState([]);
  const { data, isLoading } = useBrowseSitepages({
    orderby: filter,
  });

  useEffect(() => {
    if (data?.body?.response) {
      setPages(data.body.response);
    }
  }, [data]);

  const FeaturedPageItem = ({ index, style }) => {
    const page = pages[index];
    const truncate = (input) =>
      input?.length > 45 ? `${input.substring(0, 35)}...` : input;

    const calculated_height = style.height - GUTTER_SIZE;

    return (
      <div
        className="featured-pages-item"
        style={{
          ...style,
          width: "100%",
          height: calculated_height,
          minHeight: calculated_height,
        }}
        key={`featured-page-item-${index}`}
      >
        <Link to={`/pages/page/${page.page_id}`}>
          <img src={page.image} />
        </Link>
        <div className="featured-pages-item-info">
          <div className="featured-pages-item-info-title">
            <Link to={`/pages/page/${page.page_id}`}>{page.title}</Link>
          </div>
          <div className="featured-pages-item-info-date">
            <span className="featured-pages-item-last-updated">Last updated: </span>  
            {page.modified_date
              ? new Date(page.modified_date).toLocaleDateString("default", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })
            : ""}{" "}
          </div>
          <div className="featured-pages-item-info-description">
            {truncate(page.body)}
          </div>
        </div>
      </div>
    );
  };

  //Filter logic
  const handleSetFilter = async (val) => {
    if (val === filter) return; // Do nothing if filter is the same

    //Clear current page list
    setPages([]);
    // Set new filter
    setFilter(val);
  };

  // if(isLoading){
  //     return(
  //         <div className="featured-pages-loading">
  //             <Bars stroke="#00BCD4" fill="#00BCD4"/>
  //         </div>
  //     )
  // }

  return (
    <>
      <FeaturedPagesNav handleSetFilter={handleSetFilter} filter={filter} />
      {isLoading ? (
        <div className="featured-pages-loading">
          <Bars stroke="#00BCD4" fill="#00BCD4" />
        </div>
      ) : (
        <div>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <List
                  className="featured-pages-list virtual-list-no-scrollbars"
                  height={height}
                  width={width}
                  itemCount={pages.length}
                  itemSize={height / 6}
                >
                  {FeaturedPageItem}
                </List>
              );
            }}
          </AutoSizer>
        </div>
      )}
    </>
  );
};

export default FeaturedPages;
