import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  GridList,
  getChunkArrayItemIfExist,
} from "../../components/lists/VerticalGrid";
import { useGetAds } from "../../utils/api/functions/communityAds";

const GUTTER_SIZE = 10;

const resource_type_to_url = {
  event: "/events/event",
  group: "/groups/group",
  sitepage: "/pages/page",
  profile: "/profile",
};

const AdLink = ({ id, type, url, title }) => {
  if (type) {
    return (
      <Link to={`${resource_type_to_url[type]}/${id}`} target="_blank">
        {title}
      </Link>
    );
  }

  return (
    <a href={url} target="_blank">
      {title}
    </a>
  );
};

const AdItem = ({ style, data, columnIndex, rowIndex }) => {
  let item_data = getChunkArrayItemIfExist(data, rowIndex, columnIndex);

  if (!item_data) return null;

  return (
    <div
      style={{
        ...style,
        left: style.left + GUTTER_SIZE,
        top: style.top + GUTTER_SIZE,
        width: style.width - GUTTER_SIZE,
        height: style.height - GUTTER_SIZE,
      }}
      className="ad-board-item"
    >
      <div className="ad-board-item-title">
        <AdLink
          id={item_data.resource_id}
          type={item_data.resource_type}
          url={item_data.url}
          title={item_data.cads_title}
        />
      </div>
      <img src={item_data.image} />
      <div className="ad-board-item-text">{item_data.cads_body}</div>
    </div>
  );
};

const AdBoard = () => {
  const { data } = useGetAds({});
  const [adData, setAdData] = useState([]);

  useEffect(() => {
    if (data && data.body && data.body.advertisements) {
      setAdData(data.body.advertisements);
    }
  }, [data]);

  return (
    <div className="advertise-content-box">
      <div className="advertise-content-box-header">Ad Board</div>
      <div className="box-content">
        <GridList items={adData} CellEl={AdItem} colCount={4} rowHeight={200} />
      </div>
    </div>
  );
};

export default AdBoard;
