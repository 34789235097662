//@TODO: Messages rework?
import queryString from "query-string";
import useSWR from "swr";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;

export const useGetMessages = (values) => {
  return useSWR(
    [
      hostname + "/messages/allmessages",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetConversation = (values) => {
  return useSWR(
    [
      hostname + "/messages/view/",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const postSendMessage = (conv_id, message) => {
  return fetcher(
    [
      hostname + `/messages/view/${conv_id}`,
      {
        method: "POST",
        body: message,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
  );
};

export const postDeleteMessage = (values) => {
  return fetcher(
    [
      hostname + `/messages/delete`,
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
  );
};

export const postNewMessage = (values) => {
  return fetcher(
    [
      hostname + `/messages/compose`,
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true
  )
}