import React from "react";
import { Form } from "react-bootstrap";

const PageFeaturedAdmins = () => {
  return (
    <div className="page-edit-featured-admins-container">
      <div>
        Featured Admins
        <p>
          Edit the featured admins on your page. Featured admins are shown on
          the page.
        </p>
      </div>

      <Form></Form>
    </div>
  );
};

export default PageFeaturedAdmins;
