import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";

import { useToast } from "rc-toastr";
import {
  postProfileEdit,
  useGetProfileEdit,
} from "../../../utils/api/functions/memberSettings";

const ProfileEditSchema = Yup.object().shape({
  //Personal Info
  "1_1_3_alias_first_name": Yup.string().required("First name is required"),
  "1_1_4_alias_last_name": Yup.string().required("Last name is required"),
  "1_1_5_alias_gender": Yup.string(),
  "1_1_18_alias_relationship_status": Yup.string(),

  //Contact information
  "1_1_8_alias_": Yup.string(), //Website
  "1_1_9_alias_": Yup.string(), //Twitter
  "1_1_10_alias_": Yup.string(), //Facebook

  //Personal Details
  "1_1_12_alias_": Yup.string(), //About me
  "1_1_17_alias_interests": Yup.string(), //Interests
  "1_1_16_alias_education_level": Yup.string(), //Education
  "1_1_15_alias_occupation": Yup.string(), //Occupation
  "1_1_14_alias_religion": Yup.string(), //Faith / Religion
  "1_1_13_alias_political_views": Yup.string(), //Political Views
  "1_1_20_alias_city": Yup.string(), // City
  "1_1_22_alias_": Yup.string(), // State / Province / Territory
  "1_1_24_alias_country": Yup.string(), // Country
});

const SectionHeader = ({ title }) => {
  return <div className="profile-edit-form-section-header">{title}</div>;
};

const PersonalDetails = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
}) => {
  return (
    <div className="profile-edit-form-personal-details">
      <SectionHeader title="Personal Details" />
      <Form.Group>
        <Form.Label>About Me</Form.Label>
        <Form.Control
          as="textarea"
          name="1_1_12_alias_"
          value={values["1_1_12_alias_"]}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={touched["1_1_12_alias_"] && !errors["1_1_12_alias_"]}
          isInvalid={touched["1_1_12_alias_"] && errors["1_1_12_alias_"]}
        />
        <Form.Control.Feedback type="invalid">
          {errors["1_1_12_alias_"]}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Interests</Form.Label>
        <Form.Control
          as="textarea"
          name="1_1_17_alias_interests"
          value={values["1_1_17_alias_interests"]}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={
            touched["1_1_17_alias_interests"] &&
            !errors["1_1_17_alias_interests"]
          }
          isInvalid={
            touched["1_1_17_alias_interests"] &&
            errors["1_1_17_alias_interests"]
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors["1_1_17_alias_interests"]}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Education</Form.Label>
        <Form.Select
          name="1_1_16_alias_education_level"
          value={values["1_1_16_alias_education_level"]}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={
            touched["1_1_16_alias_education_level"] &&
            !errors["1_1_16_alias_education_level"]
          }
          isInvalid={
            touched["1_1_16_alias_education_level"] &&
            errors["1_1_16_alias_education_level"]
          }
        >
          <option></option>
          <option>High School</option>
          <option>Some College</option>
          <option>Associates Degree</option>
          <option>Bachelors Degree</option>
          <option>Graduate Degree</option>
          <option>PhD / Post Doctoral</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors["1_1_16_alias_education_level"]}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Occupation</Form.Label>
        <Form.Select
          name="1_1_15_alias_occupation"
          value={values["1_1_15_alias_occupation"]}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={
            touched["1_1_15_alias_occupation"] &&
            !errors["1_1_15_alias_occupation"]
          }
          isInvalid={
            touched["1_1_15_alias_occupation"] &&
            errors["1_1_15_alias_occupation"]
          }
        >
          <option></option>
          <option>Administrative / Secretarial</option>
          <option>Architecture / Interior design</option>
          <option>Artistic / Creative / Performance</option>
          <option>Education / Teacher / Professor</option>
          <option>Executive / Management</option>
          <option>Fashion / Model / Beauty</option>
          <option>Financial / Accounting / Real Estate</option>
          <option>Labor / Construction</option>
          <option>Law enforcement / Security / Military</option>
          <option>Legal</option>
          <option>Medical / Dental / Veterinary / Fitness</option>
          <option>Nonprofit / Volunteer / Activist</option>
          <option>Political / Govt / Civil Service / Military</option>
          <option>Retail / Food services</option>
          <option>Retired</option>
          <option>Sales / Marketing</option>
          <option>Self-Employed / Entrepreneur</option>
          <option>Student</option>
          <option>Technical / Science / Computers / Engineering</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors["1_1_15_alias_occupation"]}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Religious Beliefs</Form.Label>
        <Form.Select
          name="1_1_14_alias_religion"
          value={values["1_1_14_alias_religion"]}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={
            touched["1_1_14_alias_religion"] && !errors["1_1_14_alias_religion"]
          }
          isInvalid={
            touched["1_1_14_alias_religion"] && errors["1_1_14_alias_religion"]
          }
        >
          <option></option>
          <option>Agnostic</option>
          <option>Atheist</option>
          <option>Buddhist</option>
          <option>Catholic</option>
          <option>Christian</option>
          <option>Hindu</option>
          <option>Jewish</option>
          <option>LDS</option>
          <option>Muslim</option>
          <option>Spiritual</option>
          <option>Taoist</option>
          <option>Other</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors["1_1_14_alias_religion"]}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Political Views</Form.Label>
        <Form.Select
          name="1_1_13_alias_political_views"
          value={values["1_1_13_alias_political_views"]}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={
            touched["1_1_13_alias_political_views"] &&
            !errors["1_1_13_alias_political_views"]
          }
          isInvalid={
            touched["1_1_13_alias_political_views"] &&
            errors["1_1_13_alias_political_views"]
          }
        >
          <option></option>
          <option>Middle of the Road</option>
          <option>Very Conservative</option>
          <option>Conservative</option>
          <option>Liberal</option>
          <option>Very Liberal</option>
          <option>Non-conformist</option>
          <option>Libertarian</option>
          <option>Green</option>
          <option>Other</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors["1_1_13_alias_political_views"]}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>City</Form.Label>
        <Form.Control
          type="text"
          placeholder="City"
          name="1_1_20_alias_city"
          value={values["1_1_20_alias_city"]}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={touched["1_1_20_alias_city"] && !errors["1_1_20_alias_city"]}
          isInvalid={
            touched["1_1_20_alias_city"] && errors["1_1_20_alias_city"]
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors["1_1_20_alias_city"]}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>State (or Province/Territory)</Form.Label>
        <Form.Control
          type="text"
          placeholder="State / Province / Territory"
          name="1_1_22_alias_"
          value={values["1_1_22_alias_"]}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={touched["1_1_22_alias_"] && !errors["1_1_22_alias_"]}
          isInvalid={touched["1_1_22_alias_"] && errors["1_1_22_alias_"]}
        />
        <Form.Control.Feedback type="invalid">
          {errors["1_1_22_alias_"]}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Country</Form.Label>
        <Form.Control
          type="text"
          placeholder="Country"
          name="1_1_24_alias_country"
          value={values["1_1_24_alias_country"]}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={
            touched["1_1_24_alias_country"] && !errors["1_1_24_alias_country"]
          }
          isInvalid={
            touched["1_1_24_alias_country"] && errors["1_1_24_alias_country"]
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors["1_1_24_alias_country"]}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};

const EditProfileInfo = () => {
  const { data } = useGetProfileEdit({});
  const [initial, setInitial] = useState({});
  const { toast } = useToast();

  useEffect(() => {
    
    if (data?.body?.formValues) {
      let parsed_form_values = {};
      Object.keys(data.body.formValues).map((key) => {
        parsed_form_values[key] = data.body.formValues[key].value;
      });
      setInitial(() => parsed_form_values);
    }
  }, [data]);

  return (
    <div className="profile-edit-form-container">
      <Formik
        enableReinitialize={true}
        validationSchema={ProfileEditSchema}
        initialValues={initial}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const form_data = new FormData();

          for (let value in values) {
            form_data.append(value, values[value]);
          }

          const res = await postProfileEdit(form_data);
          
          if (res.status_code === 204) {
            toast.success("Profile updated successfully");
          } else {
            toast.error("Error updating profile");
          }
          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <SectionHeader title="Personal Information" />
            <Form.Group className="mb-3" controlId="first_name">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                name="1_1_3_alias_first_name"
                value={values["1_1_3_alias_first_name"]}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={
                  touched["1_1_3_alias_first_name"] &&
                  !errors["1_1_3_alias_first_name"]
                }
                isInvalid={
                  touched["1_1_3_alias_first_name"] &&
                  errors["1_1_3_alias_first_name"]
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors["1_1_3_alias_first_name"]}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="1_1_4_alias_last_name"
                value={values["1_1_4_alias_last_name"]}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={
                  touched["1_1_4_alias_last_name"] &&
                  !errors["1_1_4_alias_last_name"]
                }
                isInvalid={
                  touched["1_1_4_alias_last_name"] &&
                  errors["1_1_4_alias_last_name"]
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors["1_1_4_alias_last_name"]}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Gender</Form.Label>
              <Form.Select
                name="1_1_5_alias_gender"
                value={values["1_1_5_alias_gender"]}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={
                  touched["1_1_5_alias_gender"] && !errors["1_1_5_alias_gender"]
                }
                isInvalid={
                  touched["1_1_5_alias_gender"] && errors["1_1_5_alias_gender"]
                }
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors["1_1_5_alias_gender"]}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Birthdate</Form.Label>
              <Form.Control
                type="date"
                name="birthdate"
                max={new Date().toISOString().slice(0, 10)}
                values={values["birthdate"]}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Relationship</Form.Label>
              <Form.Select
                name="1_1_18_alias_relationship_status"
                value={values["1_1_18_alias_relationship_status"]}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={
                  touched["1_1_18_alias_relationship_status"] &&
                  !errors["1_1_18_alias_relationship_status"]
                }
                isInvalid={
                  touched["1_1_18_alias_relationship_status"] &&
                  errors["1_1_18_alias_relationship_status"]
                }
              >
                <option value="single">Single</option>
                <option value="relationship">In a Relationship</option>
                <option value="engaged">Engaged</option>
                <option value="married">Married</option>
                <option value="complicated">It's Complicated</option>
                <option value="widowed">Widowed</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors["1_1_18_alias_relationship_status"]}
              </Form.Control.Feedback>
            </Form.Group>
            <div id="profile-edit-form-contact-info">
              <SectionHeader title="Contact Information" />
              <Form.Group>
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Website"
                  name="1_1_8_alias_"
                  value={values["1_1_8_alias_"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched["1_1_8_alias_"] && !errors["1_1_8_alias_"]}
                  isInvalid={touched["1_1_8_alias_"] && errors["1_1_8_alias_"]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["1_1_8_alias_"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Facebook</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Facebook URL"
                  name="1_1_10_alias_"
                  value={values["1_1_10_alias_"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched["1_1_10_alias_"] && !errors["1_1_10_alias_"]}
                  isInvalid={
                    touched["1_1_10_alias_"] && errors["1_1_10_alias_"]
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors["1_1_10_alias_"]}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <PersonalDetails
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />
            <Form.Group style={{ marginTop: "1rem" }}>
              <Button
                variant="primary"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditProfileInfo;
