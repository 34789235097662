import React, { useState, useEffect } from "react";
import Layout from "./Default";
import routes from "../settings/routes";
import { NavLink } from "react-router-dom";

const EventsNav = () => {
  const [nav, setNav] = useState([]);

  useEffect(() => {
    const events_nav_routes = routes.filter((val, i) => {
      if (val.name === "Events") {
        return true;
      }
      return false;
    });
    if (!events_nav_routes) return;
    setNav((old) => events_nav_routes[0].navOptions.subMenu);
  }, []);

  return (
    <div className="events-nav">
      <div className="events-nav-header">Events</div>
      {nav.map((val, index) => {
        return (
          <NavLink
            to={val.link}
            key={index}
            className="events-nav-item"
            activeClassName="events-nav-item active"
            end={true}
          >
            {val.name}
          </NavLink>
        );
      })}
    </div>
  );
};

const EventsLayout = ({ children }) => {
  return (
    <Layout>
      <div className="events-container">
        <EventsNav />
        <div className="events-content-container">{children}</div>
      </div>
    </Layout>
  );
};

export default EventsLayout;
