import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from 'rc-toastr';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { postCoverPhoto, postGroupCoverPhoto, postPageCoverPhoto } from '../../utils/api/functions/misc';


const UploadCoverPhoto = ({subject_id, subject_type, type="profile"}) => {
    const [show, setShow] = useState(false);
    const [preview, setPreview] = useState(null);
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const {toast} = useToast();

    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        if(!selectedPhoto){
            setPreview(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(selectedPhoto);
        setPreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedPhoto])

    const handleSelectPhoto = (e) => {
        if(!e.target.files || e.target.files.length === 0){
            setSelectedPhoto(undefined);
            return;
        }
        setSelectedPhoto(e.target.files[0]);
    }
    const handlePhotoUpload = async() => {
        const formData = new FormData();
        formData.append("photo", selectedPhoto);
        formData.append("subject_id", subject_id);
        if(subject_type) formData.append("subject_type", subject_type);

        let res;
        switch(type){
            case "profile":
                res = await postCoverPhoto(formData, subject_id, subject_type);
                break;
            case "page":
                res = await postPageCoverPhoto(formData, subject_id, subject_type);
                break;
            case "group":
                formData.append("Filedata", selectedPhoto);
                formData.delete("photo");
                res = await postGroupCoverPhoto(formData, subject_id);
                 break; 
        }

        if(res.status_code === 204){
            toast.success("Cover photo uploaded successfully");
            window.location.reload();
        }
        else {
            toast.error("Error uploading cover photo");
        }
    }

    return(
        <>
        <Button
            className="upload-cover-photo-btn"
            onClick={handleShow}
        >
            <FontAwesomeIcon icon={faCamera} />
        </Button>
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Upload Cover Photo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control type="file" onChange={handleSelectPhoto}/>
                </Form>
                {preview ? (
                    <>
                    <img src={preview} alt="preview" className="upload-cover-photo-preview"/>
                    <Button style={{marginTop: "0.5rem"}} onClick={handlePhotoUpload}>Upload</Button>
                    </>
                ) : null}
            </Modal.Body>
        </Modal>
        </>
    )
}

export default UploadCoverPhoto; 