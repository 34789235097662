import { useToast } from 'rc-toastr';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { putEditNotificationSettings, useGetNotificationSettings } from '../../../utils/api/functions/memberSettings';

const NotificationField = ({category, fields, values, handleChange}) => {
    return(
        <Form.Group>
            <Form.Label>{category}</Form.Label>
            {
                fields.map((val, i) => {
                    return(
                        <Form.Check 
                            label={val.label}
                            name={val.name}
                            value={values.includes(val.name)}
                            checked={values.includes(val.name)}
                            onChange={() => {handleChange(val.name)}}
                        />
                    )
                })
            }
        </Form.Group>
    )
}

const AdvancedNotifications = () => {
    const [formFields, setFormFields] = useState([]); 
    const [formValues, setFormValues] = useState([]); 
    const {data, mutate} = useGetNotificationSettings(); 
    const {toast} = useToast(); 

    useEffect(() => {
        if(data && data.status_code === 200) {
            console.log("Notification data: ", data)
            setFormFields(() => data.body.form)
            setFormValues(() => data.body.formValues)
        }
    }, [data])

    const handleSubmit = async() => {
        console.log("Form values: ", formValues);
        let request_values = {}; 
        formValues.map((value, i ) => {
            request_values[value] = 1; 
        })
        const res = await putEditNotificationSettings(request_values); 
        if(res.status_code === 204){
            toast.success("Settings saved!"); 
        }
        else {
            toast.error("Error saving"); 
        }
        mutate(); 
    }

    const handleChange = (_name) => {
        let old_arr = [...formValues]; 
        
        if(old_arr.includes(_name)){
            //Remove value from array if already exists
            old_arr = old_arr.filter((item) => {
                return item !== _name;
            })
        }
        else {
            //Add value if doesn't already exist 
            old_arr.push(_name); 
        }
        setFormValues(() => old_arr); 
    }

    return(
        <Form>
            {
                formFields.map((val, i) => {
                    return(
                        <NotificationField category={val.category} fields={val.types} values={formValues} handleChange={handleChange}/>
                    )
                }) 
            }
            <Form.Group>
                <Button onClick={handleSubmit}>
                    Save
                </Button>
            </Form.Group>
        </Form>
    )
}


export default AdvancedNotifications; 