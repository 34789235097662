import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faBars } from "@fortawesome/free-solid-svg-icons";
import ProfilePreview from "../sidebar/ProfilePreview";
import { useGetUserInfo } from "../../utils/api/functions/members";

export const portalLinks = [
  { link: "/portal/home-schooling", title: "Home Schooling" },
  { link: "/portal/country-living", title: "Country Living" },
  { link: "/portal/travel", title: "travel" },
];

export const pagesLinks = [
  { link: "/pages/home", title: "Pages Home" },
  { link: "/pages/browse", title: "Browse Pages" },
  { link: "/pages/my-pages", title: "My Pages" },
  { link: "/pages/pinboard", title: "Pinboard" },
  { link: "/pages/claim", title: "Claim a Page" },
  { link: "/pages/home-schooling", title: "Home Schooling" },
];

export const groupsLinks = [];

const SideNav = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const [info, setInfo] = useState({});

  const user_id = localStorage.getItem("user_id");
  const { data, mutate, isLoading } = useGetUserInfo({
    user_id: user_id
  });

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setInfo((old) => data.body.response);
    }
  }, [data]);

  const navExpandedRef = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (
        isNavExpanded &&
        navExpandedRef.current &&
        !navExpandedRef.current.contains(event.target)
      ) {
        setIsNavExpanded(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [isNavExpanded]);

  return (
    <nav ref={navExpandedRef} className="SideNav">
      <button
        className="toggle-side-nav"
        onClick={() => setIsNavExpanded((prev) => !prev)}
      >
        {isNavExpanded ? (
          <FontAwesomeIcon icon={faXmark} />
        ) : (
          <FontAwesomeIcon icon={faBars} />
        )}
      </button>
      <ul className={`side-menu-nav${isNavExpanded ? " show-side-menu" : ""}`}>
        <div className="responsive-profile-preview">
          <ProfilePreview></ProfilePreview>
        </div>
        <li>
          <Link to={`/home`}>My News Feed</Link>
        </li>
        <li>
          <Link to={`/profile/${info.user_id}`}>My Profile</Link>
        </li>
        <li>
          <Link to={`/pages`}>Pages</Link>
        </li>
        <li>
          <Link to={`/groups`}>Groups</Link>
        </li>
        {/* <li>
                    <Link to={``}>FAQs</Link>
                </li> */}
        <li>
          <Link to={`/invite`}>Invite</Link>
        </li>
        <li>
          <Link to={`/advertise`}>Advertise</Link>
        </li>
        <li>
          <Link to={`/events`}>Events</Link>
        </li>
      </ul>
    </nav>
  );
};

export default SideNav;
