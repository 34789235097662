import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import {
  useGetEditSitePageForm,
  postEditSitePage,
} from "../../../../utils/api/functions/sitepages";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useToast } from "rc-toastr";

const PageContactInfoSchema = Yup.object().shape({
  phone: Yup.string(),
  email: Yup.string().email("Invalid email (e.g. test@example.com)"),
  website: Yup.string().url("Invalid URL (e.g. https://www.example.com)"),
});

const PageAdminContact = () => {
  const { id } = useParams();
  const { toast } = useToast();
  const { data } = useGetEditSitePageForm(id, {});
  const [initial, setInitial] = useState();

  useEffect(() => {
    if (data && data.body) {
      setInitial(() => data.body.formValues);
    }
  }, [data]);

  const getValues = (values, name) => {
    if (values && values[name]) {
      return values[name];
    }
    return "";
  };

  return (
    <div className="page-edit-admin-contact-container">
      <div>
        Contact Details
        <p>
          Contact information will be displayed in the info section of your
          page.
        </p>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={initial}
        validationSchema={PageContactInfoSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          toast.info("Saving contact details...");
          const form_data = new FormData();
          
          for (let value in values) {
            if (values[value] === null) continue;
            form_data.append(value, values[value]);
          }

          await postEditSitePage(id, form_data)
            .then((res) => {
              if (res.status_code === 204) {
                toast.success("Contact details saved successfully!");
              } else {
                toast.error("Error saving contact details");
              }
            })
            .catch(() => {
              toast.error("Error saving contact details");
            });
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Group className="mb-1">
              <Form.Label column sm="1" style={{ textAlign: "left" }}>
                Phone:
              </Form.Label>

              <Form.Control
                type="text"
                name="phone"
                value={getValues(values, "phone")}
                onChange={handleChange}
                placeholder="Phone Number"
              />
              {errors.phone && touched.phone ? (
                <div className="error-message">{errors.phone}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label column sm="1">
                Email:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Email"
                name="email"
                value={getValues(values, "email")}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <div className="error-message">{errors.email}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label column sm="1">
                Website:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Website"
                name="website"
                value={getValues(values, "website")}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.website && touched.website ? (
                <div className="error-message">{errors.website}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-1">
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Save Details
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PageAdminContact;
