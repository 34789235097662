import { useEffect, useState } from 'react';

export const cleankeyMiddleware = (useSWRNext) => (key, fetcher, config) => {

    const getCleanedKey = (key) => {
        if (Array.isArray(key)){
            return key[0];
        }
        if( typeof key === 'function'){
            return (...args) => {
                const resolvedKey = key(...args);
                return Array.isArray(resolvedKey) ? resolvedKey[0] : resolvedKey;
            }
        }
        return key; 
    }

    //Clean key 
    const cleanedKey = getCleanedKey(key); 

    // if(typeof key === 'function'){
    //     const fetcherWithParams = (key, ...params) => fetcher(key, ...params);
    //     const swr = useSWRNext(cleanedKey, fetcherWithParams, config); 
    //     return swr; 
    // }
    // else {
    //     const fetcherWithParams = (...params) => fetcher(key, ...params); 
    //     const swr = useSWRNext(cleanedKey, fetcherWithParams, config); 
    //     return swr; 
    // }
    
    const fetcherWithParams = (...params) => {
        let resolvedKey = typeof key === 'function' ? key(...params) : key; 
        if(typeof key === 'function'){
            resolvedKey[0] === params[0];
        }
        
        return fetcher(resolvedKey, ...params);
    }


    //Bypass cleaning of non-regular swr function keys for now
    // TODO: Fix key cleaning of SWRInifinite functions 
    if(typeof key === 'function'){
        return useSWRNext(key, fetcher, config); 
    }


    const swr = useSWRNext(cleanedKey, fetcherWithParams, config); 
    return swr;
}

const priorityState = {
    highPriorityLoaded: false,
    listeners: [],
  };
  
const notifyListeners = () => {
    priorityState.listeners.forEach((listener) => listener(priorityState.highPriorityLoaded));
};

export const priorityMiddleware = (useSWRNext) => (key, fetcher, config) => {
    const isHighPriority = key === '/api/high-priority-route';
  
    // State to track if high priority data is loaded
    const [isLoaded, setIsLoaded] = useState(priorityState.highPriorityLoaded);
  
    useEffect(() => {
      if (isHighPriority && !isLoaded) {
        priorityState.listeners.push(setIsLoaded);
      }
  
      return () => {
        if (isHighPriority) {
          priorityState.listeners = priorityState.listeners.filter((listener) => listener !== setIsLoaded);
        }
      };
    }, [isHighPriority, isLoaded]);
  
    const result = useSWRNext(key, fetcher, {
      ...config,
      revalidateOnMount: isHighPriority,
      revalidateIfStale: isHighPriority,
      revalidateOnFocus: isHighPriority,
    });
  
    useEffect(() => {
      if (isHighPriority && result.data) {
        priorityState.highPriorityLoaded = true;
        notifyListeners();
      }
    }, [isHighPriority, result.data]);
  
    if (!isHighPriority && !isLoaded) {
      return {
        ...result,
        data: undefined, // Force other routes to wait
        error: undefined,
        isValidating: true,
      };
    }
  
    return result;
  };