import { Formik } from "formik";
import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import { postEditEvent, useGetEventEdit } from "../../../utils/api/functions/advancedEvents";

const PermissionSelectValues = () => {
  return (
    <>
      <option value="everyone">Everyone</option>
      <option value="owner_network">Friends and Networks</option>
      <option value="owner_member_member">Friends of Friends</option>
      <option value="owner_member">Friends Only</option>
      <option value="member">Event Guests Only</option>
      <option value="owner">Just Me</option>
    </>
  );
};


const formatDate = (date) => {
  const unparsed_date = new Date(date); 
  
  const parsed_date =
    ("00" + (unparsed_date.getMonth() + 1)).slice(-2) +
    "/" +
    ("00" + unparsed_date.getDate()).slice(-2) +
    "/" +
    unparsed_date.getFullYear() +
    " " +
    ("00" + unparsed_date.getHours()).slice(-2) +
    ":" +
    ("00" + unparsed_date.getMinutes()).slice(-2) +
    ":" +
    ("00" + unparsed_date.getSeconds()).slice(-2) + " " + new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1];
  return parsed_date
}

const EventEdit = () => {
  const { id } = useParams();
  const { data, mutate } = useGetEventEdit(id, {});
  const [eventData, setEventData] = useState({});
  const {toast} = useToast(); 
  const navigate = useNavigate(); 

  useEffect(() => {
    if (data && data.body && data.body.formValues) {
      console.log("ORGINAL DATA: ", data.body.formValues)
      setEventData({
        ...data.body.formValues,
        starttime: new Date(data.body.formValues.starttime + " UTC"),
        endtime: new Date(data.body.formValues.endtime + " UTC"),  
        
      });
      console.log("FORM VALUES: ", data.body.formValues)
    }
  }, [data]);
  useEffect(() => {console.log("EVENT DATA: ", eventData)}, [eventData])
  return (
    <div className="event-edit-container">
      <div className="event-edit-form-container">
        <div className="event-edit-form-header">Edit Event</div>
        <Formik initialValues={eventData} enableReinitialize={true}
          onSubmit={async(values) => {
            const formData = new FormData(); 

            for (let key in values) {
              if (values[key]) {
                formData.append(key, values[key]);
              }
            }
            formData.set("starttime", formatDate(new Date(values.starttime)));
            formData.set("endtime", formatDate(new Date(values.endtime))); 
            const result = await postEditEvent(formData); 
            if(result && result.status_code === 204){
              toast.success("Event updated successfully"); 
              navigate(`/events/my-events`); 
            }
            else {
              toast.error("Error updating event"); 
            }

          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="title" className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                />
              </Form.Group>

              <Form.Group controlId="description" className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="textarea"
                  onChange={handleChange}
                  value={values.description}
                />
              </Form.Group>
              <Row>
                <Form.Group controlId="starttime" className="mb-3" as={Col}>
                  <Form.Label>Start Time</Form.Label>
                  <ReactDatePicker
                    // defaultValue={values.starttime}
                    selected={
                      (values.starttime && new Date(values.starttime)) || null
                    }
                    onChange={(value) => {
                      setFieldValue("starttime", new Date(value));
                    }}
                    style={{ color: "black" }}
                    showTimeSelect
                    dateFormat="Pp"
                    customInput={
                      <Form.Control
                        name="starttime"
                        // isValid={touched.starttime && !errors.starttime}
                        // isInvalid={touched.starttime && errors.starttime}
                        value={values.starttime}
                        onChange={() => {}}
                      />
                    }
                    minDate={new Date().toISOString().split(".")[0].slice(0, -3)}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="endtime" className="mb-3" as={Col}>
                  <Form.Label>End Time</Form.Label>
                  <ReactDatePicker
                    // defaultValue={values.starttime}
                    selected={
                      (values.endtime && new Date(values.endtime)) || null
                    }
                    onChange={(value) => {
                      setFieldValue("endtime", new Date(value));
                    }}
                    style={{ color: "black" }}
                    showTimeSelect
                    dateFormat="Pp"
                    customInput={
                      <Form.Control
                        name="endtime"
                        // isValid={touched.endtime && !errors.endtime}
                        // isInvalid={touched.endtime && errors.endtime}
                        value={values.endtime}
                        onChange={() => {}}
                      />
                    }
                    minDate={new Date().toISOString().split(".")[0].slice(0, -3)}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group controlId="location" className="mb-3" as={Col}>
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    value={values.location}
                  />
                </Form.Group>
                <Form.Group controlId="photo" className="mb-3" as={Col}>
                  <Form.Label>Photo</Form.Label>
                  <Form.Control type="file" onChange={handleChange} />
                </Form.Group>
              </Row>

              <Form.Group controlId="category_id" className="mb-3">
                <Form.Label>Event Category</Form.Label>
                <Form.Select onChange={handleChange} value={values.category_id}>
                  <option>Choose a category</option>
                  <option value="1">Arts</option>
                  <option value="2">Business</option>
                  <option value="3">Conferences</option>
                  <option value="4">Festivals</option>
                  <option value="5">Food</option>
                  <option value="6">Fundraisers</option>
                  <option value="7">Galleries</option>
                  <option value="8">Health</option>
                  <option value="9">Just For Fun</option>
                  <option value="10">Kids</option>
                  <option value="11">Learning</option>
                  <option value="12">Literary</option>
                  <option value="13">Movies</option>
                  <option value="14">Musuems</option>
                  <option value="15">Neighborhood</option>
                  <option value="16">Networking</option>
                  <option value="17">Nightlife</option>
                  <option value="18">On Campus</option>
                  <option value="19">Organization</option>
                  <option value="20">Outdoors</option>
                  <option value="21">Pets</option>
                  <option value="22">Politics</option>
                  <option value="23">Sales</option>
                  <option value="24">Science</option>
                  <option value="25">Spirituality</option>
                  <option value="26">Sports</option>
                  <option value="27">Technology</option>
                  <option value="28">Theatre</option>
                  <option value="29">Other</option>
                </Form.Select>
              </Form.Group>

              <Row>
                <Form.Group controlId="search" className="mb-3" as={Col}>
                  <Form.Label>Search</Form.Label>
                  <Form.Switch
                    name="search"
                    onChange={(e) => {
                      setFieldValue("search", e.target.checked)
                    }}
                    checked={values.search}
                  />
                </Form.Group>
                <Form.Group controlId="approval" className="mb-3" as={Col}>
                  <Form.Label>Approval</Form.Label>
                  <Form.Switch
                    name="approval"
                    onChange={(e) => {
                      setFieldValue("approval", e.target.checked)
                    }}
                    checked={values.approval}
                  />
                </Form.Group>
                <Form.Group controlId="auth_invite" className="mb-3" as={Col}>
                  <Form.Label>Allow Guest Invitations</Form.Label>
                  <Form.Switch
                    name="auth_invite"
                    onChange={(e) => {
                      setFieldValue("auth_invite", e.target.checked)
                    }}
                    checked={values.auth_invite}
                  />
                </Form.Group>
              </Row>

              <Form.Group controlId="auth_view" className="mb-3">
                <Form.Label>View Privacy</Form.Label>
                <Form.Select
                  name="auth_view"
                  onChange={handleChange}
                  value={values.auth_view}
                >
                  <PermissionSelectValues />
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="auth_comment" className="mb-3">
                <Form.Label>Comment Privacy</Form.Label>
                <Form.Select
                  name="auth_comment"
                  onChange={handleChange}
                  value={values.auth_comment}
                >
                  <PermissionSelectValues />
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="auth_photo" className="mb-3">
                <Form.Label>Photo Uploads</Form.Label>
                <Form.Select
                  name="auth_photo"
                  onChange={handleChange}
                  value={values.auth_photo}
                >
                  <PermissionSelectValues />
                </Form.Select>
              </Form.Group>
              <Form.Group
                style={{
                  display: "flex", 
                  justifyContent: "space-between"
                }}
              >
                <Button variant="danger"
                  onClick={()=>{
                    navigate(-1)
                  }}
                >
                  Back
                </Button>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EventEdit;
