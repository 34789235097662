import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import routes from "../settings/routes";
import DefaultLayout from "./Default";

const ProfileSettingsNav = () => {
  const [nav, setNav] = useState([]);

  useEffect(() => {
    const profile_settings_nav_routes = routes.filter((val) => {
      if (val.name === "Settings") {
        return true;
      }
      return false;
    });

    if (!profile_settings_nav_routes) return;
    
    setNav(() => profile_settings_nav_routes[0].navOptions.subMenu);
  }, []);

  return (
    <div className="profile-settings-nav">
      <div className="profile-settings-nav-header">Settings</div>
      {nav.map((val, index) => {
        return (
          <NavLink
            to={val.link}
            key={index}
            className="profile-settings-nav-item"
            activeClassName="profile-settings-nav-item active"
            end={true}
          >
            {val.name}
          </NavLink>
        );
      })}
    </div>
  );
};

const ProfileSettingsLayout = ({ children }) => {
  return (
    <DefaultLayout>
      <div className="profile-settings-container">
        <ProfileSettingsNav />
        <div className="profile-settings-content">{children}</div>
      </div>
    </DefaultLayout>
  );
};

export default ProfileSettingsLayout;
