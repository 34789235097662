import { useToast } from "rc-toastr";
import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { shareContent } from "../../utils/api/functions/misc";
import { LoadingButton } from "../buttons/Buttons";

const ShareItemVideo = ({ data }) => {
  let posted_date = new Date(data.creation_date);
  posted_date = posted_date.toLocaleString("default", {
    month: "short",
    day: "numeric",
  });

  return (
    <div className="share-item">
      <div className="share-item-header">
        <img src={data.owner_image} className="post-item-profile-pic" />
        <div className="share-item-header-title">
          {data.owner_title}
          <div className="share-item-header-date">{posted_date}</div>
        </div>
      </div>
      <div className="share-item-body">
        {data.title}
        <video controls autoPlay={false} style={{ width: "100%" }}>
          <source src={data.video_url} />
        </video>
      </div>
    </div>
  );
};

const ShareItemPhoto = ({ data }) => {
  let posted_date = new Date(data.creation_date);
  posted_date = posted_date.toLocaleString("default", {
    month: "short",
    day: "numeric",
  });

  return (
    <div className="share-item">
      <div className="share-item-header">
        <div className="share-item-header-title">
          <div className="share-item-header-date">{posted_date}</div>
        </div>
      </div>
      <div className="share-item-body">
        {data.title}
        <img src={data.image} style={{ width: "100%" }} />
      </div>
    </div>
  );
};

const ShareItemModule = ({ data }) => {
  return(
    <div className="share-item">
      <div className="share-item-header">
        <div className="share-item-header-title">
          {/* <div className="share-item-header-date">{posted_date}</div> */}
        </div>
      </div>
      <div className="share-item-body">
        {data.title}
        <img src={data.image} style={{ width: "100%" }} />
      </div>
    </div>
  )
}

const PostBody = ({handleChange}) => {
  return(
    <input onChange={handleChange} />
  )
}

const ShareUserContent = ({
  show,
  onHide,
  data,
  type = "video",
  module_type = "", 
  object_id,
}) => {

  const bodyRef = useRef(null); 

  const [loading, setLoading] = useState(false);

  const { toast } = useToast();

  const handleShare = async () => {
    toast.info("Sharing...");
    setLoading(true);

    const _form = new FormData();
    const body = bodyRef.current.value;
 
    _form.append("type", type); 
    _form.append("id", object_id);
    _form.append("body", body);

    const res = await shareContent(_form);
    setLoading(false);
    onHide();
    if (res.status_code === 204) toast.success("Shared successfully");
    else toast.error("Error sharing");
  };

  const ShareItem = () => {
    if(type === "video") return <ShareItemVideo data={data} />
    else if(type === "photo") return <ShareItemPhoto data={data} />
    else return <ShareItemModule data={data} />
  }

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Share</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Share this by re-posting it with your own message</p>
        <div className="share-container">
          <textarea ref={bodyRef}/>

          <ShareItem />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <LoadingButton
          text={"Share"}
          variant={"primary"}
          onClick={handleShare}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ShareUserContent;
