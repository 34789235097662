/**
 * Additional UI management states for the posts list 
 */

import { create } from 'zustand';

const usePostsStore = create((set) => ({
    postsListRef: null, 
    setPostsListRef: (ref) => set((state) => {
        if(state.postsListRef !== ref){
            return {postsListRef: ref}
        }
        return state; 
    })
}))

export default usePostsStore; 