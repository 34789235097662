import React, { useEffect, useState } from "react";
import AlbumPhotos from "../../../components/albums/AlbumPhotos";
import { useGetSitePageAlbums } from "../../../utils/api/functions/sitepages";

export const PagePhotos = ({ page_id }) => {
  const { data } = useGetSitePageAlbums(page_id, {});
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setAlbums(() => [...data.body.response]);
    }
  }, [data]);

  return <AlbumPhotos album_data={albums} type="sitepage" />;
};
