/** 
 * Progress Bar Store
 * Using Zustand to manage state 
*/

import { create } from 'zustand';

const useProgressBarStore = create(set => ({
    progress: 0, 
    setProgress: (new_progress) => {set(() => ({progress: new_progress}))},
})); 


export default useProgressBarStore