import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const ActionDropdown = () => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((old) => !old);
  };

  return (
    <div className="event-view-header-content-actions">
      <div className="event-view-header-content-actions-button">
        <FontAwesomeIcon icon={faPen} onClick={toggleShow} />
      </div>
      <div
        className={`event-view-header-content-actions-menu ${
          show ? "show" : ""
        }`}
      >
        <div>Edit Event Details</div>
        <div>Join Event</div>
        <div>Report</div>
        <div>Share this Event</div>
        <div>Delete Event</div>
      </div>
    </div>
  );
};

const EventHeaderContent = ({ _event }) => {
  return (
    <div className="event-view-header-content">
      <img src={_event.image} />
      <div className="event-view-header-content-title">{_event.title}</div>
    </div>
  );
};

const EventViewHeader = ({ _event }) => {
  return (
    <div className="event-view-header-container">
      <EventHeaderContent _event={_event} />
    </div>
  );
};

export default EventViewHeader;
