import { useToast } from 'rc-toastr';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { postResetPassword } from '../../utils/api/functions/login';
import { validatePassword } from '../../utils/validate';


const ResetPassword = () => {
    const {code, uid} = useParams(); 

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");  
    const [validated, setValidated] = useState(false); 
    const [submitting, setSubmitting] = useState(false); 

    const {toast} = useToast(); 
    const navigate = useNavigate(); 

    const handleSubmit = async(e) => {
        
        e.preventDefault(); 
        if(password !== confirmPassword) {
            setValidated(false); 
            e.stopPropagation()
            return; 
        }
        if(!validatePassword(password)){
            setValidated(false); 
            e.stopPropagation(); 
            return; 
        }
        setValidated(true); 
        setSubmitting(true); 

        const result = await postResetPassword({
            code: code, 
            uid: uid, 
            password: password, 
            confirm_password: confirmPassword
        })

        if(result.status_code === 200) { //Success response
            toast.success("Password reset!"); 
            setSubmitting(false); 
            navigate('/'); 
        }
        else { //Error response
            const error_message = result.message || "Error resetting password"; 
            toast.error(error_message)

            setSubmitting(false); 
        }
        
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value); 
    }
    const handleChangeConfirm = (e) => {
        setConfirmPassword(e.target.value); 
    }

    return(
        <Form className="forgot-page-form"
            noValidate
            onSubmit={handleSubmit}
            validated={validated}
        >
            <div className="forgot-form-content">
                <h6>Reset Password</h6>
                <div
                    style={{
                        marginBottom: "10px"
                    }}
                >
                    <Form.Text>
                        Enter your new password below
                    </Form.Text>
                </div>
                <Form.Group className="mb-3" controlId="password">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control 
                        type="password"
                        name="password"
                        placeholder="New Password"
                        value={password}
                        onChange={handleChangePassword}
                        isInvalid={!validatePassword(password)}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                    Password must be at least 6 characters long and contain at least one uppercase letter and one special character
                    </Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group className="mb-3" controlId="confirm_password">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control 
                        type="password"
                        name="confirm_password"
                        placeholder="Confirm Password"
                        pattern={confirmPassword}
                        value={confirmPassword}
                        onChange={handleChangeConfirm}
                        isInvalid={confirmPassword !== password}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Passwords must match!
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    style={{
                        display: "flex", 
                        justifyContent: "space-evenly"
                    }}
                >
                    <Button variant="danger" onClick={()=>{navigate('/')}}>Cancel</Button>
                    <Button type="submit" disabled={submitting}>Save</Button>
                </Form.Group>
            </div>
        </Form>
    )
}

export default ResetPassword; 