import React from "react";

const VerifyEmail = () => {
  return (
    <div className="signup-container">
      <div className="signup-welcome" style={{ margin: "auto" }}>
        Welcome to XAPiT! Please verify with the verification link sent to your
        inbox to continue.
      </div>
    </div>
  );
};

export default VerifyEmail;
