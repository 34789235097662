import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;

export const postGlobalSearch = (values) => {
  
  return fetcher([
    hostname + "/search",
    {
      method: "POST",
      body: values,
      headers: {
        Accept: "application/json",
      },
    },
  ]);

  // return useSWR(hostname + "/search", {
  //     method: "POST",
  //     body: queryString.stringify(values),
  //     headers: {
  //         "Content-Type": "application/json",
  //         "Accept": "application/json",
  //         "oauth_consumer_key": oauthConfig.oauth_consumer_key,
  //         "oauth_consumer_secret": oauthConfig.oauth_consumer_secret
  //     }
  // }, true)
};

export const postCoverPhoto = (values, subject_id, subject_type) => {
  return fetcher([
    hostname + `/user/profilepage/upload-cover-photo/user_id/${subject_id}`, 
    {
      method: "POST", 
      body: values,
      headers: {
        Accept: "application/json",
        "oauth_consumer_key": oauthConfig.oauth_consumer_key,
        "oauth_consumer_secret": oauthConfig.oauth_consumer_secret
      }
    }
  ], false, true)
}

export const postPageCoverPhoto = (values, subject_id, subject_type) => {
  return fetcher([
    hostname + `/coverphoto/upload-cover-photo?subject_id=${subject_id}&subject_type=${subject_type}`, 
    {
      method: "POST", 
      body: values,
      headers: {
        Accept: "application/json",
        "oauth_consumer_key": oauthConfig.oauth_consumer_key,
        "oauth_consumer_secret": oauthConfig.oauth_consumer_secret
      }
    }
  ], false, true)
}

export const postGroupCoverPhoto = (values, group_id) => {
  return fetcher([
    hostname + `/groups/coverphoto/${group_id}`, 
    {
      method: "POST", 
      body: values, 
      headers: {
        Accept: "application/json", 
        "oauth_consumer_key": oauthConfig.oauth_consumer_key, 
        "oauth_consumer_secret": oauthConfig.oauth_consumer_secret
      }
    }
  ], false, true)
}

export const postReportContent = (values) => {
  return fetcher(
    [
      hostname + "/report/create",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
  );
};

export const shareContent = (values) => {
  return fetcher([
    hostname + "/activity/share",
    {
      method: "POST",
      body: values,
      headers: {
        Accept: "application/json",
      },
    },
  ]);
};

export const inviteFriends = (values) => {
  return fetcher([
    hostname + "/invite",
    {
      method: "POST",
      body: values,
      headers: {
        Accept: "application/json",
      },
    },
  ]);
};
