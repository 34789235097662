import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactSlider from 'react-slider';
import { formatDateWithTimezone } from '../../../utils/parsing.js';

// Timeline component constants
const startingYear = 2018; 
const startingMonth = 10; 
const currentDate = new Date(); 
const currentMonth = currentDate.getMonth() + 1; // Using zero-indexed month
const currentYear = currentDate.getFullYear();

// Helper to create the date array
const createDateArray = (startingYear, startingMonth, currentYear, currentMonth) => {
  let dateArray = [];
  for (let year = startingYear; year <= currentYear; year++) {
    let startMonth = (year === startingYear) ? startingMonth - 1 : 0;
    let endMonth = (year === currentYear) ? currentMonth - 1 : 11;
    for (let month = startMonth; month <= endMonth; month++) {
      dateArray.push({ year, month: new Intl.DateTimeFormat('en-US', { month: 'short' }).format(new Date(year, month)) });
    }
  }
  return dateArray;
};




const TimelineSlider = ({ _handleChange }) => {
  

  // Memoize the date array to avoid recalculating on every render
  const dates = useMemo(() => createDateArray(startingYear, startingMonth, currentYear, currentMonth), []);
  const [range, setRange] = useState([0, dates.length -1]);
  const getDates = () => {
    const start = new Date(`${dates[range[0]].month} 1, ${dates[range[0]].year}`);
    const date_start = formatDateWithTimezone(start);

    // End date (last day of the month at 23:59:59)
    const end = new Date(`${dates[range[1]].month} 1, ${dates[range[1]].year}`);
    end.setMonth(end.getMonth() + 1); // Move to the next month
    end.setDate(0); // Set date to the last day of the previous month
    end.setHours(23, 59, 59, 999); // Set time to the end of the day
    const date_end = formatDateWithTimezone(end);
    return [date_start, date_end];
  }

  // Debounced function to handle range change
  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("use effect fired")
      _handleChange(getDates());
    }, 750); // Debounce by 750ms

    return () => clearTimeout(timer); // Cleanup timer on unmount or new change
  }, [range]);

  // Handler for slider change
  const handleChange = (value) => {
    setRange(value);
  };

  if (dates.length === 0) {
    return null;
  }

  return (
    <>
      {/* {range.length > 0 && (
        <div>
          Start Range: {`${dates[range[0]].month}, ${dates[range[0]].year}`} 
          End Range: {`${dates[range[1]].month}, ${dates[range[1]].year}`}
        </div>
      )} */}

      <ReactSlider
        className="timeline-slider"
        thumbClassName="timeline-thumb"
        trackClassName="timeline-track"
        defaultValue={[0, dates.length - 1]}
        ariaLabel={['Lower thumb', 'Upper thumb']}
        renderThumb={(props, state) => <div {...props}>{`${dates[range[state.index]].month}, ${dates[range[state.index]].year}`}</div>}
        orientation="vertical"
        invert
        pearling
        onChange={handleChange}
        max={dates.length - 1}
      />
    </>
  );
};

// Wrapping component with React.memo to prevent unnecessary re-renders
const TimelineNavigation = React.memo(({ handleChange }) => {
  const handleTimelineChange = (range) => {
    handleChange(range);
  };

  return (
    <div
      style={{
        flexGrow: 1
      }}
      className="timeline-wrapper"
    >
      <TimelineSlider _handleChange={handleTimelineChange} />
    </div>
  );
});


export const TimelineNavigationToggle = ({handleToggle, toggleState}) => {

  return(
  <div className="home-sidebar-item-sm"
    style={{
      display: 'flex', 
      justifyContent: 'center',
      alignItems: 'center'
    }}
  > 
    <Button className="date-filter-button" style={{width: "100%", height: "3em"}} onClick={handleToggle}>
      {toggleState ? "Close Date Filter" : "Open Date Filter"}
    </Button>
  </div>
  )
}

export default TimelineNavigation;
