import React, { useEffect } from "react";

import {
  DefaultAlbumItem,
  GroupAlbumItem,
  SitepageAlbumItem,
} from "./AlbumItem";

const AlbumPhotos = ({ album_data, type }) => {
  useEffect(() => {}, [album_data]);

  //Use AlbumItem Element depending on type (sitepages use a different api route / swr function)
  //const AlbumItem = (type === "sitepage") ? SitepageAlbumItem : DefaultAlbumItem;

  let AlbumItem;

  switch (type) {
    case "sitepage":
      AlbumItem = SitepageAlbumItem;
      break;
    case "group":
      AlbumItem = GroupAlbumItem;
      break;
    default:
      AlbumItem = DefaultAlbumItem;
      break;
  }

  return (
    <div className="album-photos-component">
      {/* <Button>+ Create Album</Button> */}
      <div className="album-photos-component-list">
        {album_data.map((val, i) => {
          return <AlbumItem key={`album-item-${i}`} album_data={val}/>;
        })}
      </div>
    </div>
  );
};

export default AlbumPhotos;
