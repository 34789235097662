import { useToast } from "rc-toastr";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { postReportContent } from "../../utils/api/functions/misc";

export const ReportModal = ({ show, onHide, type, id, category = "abuse" }) => {
  const [description, setDescription] = useState("");

  const { toast } = useToast();

  const handleReport = async () => {
    const form_data = new FormData();
    toast.info("Reporting content");
    form_data.append("type", type);
    form_data.append("id", id);
    form_data.append("category", category);
    form_data.append("description", description);

    const response = await postReportContent(form_data);


    if (response?.status_code === 204) {
      toast.success("Report successful!");
    } else {
      toast.error("Error reporting content, please try again");
    }
    onHide();
  };

  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Report Content</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter description"
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button variant="primary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleReport}>
            Report
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
