import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBrowseAlbums } from "../../utils/api/functions/photosAlbums";
import AlbumPhotos from "../../components/albums/AlbumPhotos";
import { Bars } from "react-loading-icons";

const ProfileAlbums = () => {
  const { id } = useParams();

  const { data, isLoading, mutate } = useBrowseAlbums({
    user_id: id,
  });
  const [albumData, setAlbumData] = useState([]);

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setAlbumData((old) => [...data.body.response]);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="loading-icon-wrapper">
        <Bars stroke="#00BCD4" fill="#00BCD4" />
      </div>
    );
  }
  if (!isLoading && albumData.length === 0) {
    return (
      <div className="loading-icon-wrapper">Nothing to see here yet...</div>
    );
  }

  return <AlbumPhotos album_data={albumData} type="profile" />;
};

export default ProfileAlbums;
