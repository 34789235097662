import { Formik } from "formik";
import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import TailSpin from "react-loading-icons/dist/esm/components/tail-spin";
import {
  PutEditPrivacySettings,
  useGetPrivacySettings,
} from "../../../utils/api/functions/memberSettings";

const ProfilePrivacyInitialValues = {
  search: 0,
  privacy: "",
  last_login_date: "",
};

const PrivacySelect = ({ label, text, value, onChange, ...props }) => {
  return (
    <Form.Group {...props} className="mb-3">
      <Form.Label>{label}</Form.Label>
      <Form.Text className="text-muted">{text}</Form.Text>
      <Form.Select size="sm" value={value} onChange={onChange}>
        <option value="owner">Only Me</option>
        <option value="member">My Friends</option>
        <option value="network">Friends & Networks</option>
        <option value="registered">All Registered Members</option>
        <option value="everyone">Everyone</option>
      </Form.Select>
    </Form.Group>
  );
};

const ProfileSettingsPrivacy = () => {
  const [settings, setSettings] = useState({});
  const { data, mutate } = useGetPrivacySettings();

  const {toast} = useToast(); 

  useEffect(() => {
    if (data && data.body && data.body.formValues) {
      setSettings((old) => data.body.formValues);
    }
  }, [data]);

  return (
    <div className="profile-settings-form-container">
      <Formik
        enableReinitialize={true}
        initialValues={settings}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true); 
          const _formData = new FormData();

          Object.keys(values).map((key) => {
            if (key === "publishTypes") return;
            _formData.append(key, values[key]);
          });

          const result = await PutEditPrivacySettings(_formData);
          if(result.status_code === 204){
            toast.success("Settings saved!"); 
          }
          setSubmitting(false); 
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <Form.Group className="mb-3" controlId="search">
              <Form.Label>Profile Discovery</Form.Label>
              <Form.Check
                type="switch"
                name="search"
                label='Show my profile during searches, browsing members, and/or the "Online Members" list.'
                checked={values?.search || 0}
                onChange={(e) => {
                  setFieldValue("search", e.target.checked ? 1 : 0);
                }}
              />
            </Form.Group>
            <PrivacySelect
              label="Profile Privacy"
              text="Who may view your profile?"
              controlId="privacy"
              value={values?.privacy}
              onChange={handleChange}
            />
            <PrivacySelect
              label="Last Login Date"
              text="Who may view your last login date?"
              controlId="lastLoginDate"
              value={values?.lastLoginDate}
              onChange={handleChange}
            />
            <PrivacySelect
              label="Last Update Date"
              text="Who may view your last update date?"
              controlId="lastUpdateDate"
              value={values?.lastUpdateDate || ""}
              onChange={handleChange}
            />
            <PrivacySelect
              label="Name of Invitee"
              text="Who may view the name of the person who invited you to join?"
              controlId="inviteeName"
              value={values?.inviteeName}
              onChange={handleChange}
            />
            <PrivacySelect
              label="Member Level"
              text="Who may view your member level?"
              controlId="memberLevel"
              value={values?.memberLevel}
              onChange={handleChange}
            />
            <PrivacySelect
              label="Profile Views"
              text="Who can view your Profile Views?"
              controlId="profileViews"
              value={values?.profileViews}
              onChange={handleChange}
            />
            <PrivacySelect
              label="Profile Comment Privacy"
              text="Who may comment on your profile?"
              controlId="comment"
              value={values?.comment}
              onChange={handleChange}
            />
            <PrivacySelect
              label="Profile Posting Privacy"
              text="Who may post on your profile?"
              controlId="profileType"
              value={values?.profileType}
              onChange={handleChange}
            />
            <PrivacySelect
              label="Mentions Privacy"
              text="Who may mention you?"
              controlId="mention"
              value={values?.mention}
              onChange={handleChange}
            />
            <Button variant="primary" type="submit" disabled={isSubmitting}>
                {
                  isSubmitting ? (
                    <TailSpin
                      stroke="#fff"
                      speed={1}
                      width="20px"
                      height="20px"
                    />
                  ) : "Save Changes"
                }
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileSettingsPrivacy;
