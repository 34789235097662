import React, { useEffect, useState } from "react";
import { useGetAds } from "../../utils/api/functions/communityAds";
import { map_type_to_link } from "../../utils/mapping";

const AdsWidget = () => {
  const [ads, setAds] = useState([]);
  const { data } = useGetAds({});

  useEffect(() => {

    if (data && data.body && data.body.advertisements) {
      setAds(() => data.body.advertisements.slice(0, 1));
    }
  }, [data]);

  return (
    <div className="ads-widget-container">
      {ads.map((ad, index) => (
        <div className="ads-widget-item" key={`ads-widget-item-${index}`}>
          <a href={map_type_to_link(ad.resource_type, ad.resource_id)}></a>
          <div className="ads-widget-item-title">{ad.cads_title}</div>

          <img className="ads-widget-item-image" src={ad.image} />
          <div className="ads-widget-item-description">{ad.cads_body}</div>
        </div>
      ))}
    </div>
  );
};

export default AdsWidget;
