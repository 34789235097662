import React from "react";

const AdHelp = () => {
  return (
    <div className="advertise-content-box">
      <div className="advertise-content-box-header">Help & Support</div>
      <div className="box-content"></div>
    </div>
  );
};

export default AdHelp;
