import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CreatePost from "../../components/posts/CreatePost";
import PostsFeed from "../../components/posts/Feedv2";
import { useGetUserInfo } from "../../utils/api/functions/members";

const ProfilePosts = () => {
  const { id } = useParams();
  const [canPost, setCanPost] = useState(false);

  const { data } = useGetUserInfo({
    user_id: id,
  });

  useEffect(() => {
    if (data && data.body && data.body.response) {
      if (
        data.body.response.is_follow !== 0 ||
        id === localStorage.getItem("user_id")
      ) {
        setCanPost(true);
      }
    }
  }, [data]);

  return (
    <div className="profile-posts">
      <PostsFeed
        feed_params={{
          subject_id: id,
          subject_type: "user",
        }}
        CreatePostComponent={CreatePost}
        can_post={canPost}
      />
    </div>
  );
};

export default ProfilePosts;
