import {
  faArrowRightFromBracket,
  faBan,
  faCircleMinus,
  faFlag,
  faPencil,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import UploadCoverPhoto from "../components/buttons/UploadCoverPhoto";
import {
  LoadingList,
  LoadingListWithImage,
} from "../components/loading-placeholders/LoadingSidebarCards";
import { ReportModal } from "../components/widgets/ReportModal";
import ShareUserContent from "../components/widgets/ShareUserContent";
import {
  postDeleteGroup,
  postJoinGroup,
  postLeaveGroup,
  useBrowseGroups,
  useGetGroup,
} from "../utils/api/functions/groups";
import { getMonthAndDate } from "../utils/parsing";
import Layout from "./Default";

const GroupHeader = ({ groupImage, groupCoverphoto }) => {
  const {id} = useParams();

  const { data } = useGetGroup(id, {});

  const [groupData, setGroupData] = useState({});

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setGroupData(() => data.body.response);
    }
  }, [data]);

  return (
    <div className="groups-header">
      <div className="groups-header-wrapper" style={{position: "relative"}}>
        <img className="groups-header-coverphoto" src={"https://pizzasandwich.xapit.com"+groupCoverphoto}/>
        <div className="groups-image-container">
          <img src={groupImage} />
        </div>
        <div className="groups-header-title">
          <h4>{groupData.title}</h4>
        </div>
        <UploadCoverPhoto type={"group"} subject_id={id} subject_type={"sitegroup_group"}/>
      </div>
    </div>
  );
};

//Group Delete confirmation modal
const GroupDeleteModal = ({ show, handleClose }) => {
  const { id } = useParams();
  const { toast } = useToast();
  const navigate = useNavigate();

  const handleDelete = async () => {
    const form_data = new FormData();
    const res = await postDeleteGroup(id, form_data);

    if (res.status_code === 204) {
      toast.success("Group deleted!");
      navigate("/groups/my-groups");
    } else {
      toast.error("Error deleting group");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton style={{ fontWeight: "bold" }}>
        Confirm Delete Group
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

//Group View Sidebar Components
const GroupViewActions = () => {
  const { id } = useParams();
  const { data, mutate } = useGetGroup(id, {});
  const [groupData, setGroupData] = useState({});
  const [isOwner, setIsOwner] = useState(false);
  const { toast } = useToast();
  const [showReport, setShowReport] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {


    if (data && data.body && data.body.response) {
      setGroupData(() => data.body.response);

      if (
        data.body.response.user_id === Number(localStorage.getItem("user_id"))
      ) {
        setIsOwner(true);
      }
    }
  }, [data]);

  const handleJoinGroup = async () => {
    const form_data = new FormData();

    const response = await postJoinGroup(id, form_data);

    if (response.status_code === 204) {
      toast.success("Group joined!");
      mutate();
    } else {
      toast.error("Error joining group");
    }
  };
  const handleLeaveGroup = async () => {
    const form_data = new FormData();

    const response = await postLeaveGroup(id, form_data);


    if (response.status_code === 204) {
      toast.success("Left group successfully");
      mutate();
    } else {
      toast.error("Error leaving group");
    }
  };
  const handleDeleteGroup = async () => {
    setShowDelete(true);
  };

  const handleReport = () => {
    setShowReport(true);
  };
  const handleCancelReport = () => {
    setShowReport(false);
  };

  return (
    <div className="groups-view-sidebar-item">
      <div className="groups-sidebar-actions">
        {!isOwner && (
          <div className="groups-sidebar-actions-item">
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
            {groupData.isMember === 1 ? (
              <span onClick={handleLeaveGroup}>Leave Group</span>
            ) : (
              <span onClick={handleJoinGroup}>Join Group</span>
            )}
          </div>
        )}

        {isOwner && (
          <div className="groups-sidebar-actions-item">
            <FontAwesomeIcon icon={faPencil} />
            <Link to={`/groups/group/${id}/edit`}>Edit Group</Link>
          </div>
        )}
        {isOwner && (
          <div className="groups-sidebar-actions-item">
            <FontAwesomeIcon icon={faCircleMinus} />
            <span onClick={handleDeleteGroup}>Delete Group</span>
          </div>
        )}
        <div className="groups-sidebar-actions-item">
          <FontAwesomeIcon icon={faFlag} />
          <span onClick={handleReport}>Report</span>
        </div>
        <ShareGroup group_data={groupData}/>
        {!isOwner && (
          <div className="groups-sidebar-actions-item">
            <FontAwesomeIcon icon={faBan} />
            <span>Block Group</span>
          </div>
        )}
      </div>
      <GroupDeleteModal
        show={showDelete}
        handleClose={() => {
          setShowDelete(false);
        }}
      />
      <ReportModal
        show={showReport}
        onHide={handleCancelReport}
        type="group"
        id={id}
      />
    </div>
  );
};

const ShareGroup = ({group_data}) => {
  const {id} = useParams(); 
  const [showShare, setShowShare] = useState(false); 
  const handleShowShareModal = () => {
    setShowShare(true); 
  }
  const handleHideShareModal = () => {
    setShowShare(false);
  }

  return(
    <div onClick={handleShowShareModal} className="groups-sidebar-actions-item">
      <FontAwesomeIcon icon={faShareNodes} /> Share Group
      <ShareUserContent 
        show={showShare}
        onHide={handleHideShareModal}
        data={group_data}
        type={"group"}
        object_id={id}
      />
    </div>
  )

}

const GroupViewInfo = ({ data, isLoading = false }) => {
  useEffect(() => {
    window.document.title = `${data.title ? data.title : "Group"} - XAPiT`;
  }, [data]);

  if (isLoading)
    return (
      <div className="groups-view-sidebar-item">
        <div className="groups-sidebar-info">
          <div className="groups-sidebar-item-header">Group Info</div>
          <LoadingList row_num={4} />
        </div>
      </div>
    );

  return (
    <div className="groups-view-sidebar-item">
      <div className="groups-sidebar-info">
        <div className="groups-sidebar-item-header">Group Info</div>
        <div id="groups-sidebar-info-title">{data.title}</div>
        <div id="groups-sidebar-info-category">{data.category_title}</div>
        <div id="groups-sidebar-info-desc">{data.description}</div>
        <div id="groups-sidebar-info-owner">
          <Link to={`/profile/${data.user_id}`}>{data.owner_title}</Link>{" "}
          (owner)
        </div>
        <div className="groups-sidebar-info-stats">
          <span>{data.view_count} total views</span>
          <span>{data.member_count} total members</span>
          <span>
            Last updated{" "}
            {data.modified_date ? getMonthAndDate(data.modified_date) : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

const GroupRecommended = ({ limit = 2 }) => {
  const { data, isLoading } = useBrowseGroups({
    orderby: "view_count",
    limit: limit,
  });

  const [recommended, setRecommended] = useState([]);

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setRecommended(() => [...data.body.response.slice(0, limit)]);
    }
  }, [data]);

  if (isLoading)
    return (
      <div className="groups-view-sidebar-item">
        <div className="groups-sidebar-recommended">
          <div className="groups-sidebar-item-header">Recommended Groups</div>
          <div className="groups-sidebar-item-list">
            <LoadingListWithImage row_num={2} />
          </div>
        </div>
      </div>
    );

  return (
    <div className="groups-view-sidebar-item">
      <div className="groups-sidebar-recommended">
        <div className="groups-sidebar-item-header">Recommended Groups</div>
        <div className="groups-sidebar-item-list">
          {recommended.map((val, i) => {
            return (
              <div
                className="groups-recommended-item"
                key={`group_recommended_item_${i}`}
              >
                <div className="groups-recommended-image">
                  <Link to={`/groups/group/${val.group_id}`}>
                    <img src={val.image_icon} />
                  </Link>
                </div>
                <div className="groups-recommended-info">
                  <Link to={`/groups/group/${val.group_id}`}>
                    <p className="groups-recommended-title">{val.title}</p>
                  </Link>
                  <p className="groups-recommended-members">
                    {val.member_count} members led by{" "}
                    <Link to={`/profile/${val.user_id}`}>
                      {val.owner_title}
                    </Link>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const GroupViewSidebar = ({ data, isLoading = false }) => {
  return (
    <div className="groups-view-sidebar">
      <GroupViewActions />
      <GroupViewInfo data={data} isLoading={isLoading} />
      <GroupRecommended />
    </div>
  );
};

const GroupNav = () => {
  const { id } = useParams();

  return (
    <div
      className="component-nav"
      style={{ height: "4.25rem", marginTop: "0" }}
    >
      <NavLink
        to={`/groups/group/${id}/posts`}
        key={"component-nav-1"}
        className="component-nav-item"
        activeClassName="component-nav-item-active"
      >
        Posts
      </NavLink>
      <NavLink
        to={`/groups/group/${id}/members`}
        key={"component-nav-2"}
        className="component-nav-item"
        activeClassName="component-nav-item-active"
      >
        Members
      </NavLink>
      {/* <NavLink
                to={`/groups/group/${id}/videos`}
                key={'component-nav-3'}
                className="component-nav-item"
                activeClassName="component-nav-item-active"
            >
                Videos
            </NavLink> */}
    </div>
  );
};

const GroupViewLayout = ({ children }) => {
  const { id } = useParams();

  const { data, isLoading } = useGetGroup(id, {});

  const [groupData, setGroupData] = useState({});

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setGroupData(() => data.body.response);
    }
  }, [data]);

  return (
    <Layout>
      <div className="groups-view-container">
        <GroupHeader
          groupImage={groupData.image_profile}
          title={groupData.title}
          groupCoverphoto={groupData.coverphoto_url}
        />
        <div className="groups-view-content-container">
          <GroupViewSidebar data={groupData} isLoading={isLoading} />
          <div className="group-view-content-wrapper">
            <GroupNav />
            {children}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GroupViewLayout;
