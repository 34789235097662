import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { inviteFriends } from "../../utils/api/functions/misc";

const EmailInput = ({
  removeable = true,
  handleRemove,
  handleChange,
  value
}) => {
  return (
    <div className="invite-page-form-email-input">
      <Form.Control
        type="email"
        placeholder="Email Address"
        onChange={handleChange}
        value={value}
      />
      {removeable ? (
        <Button variant="danger" onClick={handleRemove}>
          X 
        </Button>
      ) : null}
    </div>
  );
};

const InviteForm = ({handleSuccess}) => {
  const [emailInputs, setInputs] = useState([
    {
      value: "",
    },
  ]);
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [message, setMessage] = useState("");

  const handleAddEmail = () => {
    setInputs((old) => [...old, { value: "" }]);
  };
  const handleRemoveEmail = (index) => {
    let newInputs = [...emailInputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };
  const handleChange = (e, index) => {
    let newInputs = [...emailInputs];
    newInputs[index].value = e.target.value;
    setInputs(newInputs);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    
    

    let email_list = [];
    for (let i = 0; i < emailInputs.length; i++) {
      email_list.push(emailInputs[i].value.replace(" ", ""));
    }

    const form_data = new FormData();
    form_data.append("recipients", email_list.join(","));
    form_data.append("message", message);

    let result = await inviteFriends(form_data);
    if (result) {
      toast.success("Invites sent!");
      setMessage(() => "");
      handleSuccess(); 
    }
    
    setInputs(() => [{ value: "" }]);
    setMessage(() => "");
    setIsSubmitting(false);
  };

  useEffect(() => {}, []);

  return (
    <Form>
      <Form.Group
        className="invite-page-email-input-group mb-3"
        controlId="email_address"
      >
        <Form.Label>Recipients</Form.Label>

        {emailInputs.map((input, index) => {
          return (
            <EmailInput
              key={index}
              index={index}
              handleRemove={() => {
                handleRemoveEmail(index);
              }}
              handleChange={(e) => {
                handleChange(e, index);
              }}
              removeable={true}
              value={input.value}
            />
          );
        })}
      </Form.Group>
      <Button
        size="sm"
        onClick={handleAddEmail}
        className="invite-page-form-email-input-add"
      >
        +
      </Button>
      <Form.Group
        className="mb-3 custom-message-invite-friends"
        controlId="message"
      >
        <Form.Label>Custom Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={6}
          placeholder="Message"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
      </Form.Group>
      <Button className="send-invite-friends" variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
        Send Invites
      </Button>
    </Form>
  );
};

const Invite = () => {
  const [success, setSuccess] = useState(false); 
  const handleSuccess = () => {
    setSuccess(true); 
  };

  return (
    <div className="invite-page-container">
      <div className="invite-page-header">Invite Your Friends</div>
      <div className="invite-page-content">
        {
          !success ? (
            <InviteForm handleSuccess={handleSuccess} />
          ) : (
            <h5 
            style={{textAlign: "center", marginTop: "5rem", marginBottom: "5rem"}}
            >Invite(s) sent successfully!</h5>
          )
        }
      </div>
    </div>
  );
};

export default Invite;
