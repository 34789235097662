import React from "react";
import { Form } from "react-bootstrap";

const AdReports = () => {
  return (
    <div className="advertise-content-box">
      <div className="advertise-content-box-header">Reports</div>
      <div className="box-content">
        <Form></Form>
      </div>
    </div>
  );
};

export default AdReports;
