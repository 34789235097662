import React, { useEffect } from "react";

export const PageAbout = ({ page_data, ...props }) => {
  useEffect(() => {}, [page_data]);

  return (
    <div className="sitepages-about-container">
      {/* <p>
        <strong>Location: </strong>
        {page_data.location}
      </p> */}
      <p>
        <strong>Created: </strong>
        {page_data.creation_date
          ? new Date(page_data.creation_date).toLocaleDateString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })
        : ""}{" "}
      </p>
      <p>
        <strong>Last Updated: </strong>
        {page_data.modified_date
          ? new Date(page_data.modified_date).toLocaleDateString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })
        : ""}{" "}
      </p>
      <p>
        <strong>Members: </strong>
        {page_data.member_count}
      </p>
      <p>
        <strong>Comments: </strong>
        {page_data.comment_count}
      </p>
      <p>
        <strong>Views: </strong>
        {page_data.view_count}
      </p>
      <p>
        <strong>Likes: </strong>
        {page_data.like_count}
      </p>
      <p>
        <strong>Followers: </strong>
        {page_data.follow_count}
      </p>
      <p>
        <strong>Category: </strong>
        {page_data.category_title}
      </p>
      <p>
        <strong>Description: </strong>
        {page_data.body}
      </p>
    </div>
  );
};
