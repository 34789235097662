import * as React from "react";
import { useCookies } from "react-cookie";
import API from "./api/handler";

const AuthContext = React.createContext();

function useAuth() {
  //Retrieve initial login state using temporary true|false cookie.
  let initialState = false;

  const [cookies, setCookie, removeCookie] = useCookies([
    "oauth_token",
    "oauth_secret",
  ]);

  if (
    cookies["oauth_token"] &&
    cookies["oauth_secret"] &&
    localStorage.getItem("user_id")
  ) {
    initialState = true;
  } else {
    initialState = false;
  }

  const [authed, setAuthed] = React.useState(initialState);
  const [userId, setUserId] = React.useState(null);
  return {
    authed,
    login(email, pass) {
      return new Promise(async (res, rej) => {
        let login_res = await API.Login.login(email, pass);
        
        if (
          login_res.status_code === 200 &&
          login_res.body.oauth_secret &&
          login_res.body.oauth_token
        ) {
          setCookie("oauth_token", login_res.body.oauth_token);
          setCookie("oauth_secret", login_res.body.oauth_secret);
          localStorage.setItem("user_id", String(login_res.body.user.user_id));
          setUserId(login_res.body.user.user_id);
          setAuthed(true);
          res(true);
        } else {
          
          setAuthed(false);
          rej(login_res.error_code);
        }
      });
    },
    logout() {
      return new Promise((res) => {
        removeCookie("oauth_token");
        removeCookie("oauth_secret");
        localStorage.removeItem("user_id");
        setAuthed(false);
        res();
      });
    },
    userId,
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(AuthContext);
}
