import {
  faEllipsis,
  faPaperPlane,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useToast } from "rc-toastr";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from 'react-bootstrap';
import { Bars } from "react-loading-icons";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import {
  postDeleteMessage,
  postNewMessage,
  postSendMessage,
  useGetConversation,
} from "../../utils/api/functions/messages";
import { useMessages } from "../../utils/context/messages/messagesContext";


const MessageBoxItem = ({ data }) => {
  const user_id = localStorage.getItem("user_id");
  let is_user = false;
  if (data?.message?.user_id == user_id) {
    is_user = true;
  }
  if(!data?.message?.body || data.message.body === " ") return null;

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior : "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [data]);

  return (
    <div
      className={`message-box-item ${
        is_user ? "sent-message" : "received-message"
      }`}
    >
      <div className="message-box-item-content">{data?.message?.body}</div>
      <div ref={messagesEndRef}></div>
    </div>
  );
};

const MessageActionMenu = ({ conversation_id, handleDelete }) => {
  const [show, setShow] = useState(false);
  const menuRef = useRef(null);
  const handleToggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className="message-action-menu-wrapper">
      <FontAwesomeIcon icon={faEllipsis} onClick={handleToggle} />
      <div
        className={`message-action-menu ${show ? "show" : ""}`}
        ref={menuRef}
      >
        <div onClick={()=>{handleDelete(conversation_id)}}>Delete</div>
      </div>
    </div>
  );
};

export const MessageBox = ({
  containerClass = "message-box",
}) => {
  const { toast } = useToast();

  const {show, activeMessage: message_data, hideMessageBox} = useMessages(); 

  const [conversationData, setConversation] = useState({});
  const { data, isLoading } = useGetConversation({
    id: message_data?.message?.conversation_id,
  });

  const [message, setMessage] = useState("");

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const sendMessage = async () => {
    toast.info("Sending message...");
    const formData = new FormData();
    formData.append("body", message);
    formData.append("id", message_data?.message?.conversation_id);

    setMessage("");
    let result = await postSendMessage(
      message_data?.message?.conversation_id,
      formData,
    );
    if (result && result.status_code == 200) {
      setConversation(() => result.body);

      toast.success("Message sent successfully");
    }
  };

  const handleDelete = async (conversation_id) => {
    toast.info("Deleting conversation...");
    const form_data = new FormData();
    form_data.append("conversation_ids", conversation_id);
    const res = await postDeleteMessage(form_data);
    if (res.status_code === 204) {
      toast.success("Conversation deleted successfully");
      setTimeout(() =>{
        window.location.reload(); 
      }, 1500);
    } else {
      toast.error("Error deleting conversation");
    }
  };

  useEffect(() => {
    if (data && data.status_code === 200 && data.body) {
      setConversation(data.body);
    }
  }, [data]);

  return (
    <div className={`${containerClass} ${show ? "active-show" : ""}`}>
      <div className="message-box-header">
        <div className="message-box-header-image">
          <img src={message_data?.sender?.image_icon} />
        </div>
        <div className="message-box-header-name">
          <Link
            to={`/profile/${message_data?.sender?.user_id}`}
          >
            {message_data?.sender?.displayname
            .replace("<i>", "")
            .replace("</i>", "")}
          </Link>
        </div>
        <div className="message-box-header-actions">
          <MessageActionMenu
            conversation_id={message_data?.message?.conversation_id}
            handleDelete={handleDelete}
          />
          <FontAwesomeIcon icon={faXmark} onClick={hideMessageBox} />
        </div>
      </div>
      <div className="message-box-content">
        {isLoading ? (
          <div className="message-box-loading-wrapper">
            <Bars stroke="#00BCD4" fill="#00BCD4" />
          </div>
        ) : null}
        {conversationData?.messages?.map((message, index) => {
          return <MessageBoxItem data={message} key={index}></MessageBoxItem>;
        })}
      </div>
      <div className="message-box-footer">
        <div className="message-emoticon"></div>
        <div className="message-input">
          <textarea
            type="text"
            name="message"
            placeholder="Type a message"
            value={message}
            onChange={handleMessageChange}
          />
        </div>
        <div className="message-action">
          <FontAwesomeIcon icon={faPaperPlane} onClick={sendMessage} />
        </div>
      </div>
    </div>
  );
};

export const NewMessage = () => {
  const {showNewMessageForm, closeNewMessage} = useMessages(); 
  const {toast} = useToast(); 

  const handleSendNewMessage = async(values, {setSubmitting}) => {
    const formData = new FormData(); 
    formData.append("toValues", showNewMessageForm.user_id); 
    formData.append("title", values.title); 
    formData.append("body", values.body); 
    formData.append("react_app", true); 
    const result = await postNewMessage(formData); 
    if(result && result.status_code === 200) {
      setSubmitting(false);

      toast.success("Message sent!"); 
      closeNewMessage(result.body.messages[0]); 
    }
    else {
      setSubmitting(false); 
      toast.error("Error sending message"); 
    }
    
  }
  return(
    <Modal
      show={showNewMessageForm}
      size="lg"
      onHide={closeNewMessage}
      centered={true}
    >
      <Modal.Header>
        Compose a new message
      </Modal.Header>
      
        <Formik
          validationSchema={Yup.object({
            title: Yup.string().required("Title is required"),
            body: Yup.string().required("Message is required")
          })}
          initialValues={{
            title: "", 
            body: ""
          }}
          onSubmit={handleSendNewMessage}
        >
          {({values, handleSubmit, handleChange, touched, errors, isSubmitting}) => (
            <>
            <Modal.Body>
            <Form>
              <div
                style={{
                  display: "flex", 
                }}
              >
                <div>
                  To: 
                </div>
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #ccc",
                    padding: "0.5rem", 
                    marginLeft: "0.5rem"
                  }}
                >
                  <img 
                    src={showNewMessageForm.image}
                    style={{
                      height: "4rem",
                      borderRadius: "50%", 
                      width: "4rem",
                    }}
                  />
                  <div>
                    {showNewMessageForm.displayname}
                  </div>
                </div>
                
              </div>
                
              <Form.Group controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control name="title" type="text" placeholder="Enter title" 
                  onChange={handleChange}
                  value={values.title}
                  isInvalid={touched.title && errors.title}
                  isValid={touched.title && !errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="body">
                <Form.Label>Message</Form.Label>
                <Form.Control name="body" as="textarea" rows={3} 
                  onChange={handleChange}
                  value={values.body}
                  isInvalid={touched.body && errors.body}
                  isValid={touched.body && !errors.body}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.body}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={closeNewMessage}>Cancel</Button>
              <Button onClick={handleSubmit} disabled={isSubmitting}>Send</Button>
            </Modal.Footer>
            </>
          )}
          
        </Formik>
      
    </Modal>
  )
}