import { faUserXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Bars } from "react-loading-icons";
import { Link, useParams } from "react-router-dom";
import { GridList, getChunkArrayItemIfExist } from "../../components/lists/VerticalGrid";
import { useGetFriends } from "../../utils/api/functions/friends";

const UserXIcon = <FontAwesomeIcon icon={faUserXmark} />;

const GUTTER_SIZE = 10;

const FriendListItem = ({ style, data, columnIndex, rowIndex }) => {
  const item_data = getChunkArrayItemIfExist(data, rowIndex, columnIndex);
  const { id } = useParams();
  const isSelf = id == window.localStorage.getItem("user_id"); 

  if (item_data) {
    return (
      <div
        className="profile-friends-grid-item"
        style={{
          ...style,
          left: style.left + GUTTER_SIZE,
          top: style.top + GUTTER_SIZE,
          width: style.width - GUTTER_SIZE,
          height: style.height - GUTTER_SIZE,
        }}
      >
        <div className="item-img-container">
          <Link to={`/profile/${item_data.user_id}`} reloadDocument>
            <img src={item_data.image_icon} />
          </Link>
        </div>
        <div className="item-name-container">
          <Link to={`/profile/${item_data.user_id}`} reloadDocument>
            {item_data.displayname}
          </Link>
        </div>
        <div className="item-actions-container">
          {isSelf ? (<span>{UserXIcon} Remove Friend</span>) : (null)}
        </div>
      </div>
    );
  }
  return null;
};

const ProfileFriends = () => {
  const { id } = useParams();
  const [friends, setFriends] = useState([]);
  const { data, isLoading, mutate } = useGetFriends({
    user_id: id,
    limit: 50
  });

  useEffect(() => {
    if (data && data.body && data.body.friends) {
      setFriends((old) => [...data.body.friends]);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="profile-friends">
        <div className="loading-icon-wrapper">
          <Bars stroke="#00BCD4" fill="#00BCD4" />
        </div>
      </div>
    );
  }

  if (!isLoading && friends.length === 0) {
    return (
      <div className="profile-friends">
        <div className="loading-icon-wrapper">Nothing to see here yet...</div>
      </div>
    );
  }

  return (
    <div className="profile-friends">
      <GridList
        items={friends}
        colCount={1}
        CellEl={FriendListItem}
        autosizerClass=""
        gridClass=""
        rowHeight={100}
      />
    </div>
  );
};

export default ProfileFriends;
