import { useState } from "react";
//Dropdown menu component
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const DropdownMenu = (props) => {
  const [show, setShow] = useState(false);

  const showDropdown = () => {
    setShow(true);
  };
  const hideDropdown = () => {
    setShow(false);
  };

  const propClass = props.className;

  return (
    <NavDropdown
      title={
        props.mainLink ? (
          <Link to={props.mainLink}>
            {props.title} {props.icon}
          </Link>
        ) : (
          <span>
            {props.title} {props.icon}
          </span>
        )
      }
      className={propClass}
      show={show}
      onMouseEnter={showDropdown}
      onMouseLeave={hideDropdown}
    >
      {props.links.map((item) => {
        return (
          <NavDropdown.Item href={item["link"]}>
            {item["name"]}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
};

export default DropdownMenu;
