//Import api functions
import axios from "axios";
import { Cookies } from "react-cookie";
import oauthConfig from "../../oauth.config";
import { login, postForgotPassword } from "./functions/login";
import useTest from "./functions/test";

const CookiesClass = new Cookies();
//Javascript Native Fetch module wrapper
export const fetcher = (request_obj, multipart = true, json_req = false, onProgress=()=>{}) => {
  
  let url = request_obj[0];
  let params = request_obj[1];
  if(url.includes("feed")){
    console.log("FETCHER REQUEST: ", request_obj); 
  }

  //Fix for appending GET request body to url
  if (params.method === "GET") {
    if (params.body) {
      url = url + "?" + params.body;
    }
    delete params.body;
  }

  let cookies = CookiesClass.getAll();
  let oauth_token = cookies["oauth_token"];
  let oauth_secret = cookies["oauth_secret"];

  params.headers["oauth_token"] = oauth_token;
  params.headers["oauth_secret"] = oauth_secret;
  //@TODO: Manipulate query to include required oauth parameters
  if (params.method === "GET") {
    let preparsed_url = new URL(url);
    preparsed_url.searchParams.append(
      "oauth_consumer_key",
      params.headers["oauth_consumer_key"],
    );
    preparsed_url.searchParams.append(
      "oauth_consumer_secret",
      params.headers["oauth_consumer_secret"],
    );
    preparsed_url.searchParams.append(
      "oauth_token",
      params.headers["oauth_token"],
    );
    preparsed_url.searchParams.append(
      "oauth_secret",
      params.headers["oauth_secret"],
    );
    url = preparsed_url.toString();
  }

  if (
    (params.method === "POST" ||
      params.method === "PUT" ||
      params.method === "DELETE") &&
    multipart
  ) {
    delete params.headers["oauth_token"];
    delete params.headers["oauth_secret"];

    if (oauth_token) params.body.append("oauth_token", oauth_token);
    if (oauth_secret) params.body.append("oauth_secret", oauth_secret);

    // params.body.append('oauth_token', oauth_token);
    // params.body.append('oauth_secret', oauth_secret);
    params.body.append("oauth_consumer_key", oauthConfig.oauth_consumer_key);
    params.body.append(
      "oauth_consumer_secret",
      oauthConfig.oauth_consumer_secret,
    );
  } else if (
    (params.method === "POST" ||
      params.method === "PUT" ||
      params.method === "DELETE") &&
    !multipart
  ) {
    // params.headers['oauth_token'] = oauth_token;
    // params.headers['oauth_secret'] = oauth_secret;
  }
  if (json_req === true) {
    // params.headers['Content-Type'] = 'application/json';
    params.headers["Accept"] = "application/json";

    //Add Oauth parameters to url for JSON POST / PUT requests
    let preparsed_url = new URL(url);
    preparsed_url.searchParams.append(
      "oauth_consumer_key",
      params.headers["oauth_consumer_key"],
    );
    preparsed_url.searchParams.append(
      "oauth_consumer_secret",
      params.headers["oauth_consumer_secret"],
    );
    preparsed_url.searchParams.append(
      "oauth_token",
      params.headers["oauth_token"],
    );
    preparsed_url.searchParams.append(
      "oauth_secret",
      params.headers["oauth_secret"],
    );

    url = preparsed_url.toString();
    //params.body = JSON.stringify(params.body);
  }

  if(params.method === "POST") {
    return axios.post(url, params.body, {

      ...params,
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        onProgress(percent);
      },
    }).then((res) => res.data); 
  }

  return axios(url, params).then((res) => res.data); 
};

const API = {
  Test: {
    test: useTest,
  },
  Login: {
    login: login,
    postForgotPassword: postForgotPassword
  },
};

export default API;
