import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToast } from "rc-toastr";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Bars from "react-loading-icons/dist/esm/components/bars";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GridList, getChunkArrayItemIfExist } from "../../components/lists/VerticalGrid";
import VideoDisplay from "../../components/videos/VideoDisplay";
import { ReportModal } from "../../components/widgets/ReportModal";
import ShareUserContent from "../../components/widgets/ShareUserContent";
import VideoUploadButton from "../../components/widgets/VideoUpload";
import { deleteVideo, useBrowseVideos, useGetVideo } from "../../utils/api/functions/videos";


const GUTTER_SIZE = 10;


const DeleteVideoModal = ({ show, onHide, video_id }) => {

  const { id } = useParams();
  const {mutate} = useBrowseVideos({user_id: id}); 

  const { toast } = useToast();
  
  //Handle delete video function
  const handleDelete = async() => {
    const res = await deleteVideo({video_id: video_id}); 
    if(res.status_code === 204) {
      toast.success("Video deleted successfully"); 
      mutate(); 
      onHide(); 
    }
    else {
      toast.error("Error deleting video"); 
      onHide();
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        Delete Video
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this video?</p>
      </Modal.Body>
      <Modal.Footer style={{
        display: "flex",
        justifyContent: "space-between"
      }}>
        <Button onClick={onHide}>Cancel</Button>
        <Button
          onClick={handleDelete}
          variant="danger"
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const VideoItemOverlay = ({ item_data }) => {
  const [show, setShow] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showReport, setShowReport] = useState(false);
  let menuRef = useRef();

  const {id} = useParams();
  const isOwner = item_data.owner_id == localStorage.getItem("user_id"); 

  const handleToggle = () => {
    setShow(!show);
  };

  const handleHideShare = () => {
    setShowShare(false);
  };
  const handleShowShare = () => {
    setShowShare(true);
  };

  const handleShowReport = () => {
    setShowReport(true); 
  }
  const handleHideReport = () => {
    setShowReport(false); 
  }

  const handleDelete = async() => {
    const video_id = item_data.video_id; 

    // delete video
    const res = await deleteVideo({video_id: video_id}); 

  }
  
  useEffect(() => {
    let handler = (e) => {
      if(!menuRef.current.contains(e.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return() => {
      document.removeEventListener("mousedown", handler);
    }
  });

  return (
    <div className="video-item-overlay">
      <ReportModal 
        show={showReport}
        onHide={handleHideReport}
        type="video"
        id={item_data.video_id}
      />
      <div className="video-item-overlay-menubar">
        <div className="video-item-overlay-menu-icon">
          <FontAwesomeIcon icon={faBars} onClick={handleToggle} />
          <div className={`video-item-overlay-menu ${show ? "show" : ""}`} ref={menuRef}>
            { isOwner ? null : <div onClick={handleShowReport}>Report</div> }
            { isOwner ? <div>
              <a href={item_data.video_url} target="_blank">
                Download
              </a>
            </div> : null}
            {
              isOwner ? (
                <>
                  <div
                  onClick={() => setShowDelete(true)}
                  >
                    Delete
                  </div>
                  <DeleteVideoModal 
                      show={showDelete}
                      onHide={() => setShowDelete(false)}
                      video_id={item_data.video_id}
                  />
                </>
                
              ) : 
              (null)
            }
            <div onClick={handleShowShare}>Share</div>
          </div>
        </div>
      </div>
      <ShareUserContent
        show={showShare}
        onHide={handleHideShare}
        data={item_data}
        object_id={item_data.video_id}
      />
    </div>
  );
};

const VideoListItem = ({ style, data, columnIndex, rowIndex, handleShow}) => {
  const item_data = getChunkArrayItemIfExist(data, rowIndex, columnIndex);


  if (item_data) {
    return (
      <div
        className="profile-videos-grid-item"
        style={{
          ...style,
          left: style.left + GUTTER_SIZE,
          top: style.top + GUTTER_SIZE,
          width: style.width - GUTTER_SIZE,
          height: style.height - GUTTER_SIZE,
        }}
        
      >
        <VideoItemOverlay item_data={item_data} />
        <Link
          style={{
            width: style.width - GUTTER_SIZE,
            height: style.height - GUTTER_SIZE,
            color: "inherit",
            textDecoration: "none",
          }}
          to={String(item_data.video_id)}
          
        >
          <div className="item-img-container" onClick={()=>{console.log("TEST ONCLICK"); handleShow(item_data)}}>
            <img src={item_data.image} loading="lazy"/>
          </div>
          {/* <div className="item-desc-container">
            <strong>{item_data.title}</strong>

            <span>By {item_data.owner_title}</span>
            <span>{item_data.view_count} views</span>
          </div> */}
        </Link>
      </div>
    );
  }
  return null;
};

// Auto-load video from video_id url parameter if present

const QuickVideoModal = ({ video_id }) => {
  const { data, isLoading } = useGetVideo({ video_id: video_id });
  const [video, setVideo] = useState({});
  const [show, setShow] = useState(true);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setVideo(() => data.body.response);
    }
  }, [data]);

  // set show to true on video_id trigger
  useEffect(() => {
    setShow(true);
  }, [video_id]);

  const handleHide = async() => {
    // navigate back to videos route
    
    await navigate(`/profile/${id}/videos`, {
      state: {show: false}
    });
    setShow(()=>false);
    
  };

  return (
    <VideoDisplay
      show={show}
      onHide={handleHide}
      video_data={video}
      subject_type="video"
      subject_id={video.video_id}
      isLoading={isLoading}
    />
  );
};
const QuickVideo = () => {
  const { videoId } = useParams();
  if (!videoId) return null;

  return <QuickVideoModal video_id={videoId} />;
};

//End quick video component

const ProfileVideos = () => {  
  const { id } = useParams();
  const [videos, setVideos] = useState([]);
  const [video, setVideo] = useState({});
  const [show, setShow] = useState(false); 
  const { data, isLoading} = useBrowseVideos({
    user_id: id,
  });
  useEffect(() => {
    if (data && data.body && data.body.response) {
      setVideos(() => [...data.body.response]);
    }
  }, [data]);

  const handleShow = (video_data) => {
    console.log("HANDLE SHOW TRIGGERED")
    setVideo(video_data);
    setShow(true);
  }
  const handleHide = () => {
    setShow(false);
  }

  if (isLoading) {
    return (
      <div className="profile-videos">
        <div className="loading-icon-wrapper">
          <Bars stroke="#00BCD4" fill="#00BCD4" />
        </div>
      </div>
    );
  }

  // Render nothing to see here message if done loading and no videos exist
  if (!isLoading && videos.length === 0) {
    return (
      <div className="profile-videos">
        <VideoUploadButton />
        <div className="loading-icon-wrapper">Nothing to see here yet...</div>
      </div>
    );
  }

  return (
    <div className="profile-videos">
      <VideoUploadButton />
      <GridList
        items={videos}
        colCount={3}
        CellEl={(args) => {
          return <VideoListItem {...args} handleShow={handleShow}/>;
        }}
        autosizerClass=""
        gridClassName=""
        rowHeight={160}
      />
      {/* <VideoDisplay 
                show={show}
                onHide={handleHide}
                video_data={video}
                subject_type="video"
                subject_id={video.video_id}
            /> */}
      <QuickVideo />
    </div>
  );
};

export default ProfileVideos;
