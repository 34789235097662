import React from "react";
import { Button } from "react-bootstrap";

const Donate = () => {
  return (
    <div className="home-sidebar-item sidebar-donate">
      <p>
        To help support XAPiT and its future development, please click the
        button below to send your gift:
      </p>
      <Button variant="success">Donate</Button>
      <p>Gifts to XAPiT are not tax deductible, but are greatly appreciated.</p>
    </div>
  );
};

export default Donate;
