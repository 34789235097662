import React, { useState, useEffect } from "react";
import { useGetOwnersAds } from "../../utils/api/functions/communityAds";

import { Table, Form } from "react-bootstrap";

const CampaignRow = ({ campaign }) => {
  return (
    <tr className="campaign-row">
      <td>
        <Form.Check />
      </td>
      <td>{campaign.name}</td>
      <td>{campaign.ads}</td>
      <td>{campaign.views}</td>
      <td>{campaign.clicks}</td>
      <td>{campaign.CTR}</td>
      <td className="campaign-row-options">
        <span>Manage</span>|<span>Edit</span>|<span>Delete</span>
      </td>
    </tr>
  );
};

const AdCampaigns = () => {
  const { data } = useGetOwnersAds({});
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    if (data && data.body && data.body.campaigns) {
      setCampaigns(data.body.campaigns);
    }
  }, [data]);

  return (
    <div className="advertise-content-box">
      <div className="advertise-content-box-header">My Campaigns</div>
      <div className="box-content">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>
                <Form.Check />
              </th>
              <th>Campaign Name</th>
              <th>Ads</th>
              <th>Views</th>
              <th>Clicks</th>
              <th>CTR(%)</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {campaigns.map((val) => {
              return <CampaignRow campaign={val} />;
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AdCampaigns;
