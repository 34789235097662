import React from "react";
import { LoadingList } from "../../components/loading-placeholders/LoadingSidebarCards";

const InfoSection = ({ profile_data, isLoading = false }) => {
  if (isLoading)
    return (
      <div className="profile-sidebar-info">
        <LoadingList row_num={2} />
      </div>
    );

  const first_name = profile_data.displayname
    ? profile_data.displayname.split(" ")[0]
    : "";
  const last_name = profile_data.displayname
    ? profile_data.displayname.split(" ")[1]
    : "";
  const city = profile_data.city ? profile_data.city : false;
  const state_province_territory = profile_data.state_province_territory
    ? profile_data.state_province_territory
    : false;
  const country = profile_data.country ? profile_data.country : false;

  return (
    <div className="profile-sidebar-info">
      {first_name ? (
        <p>
          <strong>First Name: </strong>
          {first_name}
        </p>
      ) : null}

      {last_name ? (
        <p>
          <strong>Last Name: </strong>
          {last_name}
        </p>
      ) : null}

      {city ? (
        <p>
          <strong>City: </strong>
          {city}
        </p>
      ) : null}

      {state_province_territory ? (
        <p>
          <strong>Location: </strong>
          {state_province_territory}
        </p>
      ) : null}

      {country ? (
        <p>
          <strong>Country: </strong>
          {country}
        </p>
      ) : null}
    </div>
  );
};
const StatsSection = ({ profile_data, isLoading = false }) => {
  if (isLoading)
    return (
      <div className="profile-sidebar-stats">
        <LoadingList row_num={5} />
      </div>
    );

  return (
    <div className="profile-sidebar-stats">
      <p>
        <strong>Profile Type: </strong>
        {profile_data.member_type}
      </p>
      <p>
        <strong>Member Since: </strong>
        {profile_data.creation_date
          ? new Date(profile_data.creation_date).toLocaleDateString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })
        : ""}{" "}
      </p>
      <p>
        <strong>Profile Views: </strong>
        {profile_data.view_count}
      </p>
      <p>
        <strong>Friends: </strong>
        {profile_data.friend_count}
      </p>
      <p>
        <strong>Last Update: </strong>
        {profile_data.status_date
          ? new Date(profile_data.status_date).toLocaleDateString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })
        : ""}{" "}
      </p>
    </div>
  );
};

const SidebarItem = ({ component }) => {
  return <div className="sidebar-item">{component}</div>;
};

const ProfileSidebar = ({ profile_data, isLoading = false }) => {
  return (
    <div className="profile-sidebar">
      <SidebarItem
        component={
          <InfoSection profile_data={profile_data} isLoading={isLoading} />
        }
      />

      <SidebarItem
        component={
          <StatsSection profile_data={profile_data} isLoading={isLoading} />
        }
      />
    </div>
  );
};

export default ProfileSidebar;
