import React from "react";
import ContentLoader from "react-content-loader";

export const LoadingCircleImage = (props) => {
  return (
    <ContentLoader
      speed={3}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 40 40"
      backgroundColor="white"
      foregroundColor="#e6e3e3"
      {...props}
    >
      <circle cx="20" cy="20" r="20" />
    </ContentLoader>
  );
};
