import * as Yup from 'yup';


// Validate Password - Requirements: 1 uppercase, 1 special character, 6 characters long
export const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;"'<>,.?/~`-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;"'<>,.?/~`-]{6,}$/; 
    return regex.test(password); 
}
export const yupPasswordValidation = Yup.string()
    .required("Required")
    .min(6, "Password must be at least 6 characters")
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[!@#$%^&*()_+{}\[\]:;"'<>,.?/~`-]/, 'Password must contain at least one special character'); 