import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { TailSpin } from "react-loading-icons";
import { Link } from "react-router-dom";
import { putEditNotificationSettings, useGetNotificationSettings } from "../../../utils/api/functions/memberSettings";
import _notificationTypes from "./_notificationsTypes";

const TypeSwitch = ({category, enabled, handleEnable, handleDisable}) => {

  console.log(`ENABLED ${category}: `, enabled);

  const handleSwitch = (e) => {
    console.log("Switch: ", e.target.checked); 
    if(e.target.checked){
      handleEnable(); 
    }
    else{
      handleDisable(); 
    }
  }

  return(
    <Form.Group>
      <Form.Label>
        {category} {
        enabled === "partial" ? (
          <span
            style={{
              color: 'red', 
              fontSize: '0.6em'
            }}
          >Only some of these settings are enabled. To see more, visit <Link to="/profile/settings/advancednotifications">advanced notifications</Link>, or <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={handleEnable}>enable all</span></span>
        ) : null 
      }
      </Form.Label>
      <Form.Switch checked={enabled ? true : false} onChange={handleSwitch}/> 
    </Form.Group>
  )
}

// Check if all settings for a category exist in the values array
// If none of the settings exist, return false
// If some of the settings exist, return "partial"
const checkEnabled = (settings, values) => {
  const valuesSet = new Set(values); 

  let partial = false; 
  let all = true; 
  for(const val of settings) {
    if(valuesSet.has(val)) {
      partial = true; 
    }
    else {
      all = false; 
    }
  }
  if(all){
    return true; 
  }
  else if (partial){
    return "partial"; 
  }
  else {
    return false; 
  }
}


const Notifications = () => {

  const {data} = useGetNotificationSettings(); 

  const [submitting, setSubmitting] = useState(false); 
  const [formData, setFormData] = useState([]); 

  const {toast} = useToast(); 

  useEffect(() => {
    console.log("NOTIFICATION SETTINGS: ", data); 
    if(data && data?.body?.formValues){
      setFormData(data.body.formValues); 
    }
  }, [data]); 


  const handleEnable = (category) => {
    console.log("Enable: ", category); 
    // Find the category in the _notificationTypes array
    const categoryObj = _notificationTypes.find(val => val.category === category);
    // Add values from socialengine_settings to formData, ignore duplicates
    const newFormData = [...formData]; 
    for(const val of categoryObj.socialengine_settings){
      if(!newFormData.includes(val)){
        newFormData.push(val); 
      }
    }
    setFormData(newFormData); 
  }

  const handleDisable = (category) => {
    console.log("Disable: ", category); 
    // Find the category in the _notificationTypes array
    const categoryObj = _notificationTypes.find(val => val.category === category);

    // Remove all occurences of values from socialengine_settings in formData
    const newFormData = formData.filter(val => !categoryObj.socialengine_settings.includes(val)); 

    setFormData(newFormData); 
  }

  const _handleSubmit = async(e) => {
    setSubmitting(true); 
    e.preventDefault(); 
    console.log("Submit: ", formData); 

    // const result = await putEditNotificationSettings(formData); 
    let req_values = {}; 
    formData.map((val, i) => {
      req_values[val] = 1; 
    })
    const result = await putEditNotificationSettings(req_values); 
    if(result?.status_code === 204) {
      toast.success("Settings saved!"); 
    }
    else {
      toast.error("Error saving settings");
    }
    setSubmitting(false); 
  }

  return (
    <div className="profile-settings-form-container">
      <Form
        onSubmit={_handleSubmit}
      >
        {
          _notificationTypes.map((val, i) => {
            console.log("Value:", val)
            return(
              <TypeSwitch
                category={val.category}
                enabled={checkEnabled(val.socialengine_settings, formData)}
                handleEnable={() => handleEnable(val.category)}
                handleDisable={() => handleDisable(val.category)}
              />
            )
          })
        }

        <Form.Group className="mb-3"
          style={{
            marginTop: "15px"
          }}
        >
          <Button type="submit" disabled={submitting}>
            {
              submitting ? (
                <TailSpin
                  stroke="#fff"
                  speed={1}
                  width="20px"
                  height="20px"
                />
              ) : "Save Changes"  
            }
          </Button>
        </Form.Group>
      </Form>
      <Link to="/profile/settings/advancednotifications">Advanced Settings</Link>
    </div>
  );
};

export default Notifications;
