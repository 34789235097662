import React, { useEffect, useRef, useState } from "react";

const filter_types = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Friends",
    value: "membership",
  },
  {
    name: "Photos",
    value: "photo",
  },
  {
    name: "Posts",
    value: "posts",
  },
  {
    name: "Videos",
    value: "video",
  },
  {
    name: "Pages",
    value: "sitepage",
  },
  {
    name: "Hidden Posts",
    value: "hidden_post",
  },
  {
    name: "Scheduled Posts", 
    value: "schedule_post"
  },
  {
    name: "Liked Posts", 
    value: "liked_posts"
  }
];

const FilterButton = ({ text, handleChange, feedParams }, props) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if(!menuRef.current.contains(e.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return() => {
      document.removeEventListener("mousedown", handler);
    }
  });

  return (
    <button className="posts-filter-btn" {...props} onClick={handleClick} ref={menuRef}>
      {text}
      <div className={`posts-filter-list ${show ? "show-list" : ""}`}>
        {filter_types.map((val, index) => {
          return (
            <div
              className={`posts-filter-list-item ${
                feedParams.filter_type === val.value ? "active-filter" : ""
              }`}
              onClick={() => {
                handleChange(val.value);
              }}
              key={index}
            >
              {val.name}
            </div>
          );
        })}
      </div>
    </button>
  );
};

export default FilterButton;
