//Convert HSV color model to RGB color model
//Source: https://axonflux.com/handy-rgb-to-hsl-and-rgb-to-hsv-color-model-c
//Parameters required to be in a set of [0,1] and returns RGB values in a set of [0,255]
function HSVtoRGB(h, s, v) {
  let r, g, b, i, f, p, q, t;
  if (arguments.length === 1) {
    s = h.s;
    v = h.v;
    h = h.h;
  }
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
  }
  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}

//Algorithm to evenly distribute color based on array index. Uses HSV color model to evenly distribute distinct colors
//Modified to a range of 0 to 340 degrees to prevent repeating reds
export const colorArrayGen = (len) => {
  let rgb_array = [];
  for (let i = 0; i < len; i++) {
    const _hsv = { h: (i * 340) / len / 100, s: 0.75, v: 0.8 };
    rgb_array.push(HSVtoRGB(_hsv));
  }
  return rgb_array;
};
