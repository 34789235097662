import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faLock,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PostPrivacyIcon = ({ privacy_type }) => {
  if (privacy_type === "everyone") {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="tooltip">Public</Tooltip>}
      >
        <span>
          <FontAwesomeIcon icon={faGlobe} />
        </span>
      </OverlayTrigger>
    );
  }
  if (privacy_type === "onlyme") {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="tooltip">Only me</Tooltip>}
      >
        <span>
          <FontAwesomeIcon icon={faLock} />
        </span>
      </OverlayTrigger>
    );
  }
  if (privacy_type === "friends") {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="tooltip">Friends</Tooltip>}
      >
        <span>
          <FontAwesomeIcon icon={faUserGroup} />
        </span>
      </OverlayTrigger>
    );
  }
  return null;
};

export default PostPrivacyIcon;
