import React, { createContext, useEffect, useState } from "react";
import { usePaginateAdvancedActivityFeeds } from "../api/functions/advancedActivityFeeds";

// React Context for managing data of different feeds

const default_feed_params = {
  limit: 20,
};

const FeedContext = createContext();

const FeedProvider = ({ feed_params = default_feed_params, children }) => {
  const { data, isLoading, mutate, size, setSize } =
    usePaginateAdvancedActivityFeeds(feed_params);
  const [postsData, setPostsData] = useState();
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    const parseData = () => {
      if (data) {
        let fetched_posts = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].status_code === 200) {
            //Sort comment previews into post data
            let parsed_actions = [];
            let comment_preview = data[i].body.comment_preview;
            if (data[i]?.body?.data?.length > 0) {
              parsed_actions = data[i].body.data.map((val) => {
                let current_action_id = val.feed.action_id;
                let comment_data = {};
                comment_preview.map((val) => {
                  if (val.action_id === current_action_id) {
                    comment_data = val;
                  }
                });

                val.comment_data = comment_data;
                return val;
              });
            }

            fetched_posts.push(...parsed_actions);
          }
        }

        
        if (fetched_posts.length === 0) return;

        setPostsData({
          posts: fetched_posts,
        });
      }
    };
    parseData();
  }, [data]);

  return (
    <FeedContext.Provider
      value={{
        postsData,
        isLoading,
        isLoadingMore,
        setIsLoadingMore,
        feed_params,
        mutate,
        size,
        setSize,
        data,
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};

export { FeedContext, FeedProvider };

