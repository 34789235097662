import React from "react";
import { useParams } from "react-router-dom";
import PostsFeed from "../../../components/posts/Feedv2";
import CreatePost from "../../../components/posts/CreatePost";

const GroupPosts = () => {
  const { id } = useParams();

  return (
    <div className="group-posts group-view-content">
      <PostsFeed
        feed_params={{
          subject_type: "group",
          subject_id: id,
        }}
        CreatePostComponent={CreatePost}
      />
    </div>
  );
};

export default GroupPosts;
