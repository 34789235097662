import useSWR from "swr";
import { fetcher } from "../handler";
import queryString from "query-string";
import oauth_config from "../../../oauth.config";

//Sends Login post request
export default function useTest(email, password) {
  return fetcher("https://xapit.com/api/rest/login", {
    method: "POST",
    body: queryString.stringify({
      email: email,
      password: password,
      ip: "127.0.0.1",
      oauth_consumer_secret: oauth_config.oauth_consumer_secret,
      oauth_consumer_key: oauth_config.oauth_consumer_key,
    }),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      oauth_consumer_secret: oauth_config.oauth_consumer_secret,
      oauth_consumer_key: oauth_config.oauth_consumer_key,
    },
  });
}
