import React from "react";
import ContentLoader from "react-content-loader";

const convertRemToPx = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const LoadingList = ({ row_num, ...props }) => {
  const config = {
    rect: {
      x: 0,
      y: 0,
      rx: 3,
      ry: 3,
      width: "100%",
      height: 0.75, // rem value = 1rem
    },
    viewBox: {
      minWidth: 0,
      minHeight: 0,
      width: "100%",
      height: 1, //rem value = 1rem
    },
    rect_spacing: 1,
  };

  //Calculate viewbox sizing
  const vb_height = convertRemToPx(
    config.rect.height * row_num + (config.rect_spacing * row_num - 1),
  );

  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={""}
      viewBox={`0 0 100 ${vb_height}`}
      {...props}
    >
      {[...Array(row_num)].map((val, i) => (
        <rect
          x={config.rect.x}
          y={`${
            config.rect.y + config.rect.height * i + config.rect_spacing * i
          }rem`}
          rx={config.rect.rx}
          ry={config.rect.ry}
          width={config.rect.width}
          height={`${config.rect.height}rem`}
        />
      ))}
    </ContentLoader>
  );
};

// width scale 136 -> 100
// height scale 40 -> 30

export const LoadingListWithImage = ({ row_num, ...props }) => {
  const config = {
    rect: {
      x: 35,
      y: 5,
      rx: 3,
      ry: 3,
      width: 60,
      height: 5,
    },
    circle: {
      cx: 15,
      cy: 15,
      r: 15,
    },
    viewBox: {
      minWidth: 0,
      minHeight: 0,
      width: 100,
      height: 30,
    },
    rect_spacing: 15,
  };

  //Calculate viewbox sizing
  const vb_height = config.viewBox.height * row_num;

  return (
    <ContentLoader
      speed={2}
      width={"90%"}
      height={""}
      viewBox={`0 0 100 ${vb_height}`}
      {...props}
    >
      {/* <rect x="35" y="20" rx="3" ry="3" width="60" height="5" /> 
            <circle cx="15" cy="15" r="15" /> 
            <rect x="35" y="5" rx="3" ry="3" width="60" height="5" /> */}
      {[...Array(row_num)].map((val, i) => {
        return (
          <div key={i}>
            <rect
              x={config.rect.x}
              y={
                config.viewBox.height / 2 -
                config.rect.height * 2 +
                config.viewBox.height * i
              }
              rx={config.rect.rx}
              ry={config.rect.ry}
              width={config.rect.width}
              height={config.rect.height}
            />
            <circle
              cx={config.circle.cx}
              cy={config.circle.cy * 2 * i + config.circle.cy}
              r={config.circle.r}
            />
            <rect
              x={config.rect.x}
              y={
                config.viewBox.height / 2 +
                config.rect.height +
                config.viewBox.height * i
              }
              rx={config.rect.rx}
              ry={config.rect.ry}
              width={config.rect.width}
              height={config.rect.height}
            />
          </div>
        );
      })}
    </ContentLoader>
  );
};
