import { Formik } from "formik";
import { useToast } from "rc-toastr";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { postCreatePage } from "../../utils/api/functions/sitepages";

const CreatePageSchema = Yup.object().shape({
  title: Yup.string().required("Page title is required"),
  location: Yup.string().required("Page location is required"),
  category_id: Yup.number().required("Page category is required"),
  auth_view: Yup.string().required("Page view permission is required"),
  auth_comment: Yup.string().required("Page comment permission is required"),
  body: Yup.string().required("Page description is required"),
  auth_invite: Yup.string().required("Page invite permission is required"),
  auth_event: Yup.string().required("Page event permission is required"),
  auth_photo: Yup.string().required("Page photo permission is required"),
});
const CreatePageInitialValues = {
  title: "",
  location: "",
  category_id: "",
  photo: "",
  price: "",
  auth_view: "",
  auth_comment: "",
  auth_invite: "",
  splcreate: "",
  sspcreate: "",
  auth_event: "",
  smcreate: "",
  sdicreate: "",
  auth_photo: "",
  sdcreate: "",
  search: 1,
  draft: 1,
};


const PrimaryFieldsPage = ({ values, handleChange, touched, errors }) => {
  return (
    <Col
      style={{
        borderBottom: "1px solid #ccc",
        paddingBottom: "1rem",
      }}
    >
      <Row
        style={{
          display: "block",
        }}
      >
        <Form.Group as={Col}>
          <Form.Label>Page Name</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={values.title}
            onChange={handleChange}
            isValid={touched.title && !errors.title}
            isInvalid={touched.title && errors.title}
          />
          <Form.Control.Feedback type="invalid">
            {errors.title}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Page Category</Form.Label>
          <Form.Control
            as="select"
            name="category_id"
            value={values.category_id}
            onChange={handleChange}
            isValid={touched.category_id && !errors.category_id}
            isInvalid={touched.category_id && errors.category_id}
          >
            <option value="">Select a category</option>
            <option value={1}>Animals</option>
            <option value={2}>Business & Finance</option>
            <option value={3}>Computers and Internet</option>
            <option value={4}>Cultures & Community</option>
            <option value={5}>Dating & Relationships</option>
            <option value={6}>Entertainment & Arts</option>
            <option value={7}>Family & Home</option>
            <option value={8}>Games</option>
            <option value={9}>Government & Politics</option>
            <option value={10}>Health & Wellness</option>
            <option value={11}>Hobbies & Crafts</option>
            <option value={12}>Music</option>
            <option value={13}>Recreation & Sports</option>
            <option value={14}>Regional</option>
            <option value={15}>Religion & Beliefs</option>
            <option value={16}>Schools & Education</option>
            <option value={17}>Science</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.category_id}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Page Location</Form.Label>
          <Form.Control
            type="text"
            name="location"
            value={values.location}
            onChange={handleChange}
            isValid={touched.location && !errors.location}
            isInvalid={touched.location && errors.location}
          />
          <Form.Control.Feedback type="invalid">
            {errors.location}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Page Image</Form.Label>
          <Form.Control type="file" name="photo" onChange={handleChange} />
        </Form.Group>
      </Row>
      <Form.Group>
        <Form.Label>Page Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="body"
          value={values.body}
          onChange={handleChange}
          isValid={touched.body && !errors.body}
          isInvalid={touched.body && errors.body}
        />
        <Form.Control.Feedback type="invalid">
          {errors.body}
        </Form.Control.Feedback>
      </Form.Group>
    </Col>
  );
};

const CreatePage = () => {
  const navigate = useNavigate();
  const { toast } = useToast();

  return (
    <div className="pages-create-container">
      <h5>Create A Page</h5>
      <Formik
        enableReinitialize={true}
        validationSchema={CreatePageSchema}
        initialValues={CreatePageInitialValues}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          
          const form_data = new FormData();

          for (let value in values) {
            form_data.append(value, values[value]);
          }

          const res = await postCreatePage(form_data);
          if (res.status_code === 200) {
            toast.success("Page created successfully");
            navigate(`/pages/page/${res.body.page_id}`);
          } else {
            toast.error("Error creating Page");
          }
          setSubmitting(false);
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          touched,
          errors,
          isSubmitting
        }) => (
          <Form
            novalidate
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <p
              style={{
                padding: 0,
                marginTop: "0.5rem",
                marginBottom: 0,
                textAlign: "center",
                fontSize: "1.2rem",
              }}
            >
              Basic Info
            </p>
            <PrimaryFieldsPage
              values={values}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
            />

            <p
              style={{
                padding: 0,
                marginTop: "0.5rem",
                marginBottom: 0,
                textAlign: "center",
                fontSize: "1.2rem",
              }}
            >
              Privacy Settings
            </p>

            <Form.Group>
              <Form.Label>Page View Permission</Form.Label>
              <Form.Control
                as="select"
                name="auth_view"
                value={values.auth_view}
                onChange={handleChange}
              >
                <option value="">Select a permission</option>
                <option value="everyone">Everyone</option>
                <option value="members">Page Members Only</option>
              </Form.Control>
            </Form.Group>
            <Row
              style={{
                display: "block",
                borderBottom: "1px solid #ccc",
                paddingBottom: "0.5rem",
              }}
            >
              <Col>
                <Form.Group>
                  <Form.Label>Comment Permissions</Form.Label>
                  <Form.Control
                    as="select"
                    name="auth_comment"
                    value={values.auth_comment}
                    onChange={handleChange}
                    isValid={touched.auth_comment && !errors.auth_comment}
                    isInvalid={touched.auth_comment && errors.auth_comment}
                  >
                    <option value="">Select a permission</option>
                    <option value="everyone">Everyone</option>
                    <option value="members">Page Members Only</option>
                    <option>Owners and Officers Only</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.auth_comment}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Photo Upload Permission</Form.Label>
                  <Form.Control
                    as="select"
                    name="auth_photo"
                    value={values.spcreate}
                    onChange={handleChange}
                  >
                    <option value="">Select a permission</option>
                    <option value="everyone">Everyone</option>
                    <option value="members">Page Members Only</option>
                    <option>Owners and Officers Only</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Event Creation Permission</Form.Label>
                  <Form.Control
                    as="select"
                    name="auth_event"
                    value={values.secreate}
                    onChange={handleChange}
                  >
                    <option value="">Select a permission</option>
                    <option value="everyone">Everyone</option>
                    <option value="members">Page Members Only</option>
                    <option>Owners and Officers Only</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Page Invite Permission</Form.Label>
                  <Form.Control
                    as="select"
                    name="auth_invite"
                    value={values.auth_invite}
                    onChange={handleChange}
                  >
                    <option value="">Select a permission</option>
                    <option value="everyone">Everyone</option>
                    <option value="members">Page Members Only</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group style={{display: "flex", justifyContent: "space-between"}}>
              <Button className="Pages-create-form-submit" variant="danger" onClick={()=>{navigate(-1)}}>
                Back
              </Button>
              <Button
                variant="primary"
                className="Pages-create-form-submit"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                {" "}
                Create Page{" "}
              </Button>
            </Form.Group>
            
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreatePage;
