import {
  faComment,
  faShare,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import {
  likeFeedOrComment,
  unlikeFeedOrComment,
  usePaginateAdvancedActivityFeeds
} from "../../utils/api/functions/advancedActivityFeeds";
//Feelings / Activities ID mapping
import feelingsConfig from "./postAttachments/activitiesConfig";

// Import comments component
import { Comments, CreateComment } from "./Comment";

// Virtualized list imports
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { ref } from "yup";

//Loading icon
import { Bars, TailSpin } from "react-loading-icons";

import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

const AttachmentCarousel = ({ attachments }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      interval={null}
      variant="dark"
    >
      {attachments.map((item, index) => {
        return (
          <Carousel.Item key={index} style={{}}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PostItemAttachment
                attachment={item}
                key={`post-item-attachment-${index}`}
              />
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

const PostItemAttachment = ({ attachment }) => {
  if (attachment?.attachment_type?.includes("photo")) {
    return (
      <img
        src={attachment.image_main.src}
        className="post-item-attachment-image"
      ></img>
    );
  }
  if (attachment?.attachment_type?.includes("video")) {
    return (
      <div className="post-item-attachment-video">
        <video autoPlay={false} controls>
          <source src={attachment.attachment_video_url} />
        </video>
      </div>
    );
  } else {
    return null;
  }
};


export const PostFooter = ({
  is_like,
  action_id,
  handles,
  handleCommentToggle
}) => {
  const { handleLike, handleUnlike } = handles;

  return (
    <div className="row post-item-footer">
      <div
        className={is_like ? "color-blue" : ""}
        onClick={
          is_like
            ? () => {
                handleUnlike(action_id);
              }
            : () => {
                handleLike(action_id);
              }
        }
      >
        <FontAwesomeIcon icon={faThumbsUp} /> Like
      </div>
      <div onClick={handleCommentToggle}>
        <FontAwesomeIcon icon={faComment} /> Comment
      </div>
      <div>
        <FontAwesomeIcon icon={faShare} /> Share
      </div>
    </div>
  );
};

const PostItem = React.forwardRef(
  ({ postData, handles }, ref) => {
    const [showCreateComment, toggleComment] = useState(false);

    //Parse Date into more readable format
    let posted_date = new Date(postData.feed.modified_date);
    posted_date = posted_date.toLocaleString("default", {
      month: "short",
      day: "numeric",
    });

    let attachment_exists = postData.feed.attachment_count > 0;

    let tagged_friends_text = "";

    if (postData.feed.tags && postData.feed.tags.length > 0) {
      let display_names = postData.feed.tags.map((item) => {
        return item.tag_obj.displayname;
      });

      if (display_names.length === 1) {
        tagged_friends_text = display_names[0];
      } else if (display_names.length === 2) {
        tagged_friends_text = display_names[0] + " and " + display_names[1];
      } else {
        tagged_friends_text = "with " + display_names.join(", ");
      }
    }

    let activityText = "";

    if (postData.feed.params.feelings) {
      let parentId = postData.feed.params.feelings.parent;
      let childId = postData.feed.params.feelings.child;
      let parentText = postData.feed.params.feelings.parenttitle;
      let childText = "";

      for (let i = 0; i < feelingsConfig.child[String(parentId)].length; i++) {
        let curItem = feelingsConfig.child[String(parentId)][i];
        if (curItem.id === childId) childText = curItem.title;
        else continue;
      }

      activityText = `is ${parentText} ${childText}`;
    }

    const handleCommentToggle = () => {
      toggleComment(!showCreateComment);
    };

    return (
      <div className="post-item-container" ref={ref}>
        <div className="post-item col">
          <div className="post-item-header row">
            <div className="col-1">
              <Link
                to={`/profile/${postData.feed.subjectIformation.subject_id}`}
              >
                <img
                  src={postData.feed.feed_icon}
                  className="post-item-profile-pic"
                ></img>
              </Link>
            </div>
            <div className="col-8 post-item-info">
              <h5 className="post-item-display-name">
                <Link
                  to={`/profile/${postData.feed.subjectIformation.subject_id}`}
                >
                  {postData.feed.subjectIformation.displayname}
                </Link>
                {tagged_friends_text || activityText ? (
                  <span className="post-item-sub-text">
                    {" "}
                    {activityText} {tagged_friends_text}
                  </span>
                ) : null}
              </h5>
              <p className="post-item-posted-date">
                <Link to={`/posts/${postData.feed.action_id}`}>
                  {posted_date}
                </Link>
              </p>
            </div>
            <div className="col-1 post-item-more-btn-container">
              <span>...</span>
            </div>
          </div>
          <div className="post-body-container">{postData.feed.body}</div>
          {attachment_exists ? (
            // <div>
            //     {postData.feed.attachment.map((item, index) => {
            //         return(
            //             <PostItemAttachment attachment={item} key={`${key}-attachment-${index}`}/>
            //         )
            //     })}
            // </div>
            postData.feed.attachment.length > 1 ? (
              <AttachmentCarousel attachments={postData.feed.attachment} />
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PostItemAttachment attachment={postData.feed.attachment[0]} />
              </div>
            )
          ) : null}
          <PostFooter
            is_like={postData.is_like}
            action_id={postData.feed.action_id}
            action_type={postData.feed.type}
            handles={handles}
            handleCommentToggle={handleCommentToggle}
          />
        </div>
        <div className="comment-container">
          {showCreateComment ? (
            <CreateComment action_id={postData.feed.action_id} />
          ) : null}
          <Comments action_id={postData.feed.action_id} />
        </div>
      </div>
    );
  },
);

const VirtualizedFeedList = ({
  postData,
  handles,
  loadMoreItems,
  isLoadingMore,
}) => {
  const listRef = useRef({});
  const rowHeights = useRef({});
  const infiniteRef = useRef({});

  const getRowHeight = (index) => {
    return rowHeights.current[index] + 8 || 80;
  };

  const setRowHeight = (index, size) => {
    rowHeights.current = { ...rowHeights.current, [index]: size };

    listRef.current.resetAfterIndex(0);
  };

  const Row = ({ index, style }) => {
    const rowRef = useRef({});

    useEffect(() => {
      if (rowRef.current !== null) {
        const observer = new ResizeObserver(() => {
          if (rowRef.current === null) return;
          setRowHeight(index, rowRef.current.clientHeight);
        });

        observer.observe(rowRef.current);
        return () => ref.current && observer.unobserve(rowRef.current);
      }
    }, []);

    // useEffect(() => {
    //     if(rowRef.current){
    //         setRowHeight(index, rowRef.current.clientHeight);
    //     }
    // }, [rowRef])

    let postElement;

    if (!_isItemLoaded(index)) {
      postElement = (
        <div className="post-item-container-loading" ref={rowRef}>
          <TailSpin stroke="#00BCD4" fill="#00BCD4" />
        </div>
      );
    } else {
      postElement = (
        <PostItem
          ref={rowRef}
          postData={postData[index]}
          key={`post-item-${index}`}
          handles={handles}
          style={style}
        />
      );
    }

    return (
      <div style={{ ...style }} key={"post-item-container-" + index}>
        {postElement}
      </div>
    );
  };

  const _itemCount = postData.length + 1;
  const _isItemLoaded = (index) => index < postData.length;

  // loadMoreItems wrapper to debounce parallel requests
  const _loadMoreItems = isLoadingMore ? () => {} : loadMoreItems;

  useEffect(() => {
    if (postData.posts && postData.length > 0) {
    }
  }, []);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          ref={infiniteRef}
          isItemLoaded={_isItemLoaded}
          itemCount={_itemCount}
          loadMoreItems={_loadMoreItems}
          threshold={20}
        >
          {({ onItemsRendered, ref }) => (
            <List
              className="posts-list virtual-list-no-scrollbars"
              height={height}
              width={width}
              itemCount={_itemCount}
              itemSize={getRowHeight}
              onItemsRendered={onItemsRendered}
              ref={(el) => {
                ref(el);
                listRef.current = el;
              }}
            >
              {Row}
            </List>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

const PostsFeed = ({
  feed_params = {},
  CreatePostComponent = null
}) => {
  //const {data, mutate, isLoading} = useGetAdvancedActivityFeeds(feed_params);

  //Default Feed Params
  if (!feed_params?.limit) {
    feed_params.limit = 20;
  }

  feed_params.feed_filter = 1;

  const { data, isLoading, mutate, size, setSize } =
    usePaginateAdvancedActivityFeeds(feed_params);

  const [postsData, setPostsData] = useState();

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  //Set CreatePostComponent
  const CreatePostEl = () => {
    if (CreatePostComponent) {
      return <CreatePostComponent callMutate={mutate} />;
    }
    return null;
  };

  const likeFn = async (action_id, updated_object) => {
    let postData = {
      action_id: action_id,
    };
    let formData = new FormData();
    for (let key in postData) {
      formData.append(key, postData[key]);
    }
    let res = await likeFeedOrComment(formData);
    if (res.status_code == 204 || res.status_code == 200) {
      return updated_object;
    } else {
      throw new Error("Invalid API Response");
    }
  };
  const unlikeFn = async (action_id, updated_object) => {
    let postData = {
      action_id: action_id,
    };
    let formData = new FormData();
    for (let key in postData) {
      formData.append(key, postData[key]);
    }
    let res = await unlikeFeedOrComment(formData);
    if (res.status_code == 204 || res.status_code == 200) {
      return updated_object;
    } else {
      throw new Error("Invalid API Response");
    }
  };

  const handleLike = async (action_id) => {
    //Mutate existing data for update
    let updateObject = new Object({ ...data });

    for (let i = 0; i < updateObject.body.data.length; i++) {
      let item = updateObject.body.data[i];
      if (item.feed.action_id == action_id) {
        item.is_like = 1;
        break;
      }
    }
    await mutate(likeFn(action_id, updateObject), {
      optimisticData: {
        ...updateObject,
        body: { ...updateObject.body, ...updateObject.body.data },
      },
      rollbackOnError: true,
      populateCache: true,
      revalidate: false,
    });
  };
  const handleUnlike = async (action_id) => {
    //Mutate existing data for update
    let updateObject = new Object({ ...data });

    for (let i = 0; i < updateObject.body.data.length; i++) {
      let item = updateObject.body.data[i];
      if (item.feed.action_id == action_id) {
        item.is_like = 0;
        break;
      }
    }
    await mutate(unlikeFn(action_id, updateObject), {
      optimisticData: {
        ...updateObject,
        body: { ...updateObject.body, ...updateObject.body.data },
      },
      populateCache: true,
      rollbackOnError: true,
      revalidate: false,
    });
  };

  const postHandles = {
    handleLike: handleLike,
    handleUnlike: handleUnlike,
  };

  const _loadMoreItemsA = async () => {
    if (isLoading) return;
    setIsLoadingMore(true);
    await setSize(size + 1);
    setIsLoadingMore(false);
  };

  useEffect(() => {
    const parseData = () => {
      if (data) {
        let fetched_posts = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].status_code === 200 && data[i].body.data.length > 0) {
            fetched_posts.push(...data[i].body.data);
          }
        }


        if (fetched_posts.length === 0) {
          return;
        }
        setPostsData({
          posts: fetched_posts,
        });
      }
    };
    parseData();
  }, [data]);

  if (!postsData) {
    return (
      <>
        <CreatePostEl />

        <div className="posts-feed col">
          <div className="posts-loading-icon-wrapper">
            <Bars stroke="#00BCD4" fill="#00BCD4" />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <CreatePostEl />
      <div className="posts-feed col">
        <VirtualizedFeedList
          postData={postsData.posts}
          handles={postHandles}
          loadMoreItems={_loadMoreItemsA}
          isLoadingMore={isLoadingMore}
        />
      </div>
    </>
  );
};

export default PostsFeed;
