import React from "react";
import { useEffect } from "react";
import { useGetSitePageReviews } from "../../../utils/api/functions/sitepages";

export const PageReviews = ({ page_id }) => {
  const { data } = useGetSitePageReviews(page_id, {});

  useEffect(() => {}, [data]);

  return (
    <div className="sitepage-reviews-wrapper">
      <div>
        <h6>
          <strong>Write a Review</strong>
        </h6>
      </div>
      <div className="sitepage-reviews-list"></div>
    </div>
  );
};
