import React, { useEffect, useState } from "react";
import {
  faFaceSmile,
  faImage,
  faLink,
  faNoteSticky,
  faUserPlus,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { useSuggestFriends } from "../../../utils/api/functions/friends";
import FeelingsActivitiesMenu from "./FeelingsActivities";
import { useToast } from "rc-toastr";

const SuggestionList = ({ text, onClick, currentlySelected }) => {
  const { data } = useSuggestFriends({ search: text });

  return (
    <div>
      <ul className="suggestion-list">
        {data && data.body ? (
          data.body.map((item) => {
            if (currentlySelected.includes(item)) return null;
            return (
              <li
                className="suggestion-list-item"
                onClick={() => {
                  onClick(item);
                }}
                key={item.guid}
              >
                <div className="row">
                  <div className="col-2 suggestion-list-item-pic">
                    <img src={item.image} />
                  </div>
                  <div className="col suggestion-list-item-label">
                    <span>{item.label}</span>
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <span>No results!</span>
        )}
      </ul>
    </div>
  );
};

const IconToolTip = ({ children, ...props }) => {
  return (
    <div>
      <FontAwesomeIcon
        icon={props.icon}
        data-tip
        data-for={props.id}
        style={{ color: props.color }}
        onClick={props.onClick}
      />
      <ReactTooltip id={props.id} place="top" effect="solid">
        {props.tip}
      </ReactTooltip>
      {children}
    </div>
  );
};

//Option to tag a friend in a post
export const TagAFriend = (props) => {
  const [showModal, setShow] = useState(false);
  const [text, setText] = useState();

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <IconToolTip
      icon={faUserPlus}
      color="blue"
      id="tag-friend-tip"
      tip="Tag a Friend"
      onClick={handleShow}
    >
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>Tag A Friend</Modal.Header>
        <Modal.Body>
          <p style={{ marginBottom: 0 }}>Find Friends</p>
          <FormControl type="text" onChange={handleChange} />
          <SuggestionList
            text={text}
            onClick={props.add}
            currentlySelected={props.currentlySelected}
          />
        </Modal.Body>
      </Modal>
    </IconToolTip>
  );
};

//Option to attach a feeling to a post
export const FeelingActivity = (props) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  return (
    <IconToolTip
      icon={faFaceSmile}
      color="purple"
      id="feeling-activity-tip"
      tip="Feeling / Activity"
      onClick={handleShow}
    >
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>Feelings / Activities</Modal.Header>
        <Modal.Body>
          <p>Add a feeling or activity</p>
          <FeelingsActivitiesMenu add={props.add} handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </IconToolTip>
  );
};

//Option to attach a link to a post
export const AddLink = (props) => {
  const [show, setShow] = useState(false);
  const [currentLink, setLink] = useState();
  const [valid, setValid] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const validCheck = (url) => {
    let check = /^(ftp|http|https):\/\/[^ "]+$/.test(url);
    return check;
  };
  const handleChange = (e) => {
    setLink(e.target.value);
  };

  useEffect(() => {
    if (validCheck(currentLink)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [currentLink]);

  return (
    <IconToolTip
      icon={faLink}
      color="orange"
      id="add-link-tip"
      tip="Add Link"
      onClick={handleShow}
    >
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>Add Link</Modal.Header>
        <Modal.Body>
          <FormControl
            type="text"
            onChange={handleChange}
            value={currentLink}
            isValid={valid}
            isInvalid={!valid}
          ></FormControl>
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={!valid}
            onClick={() => {
              props.handleSubmit(currentLink);
              handleClose();
            }}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </IconToolTip>
  );
};

//Option to attach a photo to a post
export const AddPhoto = (props) => {
  const [show, setShow] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [isInvalid, setIsInvalid] = useState(false);

  const { toast } = useToast();

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setImages([]);
    setPreviews([]);
    setIsInvalid(false);
    setShow(false);
  };

  const handleChange = (e) => {
    setImages(e.target.files);

    let new_previews = [...e.target.files];
    new_previews = new_previews.map((item) => {
      return URL.createObjectURL(item);
    });
    setPreviews(new_previews);
  };

  const handleSubmit = () => {
    //Handle no image(s) selected
    if (!images || images.length === 0) {
      toast.error("No photo(s) selected!");
      setIsInvalid(true);
      return;
    }

    props.handleSubmit(images);
    handleClose();
  };

  return (
    <IconToolTip
      icon={faImage}
      color="green"
      id="add-photo-tip"
      tip="Add Photo"
      onClick={handleShow}
    >
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>Add Photo</Modal.Header>
        <Modal.Body>
          <FormControl
            type="file"
            multiple
            accept="image/*"
            onChange={handleChange}
            isInvalid={isInvalid}
          />
          <div className="upload-image-preview">
            {previews.map((item) => {
              return <img src={item} />;
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              handleSubmit();
            }}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </IconToolTip>
  );
};

//Option to attach a sticker to a post
export const AddSticker = () => {
  return (
    <IconToolTip
      icon={faNoteSticky}
      color="red"
      id="add-sticker-tip"
      tip="Add Sticker"
    ></IconToolTip>
  );
};

//Option to attach a video to a post
export const AddVideo = (props) => {
  const [show, setShow] = useState(false);
  const [videos, setVideos] = useState();
  const [isInvalid, setIsInvalid] = useState(false);

  const { toast } = useToast();

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setVideos({});
    setIsInvalid(false);
    setShow(false);
  };

  const handleChange = (e) => {
    let video_obj = {
      file: e.target.files[0],
      preview_url: URL.createObjectURL(e.target.files[0]),
    };

    setVideos(video_obj);
  };

  const handleSubmit = () => {
    //Handle no video selected
    if (!videos || Object.keys(videos).length === 0) {
      toast.error("No video selected!");
      setIsInvalid(true);
      return;
    }

    props.handleSubmit(videos, {});
    handleClose();
  };

  return (
    <IconToolTip
      icon={faVideo}
      color="black"
      id="add-video-tip"
      tip="Add Video"
      onClick={handleShow}
    >
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>Add Videos</Modal.Header>
        <Modal.Body>
          <FormControl
            type="file"
            accept="video/*"
            onChange={handleChange}
            isInvalid={isInvalid}
          />
          {/* <FormControl type="file" accpet="image/*" onChange={handleThumbnailChange} /> */}
          <div className="upload-video-preview">
            {videos && videos.preview_url ? (
              <video controls autoPlay={false}>
                <source src={videos.preview_url}></source>
              </video>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              handleSubmit();
            }}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </IconToolTip>
  );
};
