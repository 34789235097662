import React from "react";
import { Link } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeGrid as Grid } from "react-window";

export const chunkArray = (arr, size) => {
  const chunked_arr = [];
  let index = 0;
  if (size === arr.length) return [arr];
  while (index < arr.length) {
    chunked_arr.push(arr.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
};

export const getChunkArrayItemIfExist = (arr, a, b) => {
  if (arr[a] && arr[a][b]) {
    return arr[a][b];
  }
  return null;
};

const GUTTER_SIZE = 10;

const Cell = ({ style, data, columnIndex, rowIndex }) => {
  let item_data = getChunkArrayItemIfExist(data, rowIndex, columnIndex);
  if (item_data) {
    return (
      <div
        style={{
          ...style,
          left: style.left + GUTTER_SIZE,
          top: style.top + GUTTER_SIZE,
          width: style.width - GUTTER_SIZE,
          height: style.height - GUTTER_SIZE,
        }}
        className="groups-members-grid-item"
      >
        <div className="grid-item-image">
          <Link to={`/profile/${item_data.user_id}`}>
            <img src={item_data.image_icon} />
          </Link>
        </div>
        <div className="grid-item-text">
          <Link to={`/profile/${item_data.user_id}`}>
            {item_data.displayname}
          </Link>
        </div>
      </div>
    );
  }
  return null;
};

export const GridList = ({
  items,
  colCount,
  CellEl = null,
  autosizerClass = "",
  gridClassName = "",
  rowHeight = 100,
}) => {
  CellEl = CellEl || Cell;

  return (
    <AutoSizer className={autosizerClass}>
      {({ height, width }) => {
        let cell_data = chunkArray(items, colCount);
        return (
          <Grid
            className={gridClassName}
            columnCount={colCount}
            columnWidth={() => (width * 0.95) / colCount}
            height={height * 0.95}
            rowCount={cell_data.length}
            rowHeight={() => rowHeight}
            width={width}
            itemData={cell_data}
          >
            {CellEl}
          </Grid>
        );
      }}
    </AutoSizer>
  );
};
