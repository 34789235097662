import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import CreatePost from "../../../components/posts/CreatePost";
import PostsFeed from "../../../components/posts/Feedv2";
import { useGetSitePage } from "../../../utils/api/functions/sitepages";
import { PageAbout } from "./About";
import { PagePhotos } from "./Photos";
import { PageReviews } from "./Reviews";

// const MoreDropdown = () => {

//     const [show, setShow] = useState(false);

//     const handleShow = () => {
//         setShow(!show);
//     }

//     return(
//         <div className="sitepage-more-dropdown">
//             <span onClick={handleShow}>MORE +</span>
//             <div className={`sitepage-more-dropdown-menu ${show ? ("show") : ('')}`}>
//                 <ul>
//                     <li>
//                         Events
//                     </li>
//                     <li>
//                         Polls
//                     </li>
//                     <li>
//                         Discussions
//                     </li>
//                     <li>
//                         Music
//                     </li>
//                 </ul>
//             </div>
//         </div>
//     )
// }

const PagePostsFeed = ({subject_id}) => {
  const {id} = useParams(); 
  const {data} = useGetSitePage(id, {});
  const [showCreatePost, setShowCreatePost] = useState(false); 

  useEffect(() => {
    if(data && data.body){
      if(data.body.isPageFollowed || data.body.isOwner) {
        setShowCreatePost(true); 
      }
      else {
        setShowCreatePost(false); 
      }
    }
  }, [data])


  return(
    <PostsFeed
      feed_params={{
        subject_type: "sitepage_page", 
        subject_id: id
      }}
      CreatePostComponent={showCreatePost ? CreatePost : null}
    />
  )
}

const pageNavItems = (subject_id, page_data) => [
  {
    title: "UPDATES",
    element: [
      <PagePostsFeed subject_id={subject_id}/>
    ],
    active: true,
  },
  {
    title: "ABOUT",
    element: [<PageAbout page_data={page_data} />],
    active: false,
  },
  {
    title: "PHOTOS",
    element: [<PagePhotos page_id={subject_id} />],
    active: false,
  },
  // {
  //     title: "VIDEOS",
  //     element: [<PageVideos />],
  //     active: false
  // },
  {
    title: "REVIEWS",
    element: [<PageReviews page_id={subject_id} />],
    active: false,
  },
  // {
  //     title: <MoreDropdown />,
  //     element: [<p>More</p>],
  //     active: false,
  //     dropdown: true
  // }
];

export const PageViewContent = ({ subject_id, page_data }) => {
  const _pageNavItems = pageNavItems(subject_id, page_data);
  const [activeNav, setActive] = useState(0);
  const [content, setContent] = useState(_pageNavItems[0].element);

  const handleNavSwitch = (index) => {
    if (_pageNavItems[index].dropdown) {
      return;
    }
    setActive(index);
    setContent(_pageNavItems[index].element);
  };

  return (
    <div className="sitepage-view-content">
      <div className="sitepage-view-nav">
        {_pageNavItems.map((val, index) => {
          return (
            <h6
              className={
                activeNav === index
                  ? "sitepage-nav-item sitepage-active-nav"
                  : "sitepage-nav-item"
              }
              onClick={() => {
                handleNavSwitch(index);
              }}
            >
              {val.title}
            </h6>
          );
        })}
      </div>
      {content ? content.map((val) => val) : null}
    </div>
  );
};
