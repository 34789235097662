import React from "react";
import { Form, Button } from "react-bootstrap";

const ProfileSettingsDeleteAccount = () => {
  return (
    <div className="profile-settings-form-container">
      <h5>Delete Account</h5>
      <Form>
        <Form.Text>
          Deleting your account will delete any uploaded content, remove your
          profile from XAPiT, and is not reversible.
        </Form.Text>
        <Form.Group controlId="delete_confirm_password" className="mb-3">
          <Form.Label>
            Enter your password to confirm deletion of your account.
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            autoComplete="new-password"
          />
        </Form.Group>
        <Button variant="danger" type="submit">
          Delete Account
        </Button>
      </Form>
    </div>
  );
};

export default ProfileSettingsDeleteAccount;
