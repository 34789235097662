import queryString from "query-string";
import oauth_config from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;
//Makes Post request to login api
//@TODO: Save oauth_token & oauth_secret as a cookie ?
//@TODO: Rework api to determine IP address server-side.
const login = (email, password) => {
  return fetcher(
    [
      `${hostname}/login`,
      {
        method: "POST",
        body: queryString.stringify({
          email: email,
          password: password,
          ip: "127.0.0.1",
          oauth_consumer_secret: oauth_config.oauth_consumer_secret,
          oauth_consumer_key: oauth_config.oauth_consumer_key,
        }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          //  Accept: "application/json",
          "oauth_consumer_secret": oauth_config.oauth_consumer_secret,
          "oauth_consumer_key": oauth_config.oauth_consumer_key
        },
      },
    ],
    false,
  );
};

const postForgotPassword = (email) => {
  //@TODO: Finish POST request
  return fetcher([
    `${hostname}/forgot-password`, 
    {
      method: "POST", 
      body: queryString.stringify({
        email: email, 
        oauth_consumer_secret: oauth_config.oauth_consumer_secret, 
        oauth_consumer_key: oauth_config.oauth_consumer_key
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded", 
        //Accept: "application/json", 
        oauth_consumer_secret: oauth_config.oauth_consumer_secret, 
        oauth_consumer_key: oauth_config.oauth_consumer_key
      }
    }
  ], false);
};

const postResetPassword = (values) => {
  return fetcher([
    `${hostname}/reset-password`, 
    {
      method: "POST", 
      body: queryString.stringify({
        code: values.code, 
        uid: values.uid, 
        password: values.password, 
        confirm_password: values.confirm_password,
        oauth_consumer_key: oauth_config.oauth_consumer_key, 
        oauth_consumer_secret: oauth_config.oauth_consumer_secret
      }), 
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        oauth_consumer_key: oauth_config.oauth_consumer_key, 
        oauth_consumer_secret: oauth_config.oauth_consumer_secret
      }
    }
  ], false)
}

const logout = () => {
  //@TODO: Finish POST request
  return fetcher();
};

export { login, logout, postForgotPassword, postResetPassword };

