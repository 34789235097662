import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const EventDeleteConfirmationModal = ({show, handleHide, handleDelete}) => {
    return(
        <Modal
            show={show}
            onHide={handleHide}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                Delete Event 
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this event? 
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete}>Delete</Button>
                <Button variant="secondary" onClick={handleHide}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
}



export default EventDeleteConfirmationModal; 