import { Formik } from "formik";
import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { postGeneralSettings, useGetGeneralSettings } from "../../../utils/api/functions/memberSettings";

const GeneralForm = () => {
  const [settings, setSettings] = useState({});
  const { data } = useGetGeneralSettings();

  const { toast } = useToast();

  useEffect(() => {
    if (data && data.body && data.body.formValues) {
      const previous_values = {
        email: data.body.formValues.email,
        locale: data.body.formValues.locale,
        timezone: data.body.formValues.timezone,
      };
      setSettings(() => previous_values);
    }
  }, [data]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={settings}
      onSubmit={async (values, actions) => {
        
        actions.setSubmitting(() => true);
        const _formData = new FormData();

        Object.keys(values).map((key) => {
          _formData.append(key, values[key]);
        });

        const result = await postGeneralSettings(_formData);

        if (result.status_code === 204) {
          toast.success("Settings updated");
        } else {
          toast.error("Something went wrong");
        }

        actions.setSubmitting(() => false);
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email Address"
              defaultValue={values?.email}
              onChange={handleChange}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={isSubmitting ? true : false}
          >
            Save Changes
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const ProfileSettingsGeneral = () => {
  return (
    <div className="profile-settings-form-container">
      <GeneralForm />
    </div>
  );
};

export default ProfileSettingsGeneral;
