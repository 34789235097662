import queryString from "query-string";
import useSWR from "swr";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;

export const useEditGeneralSettings = (values) => {
  return fetcher(hostname + "/members/settings/general", {
    method: "PUT",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const PutEditPrivacySettings = (values) => {
  return fetcher(
    [
      hostname + "/members/settings/privacy",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false,
    true,
  );
};

export const useSwitchNetwork = (values) => {
  return fetcher(hostname + "/members/settings/network", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const putEditNotificationSettings = (values) => {
  return fetcher([hostname + "/members/settings/notifications", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  }], false, true);
};

export const useDeleteAccount = (values) => {
  return fetcher(hostname + "/members/settings/delete", {
    method: "DELETE",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGetGeneralSettings = () => {
  return useSWR(
    [
      hostname + "/members/settings/general",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};
export const useGetPrivacySettings = () => {
  return useSWR(
    [
      hostname + "/members/settings/privacy",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const postGeneralSettings = (values) => {
  return fetcher(
    [
      hostname + "/members/settings/general",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false,
    true,
  );
};

export const postChangePassword = (values) => {
  return fetcher(
    [
      hostname + "/members/settings/password",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false,
    true,
  );
};

export const useGetProfileEdit = (values) => {
  return useSWR(
    [
      hostname + "/members/edit/profile",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const postProfileEdit = (values) => {
  return fetcher(
    [
      hostname + "/members/edit/profile",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false,
    true,
  );
};

export const postProfileEditPhoto = (values) => {
  return fetcher(
    [
      hostname + "/members/edit/photo",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    false,
    true,
  );
};

export const useGetNotificationSettings = (values) => {
  return useSWR(
    [
      hostname + "/members/settings/notifications", 
      {
        method: "GET",
        headers: {
          Accept: "application/json", 
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret
        }
      }
    ],
    fetcher
  )
}