import { useToast } from 'rc-toastr';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import XapitLogo from "../../assets/xapit_logo_135.png";
import { postForgotPassword } from '../../utils/api/functions/login';

const ForgotPasswordSuccess = () => {
    return(
        <div
            style={{
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                justifyContent: "center", 
                height: "100%"
            }}
        >
            <img src={XapitLogo}
                style={{
                    width: "10em"
                }}
            />
            <h3>An email will arrive shortly!</h3>
            <p>If an account is located for the email provided, you will receive a link with instructions on resetting your password</p>
        </div>
    )
}

const ForgotPassword = () => {
    const [disabled, setDisable] = useState(false); 
    const [success, setSuccess] = useState(false); 

    const {toast} = useToast(); 
    const navigate = useNavigate(); 

    const handleSubmit = async(e) => {
        setDisable(true); 
        e.preventDefault();
        const email_address = e.target.email_address.value; 
        const result = await postForgotPassword(email_address); 

        if(result.status_code === 200){
            toast.success("Email sent!"); 
            setSuccess(true); 
        }
    }

    const handleCancel = () => {
        navigate("/login"); 
    }

    if(success){
        return(
            <ForgotPasswordSuccess />
        )
    }

    return(
        <Form className="forgot-page-form"
            onSubmit={handleSubmit}
        >
            <div className="forgot-form-content">
                <div className="forgot-form-header">
                    <h6>Forgot Password</h6>
                </div>
                <div
                    style={{
                        marginBottom: "12px"
                    }}
                >
                    <Form.Text>
                        If you cannot login because you have forgotten your password, enter your email below.
                    </Form.Text>
                </div>
                
                <Form.Group className="mb-3" controlId='email_address'>
                    <div className="forgot-form-email-header">
                        <Form.Label>Email Address</Form.Label>
                            <Form.Control 
                                name="email_address"
                                type="text"
                                placeholder='Email Address'
                        />
                    </div>
                </Form.Group>
                <Form.Group className="mb-3"
                    style={{
                        display: 'flex',
                        justifyContent: "space-evenly"
                    }}
                >
                <Button variant="danger" onClick={handleCancel}>Cancel</Button>
                <Button type="submit" disabled={disabled}>Send Email</Button>
                </Form.Group>
            </div>
        </Form>
    )
}


export default ForgotPassword; 