import queryString from "query-string";
import useSWR from "swr";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;

export const useBrowseAlbums = (values) => {
  return useSWR(
    [
      hostname + "/albums?" + queryString.stringify(values),
      {
        method: "GET",
        headers: {
          // "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetOwnedAlbums = (values) => {
  return useSWR(
    [
      hostname + "/albums/manage",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useUploadAlbum = (values) => {
  return fetcher(hostname + "/albums/upload", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGetAlbum = (album_id, values) => {
  return useSWR(
    [
      hostname + `/albums/view/${album_id}`,
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useEditAlbum = (values) => {
  return fetcher(hostname + "/albums/edit", {
    method: "PUT",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useDeleteAlbum = (values) => {
  return fetcher(hostname + "/albums/delete", {
    method: "DELETE",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGetAlbumPhotos = (values) => {
  return useSWR(
    [
      hostname + "/albums/photo/list",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useUploadPhotosToAlbum = (values) => {
  return fetcher(hostname + "/albums/photo/list", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useGetAlbumPhotoInfo = (values) => {
  return useSWR(
    [
      hostname + "/albums/photo/view",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useEditPhotoInfo = (values) => {
  return fetcher(hostname + "/albums/photo/edit", {
    method: "PUT",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const postDeleteAlbumPhoto = (values, photo_id) => {
  return fetcher([hostname + "/albums/photo/delete?photo_id="+photo_id, {
    method: "DELETE",
    body: values,
    headers: {
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  }], false, true);
};

export const useRotateAlbumPhoto = (values) => {
  return fetcher(hostname + "/albums/photo/rotate", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};

export const useFlipAlbumPhoto = (values) => {
  return fetcher(hostname + "/albums/photo/flip", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};
