import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { useGetSitePageManageAdmins } from "../../../../utils/api/functions/sitepages";

const user_levels = {
  Member: 0,
  Editor: 1,
  Admin: 2,
};

const UserActionMenu = ({ show, user_data }) => {
  const user_level = user_data[1].permission_level;
  const level_value = user_levels[user_level];

  const handleLevelChange = () => {};

  return (
    <div className={`admin-user-item-action-menu ${show ? "show-menu" : ""}`}>
      <div className="admin-user-item-action-menu-item">Kick</div>
      <div className="admin-user-item-action-menu-item">Ban</div>
      {Object.keys(user_levels).map((level) => {
        if (level === user_level) return null;
        return (
          <div
            className="admin-user-item-action-menu-item"
            onClick={() => {
              handleLevelChange(level);
            }}
          >
            {user_levels[level] > level_value ? "Promote to " : "Demote to "}{" "}
            {level}
          </div>
        );
      })}
    </div>
  );
};

const AdminItem = ({ admin_data }) => {
  const [showMenu, setShowMenu] = useState(false);

  if (!admin_data[0]) return null;

  const is_self =
    admin_data[1].user_id == localStorage.getItem("user_id") ? true : false;

  return (
    <div className="admin-user-item">
      <div className="admin-user-item-avatar"></div>
      <div className="admin-user-item-name">
        <Link to={"/profile/" + admin_data[1].user_id}>
          {admin_data[1].user_id == localStorage.getItem("user_id")
            ? admin_data[0].displayname + " (You)"
            : admin_data[0].displayname}
        </Link>
      </div>
      <div className="admin-user-item-role">
        {admin_data[1].permission_level}
      </div>
      <div className="admin-user-item-actions">
        {is_self ? null : (
          <div className="admin-user-item-actions-button">
            <FontAwesomeIcon
              icon={faEllipsis}
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            />
            <UserActionMenu user_data={admin_data} show={showMenu} />
          </div>
        )}
      </div>
    </div>
  );
};

const PageManageAdmins = () => {
  const { id } = useParams();
  const { data } = useGetSitePageManageAdmins(id, {});

  useEffect(() => {}, [data]);

  return (
    <div className="page-edit-manage-admins-container">
      <div>
        Manage Admins
        <p>Add new or manage administrators on your page.</p>
      </div>
      <div className="page-edit-manage-admins-content">
        <div className="page-edit-manage-admins-user-list">
          {data?.body?.allMembers
            ? data.body.allMembers.map((admin, index) => {
                return (
                  <AdminItem admin_data={admin} key={`admin-item-${index}`} />
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default PageManageAdmins;
