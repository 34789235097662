const _notificationTypes = [
    // {
    //   category: "Activity",
    //   socialengine_settings: [
    //     "replied", // When people reply on my posts.
    //     "replied_replied", // When people reply on the same thing as me.
    //     "picture_suggestion", // When I receive a picture suggestion.
    //     "commented", // When people comment on things I post.
    //     "commented_commented", // When people comment on the same thing as me.
    //     "liked", // When people like things I post.
    //     "liked_commented", // When people comment on things I've liked.
    //     "liked_replied", // When people reply on things I've liked.
    //     "shared" // When activity feed item is shared.
    //   ]
    // },
    {
      category: "Tagging",
      socialengine_settings: [
        "aaf_tagged", // When my content is tagged.
        "sitepage_tagged" // When my page is tagged.
      ]
    },
    {
      category: "Friend Requests",
      socialengine_settings: [
        "friend_accepted", // When my friend request is accepted. 
        "friend_request" // When I receive a friend request. 
      ]
    },
    {
      category: "Groups",
      socialengine_settings: [
        "group_accepted", // When my request to join a group is approved.
        "group_approve", // When someone requests to join a group I created.
        "group_discussion_reply", // When people reply to discussion topics that I've replied to.
        "group_discussion_response", // When people reply to discussion topics that I create.
        "group_invite", // When I'm invited to join a group.
        "group_promote" // When I'm given officer status in a group.
      ]
    },
    {
      category: "Pages",
      socialengine_settings: [
        "follow_sitepage_page", // When people follow my page.
        "sitepage_insights", // Periodic statistical insights of my Pages.
        "sitepage_manageadmin", // When I am made the administrator of a Page.
        "sitepage_notificationpost", // When people post on my page.
        "sitepage_suggested", // When someone invites me to visit a Page.
        "sitepage_verify_admin_approve", // When site admin approves your page verification request.
        "sitepage_verify_new", // When your page has been verified by someone.
        "sitepage_verify_user_request" // When someone has requested you to approve page verification request.
      ]
    },
    {
      category: "Videos",
      socialengine_settings: [
        "favourited" // When people favorite my channels / videos.
      ]
    },
    {
      category: "Events",
      socialengine_settings: [
        "event_accepted", // When my request to attend an event is approved.
        "event_approve", // When someone request to join my event.
        "event_discussion_reply", // When people reply to discussion topics that I've replied to.
        "event_discussion_response", // When people reply to discussion topics that I create.
        "event_invite" // When I'm invited to attend an event.
      ]
    },
    {
      category: "Messages",
      socialengine_settings: [
        "message_new" // When I receive a message.
      ]
    },
    {
      category: "Blogs",
      socialengine_settings: [
        "blog_subscribed_new" // When a new blog entry is posted by a member you have subscribed to.
      ]
    },
    {
      category: "Likes, shares, and replies",
      socialengine_settings: [
        "commented", // When people comment on things I post.
        "commented_commented", // When people comment on the same thing as me.
        "liked", // When people like things I post.
        "liked_commented", // When people comment on things I've liked.
        "liked_replied", // When people reply on things I've liked.
        "replied", // When people reply on my posts.
        "replied_replied", // When people reply on the same thing as me.
        "shared" // When activity feed item is shared.
      ]
    }
  ];
export default _notificationTypes;