import queryString from "query-string";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;

export const useGetLikesComments = (values) => {
  return useSWR(
    [
      (hostname = "/likes-comments"),
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: false,
    },
  );
};

export const useGetLikesCommentsBasic = (values) => {
  return useSWR(
    [
      hostname + `/likes-comments?subject_id=${values.subject_id}&subject_type=${values.subject_type}&viewAllComments=${values.viewAllComments}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
    {},
  );
};

//SWR Infinite Pagination for comments 
export const usePaginateCommentsBasic = (values) => {
  return useSWRInfinite(
    (index, previousPageData) => {
      values.page = index+1// increment index by 1, pagination does not start with 0 
      return [
        hostname + "/likes-comments?" + queryString.stringify(values), 
        {
          method: "GET", 
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            oauth_consumer_key: oauthConfig.oauth_consumer_key,
            oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
          }
        }
      ]
    }, 
    fetcher,
    {
      revalidateFirstPage: false
    }
  )
}
export const postLike = (values) => {
  return fetcher([hostname + "/like", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  }], false, true);
};

export const postUnlike = (values) => {
  return fetcher([hostname + "/unlike", {
    method: "POST",
    body: queryString.stringify(values),
    headers: {
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  }], false, true);
};

export const postCreateCommentBasic = (values) => {
  return fetcher(
    [
      hostname + "/comment-create",
      {
        method: "POST",
        body: values,
        headers: {
          Accept: "application/json",
        },
      },
    ],
    true,
  );
};

export const useDeleteComment = (values) => {
  return fetcher(hostname + "/comment-delete", {
    method: "DELETE",
    body: queryString.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      oauth_consumer_key: oauthConfig.oauth_consumer_key,
      oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
    },
  });
};
