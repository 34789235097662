import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import routes from "../settings/routes";
import Layout from "./Default";
//Import Groups Home Layout CSS file
import "../styles/sass/components/Groups/BrowseGroups.scss";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation(); 

  return (
    <div className="groups-home-header">
      <div>
        <h4>
          <strong>Get Together in Groups</strong>
        </h4>
        <h5>Create and join interest based Groups. Bring people Together.</h5>
      </div>
      {
        location.pathname === "/groups/create" ? null : (
          <button
            className="groups-home-header-create"
            onClick={() => {
              navigate("/groups/create");
            }}
          >
           <FontAwesomeIcon icon={faPlus} /> Create New Group
          </button>
        )
      } 
    </div>
  );
};

const GroupsNav = () => {
  //Get Groups sub menu routes
  const groups_route = routes.filter(
    (route_item) => route_item.name === "Groups",
  )[0];
  let sub_menu_routes =
    groups_route && groups_route.navOptions && groups_route.navOptions.subMenu
      ? groups_route.navOptions.subMenu
      : [];

  const location = useLocation();

  return (
    <div className="groups-home-nav">
      <div className="groups-home-nav-header">
        <h4>Groups</h4>
      </div>
      {sub_menu_routes.map((val, i) => {
        return (
          <div
            className={`groups-home-nav-item ${
              location.pathname === val.link ? "active" : null
            }`}
          >
            <Link to={val.link}>{val.name}</Link>
          </div>
        );
      })}
    </div>
  );
};

const Groups = ({ children }) => {
  return (
    <div className="groups-home-container">
      <Header />
      <GroupsNav />

      {children}
    </div>
  );
};

const GroupHomeLayout = ({ children }) => {
  return (
    <Layout>
      <Groups>{children}</Groups>
    </Layout>
  );
};

export default GroupHomeLayout;
